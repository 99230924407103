import { createBrowserHistory } from 'history';
import qs from 'qs';

import type { History } from 'history';
import type { ReactNode } from 'react';
import type { FlowableTask } from '../../flowable/tsTypes';
import type { ParsedTaxonomyUrlSplat } from '../../oms/taxonomy/utils';
import type { OMSObject } from '../../oms/tsTypes';
import type { RipcordLocation } from '../../query-params/utils';
import type { ValueOf } from '../../utils/tsUtilityTypes';

import { APP_IDS, BASE_TYPE, CATEGORIES } from '../../constants';
import { buildTaxonomyUrlSplat } from '../../oms/taxonomy/utils';
import { compileLegacyTaxonomyPath } from '../../pages/taxonomy/legacyUtils';
import { SEARCHMODES } from '../../ui/slices';
import { generatePath } from '../../utils/routing';
import { isOnRoute } from '../sharedUtils';

export { isOnRoute } from '../sharedUtils';

// paths are defined using the underlying implementation used in react router:
// https://github.com/pillarjs/path-to-regexp/tree/v1.7.0

// transparently support loading the app from either the bare `/` or the `/parachute/` pathnames
export const PARACHUTE_BASE_ROUTE = window.location.pathname.startsWith(
  `/parachute`
)
  ? '/parachute/'
  : '/';
export const DOCUFAI_PLUS_BASE_ROUTE = '/plus/';
export const CANOPY_BASE_ROUTE = '/canopy/';
export const BASE_ROUTE =
  process.env.APP_NAME === APP_IDS.CANOPY
    ? CANOPY_BASE_ROUTE
    : process.env.APP_NAME === APP_IDS.DOCUFAI_PLUS
    ? DOCUFAI_PLUS_BASE_ROUTE
    : PARACHUTE_BASE_ROUTE;
export const HOME_ROUTE = `${BASE_ROUTE}home` as const;
export const CARD_LIST_ROUTE = `${BASE_ROUTE}card-list` as const;
export const COLLECTIONS_ROUTE = `${BASE_ROUTE}collections` as const;
export const COLLECTION_ROUTE = `${COLLECTIONS_ROUTE}/:id?` as const;
export const FAVORITES_ROUTE = `${BASE_ROUTE}favorites` as const;
export const METRICS_ROUTE = `${BASE_ROUTE}metrics/:metricLabel?` as const;
export const AUDIT_SEARCH_ROUTE = `${BASE_ROUTE}audit-search` as const;
export const RECENT_ROUTE = `${BASE_ROUTE}recent` as const;
export const PERSONAL_ROUTE =
  process.env.APP_NAME === APP_IDS.DOCUFAI_PLUS
    ? `${BASE_ROUTE}my-files/:parentId?`
    : (`${BASE_ROUTE}personal/:parentId?` as const);
export const WORKSPACES_ROUTE = `${BASE_ROUTE}workspaces` as const;
export const WORKSPACE_ROUTE = `${WORKSPACES_ROUTE}/:id?` as const;
export const WORKSPACE_RECORD_ROUTE =
  `${WORKSPACE_ROUTE}/record/:recordId` as const;
export const WORKSPACE_PRINT_NOTES_ROUTE = `${WORKSPACE_ROUTE}/print` as const;
export const LEGACY_NOTEBOOKS_ROUTE = `${BASE_ROUTE}legacy_notebooks` as const;
export const LEGACY_NOTEBOOK_ROUTE = `${LEGACY_NOTEBOOKS_ROUTE}/:id?` as const;
export const LIBRARY_ROUTE = `${BASE_ROUTE}library/:parentId?` as const;
export const SOURCE_ROUTE = `${BASE_ROUTE}source/:parentId?` as const;
export const TAXONOMY_LISTING_ROUTE = `${BASE_ROUTE}taxonomies` as const;
export const TAXONOMY_ROUTE = `${TAXONOMY_LISTING_ROUTE}/:id/*` as const;
export const TAXONOMY_EDIT_ROUTE = `${BASE_ROUTE}edit-taxonomy/:id?` as const;
export const SINGULAR_TAXONOMY_LISTING_ROUTE = `${BASE_ROUTE}taxonomy` as const;
export const SINGULAR_TAXONOMY_ROUTE =
  `${SINGULAR_TAXONOMY_LISTING_ROUTE}/:id/*` as const;
export const SAVED_SEARCHES_ROUTE =
  `${BASE_ROUTE}saved-searches/:id?/:fulltext?` as const;
export const SEARCH_ROUTE = `${BASE_ROUTE}search/:fulltext?` as const;
export const RECORD_ROUTE = `${BASE_ROUTE}record/:recordId` as const;
export const TASKS_ROUTE = `${BASE_ROUTE}tasks/:queueName?` as const;
export const TASKS_BROWSING_ROUTE =
  `${BASE_ROUTE}tasks/browsing/:queueName?` as const;
export const TASKS_BROWSER_ROUTE =
  `${BASE_ROUTE}tasks-browser/:filters?` as const;
export const TASK_ROUTE = `${BASE_ROUTE}task/:view/:taskId` as const;
export const PLAIN_JSON_ROUTE = `${BASE_ROUTE}meta/:id` as const;
export const PLAIN_JSON_DESCENDANTS_ROUTE = `${BASE_ROUTE}metad/:id` as const;
export const DOCUFAI_DEBUG_ROUTE = `${BASE_ROUTE}debugTextLayer/:id` as const;

// document review is going to be it's own top-level page for now, but eventually it's
// functionality should be baked into TASKS_PROCESSING_ROUTE with the document review portion
// in the session frame
export const BOX_PROCESSING_ROUTE =
  `${BASE_ROUTE}box-processing/:queueName?` as const;
export const TASKS_PROCESSING_ROUTE =
  `${BASE_ROUTE}page-processing/:queueName?` as const;
export const DEMO_PAGE_ROUTE = `${BASE_ROUTE}demo-ripcord-components` as const;
export const DUMP_PAGE_ROUTE = `${BASE_ROUTE}dump-dev-data` as const;

export const BULK_TASKS_PROCESSING_ROUTE =
  `${BASE_ROUTE}bulk-processing/:queueName?` as const;

export const SPLIT_CLASSIFY_ROUTE =
  `${BASE_ROUTE}split-classify/:queueName?` as const;

export const ERR_404_ROUTE = `${BASE_ROUTE}404` as const;
export const TENANT_NOT_FOUND_ROUTE = `${BASE_ROUTE}tenant-not-found` as const;

// Try to use the `useHistory` hook, the `withRouter` HOC, or use connected-redux-router's push/replace.
// if you have no other option, this is exported here.
export const history: History = createBrowserHistory();

export const LISTING_ROUTES = [
  COLLECTION_ROUTE,
  COLLECTIONS_ROUTE,
  RECENT_ROUTE,
  PERSONAL_ROUTE,
  FAVORITES_ROUTE,
  LIBRARY_ROUTE,
  SOURCE_ROUTE,
  TAXONOMY_ROUTE,
  TAXONOMY_LISTING_ROUTE,
  SAVED_SEARCHES_ROUTE,
  SEARCH_ROUTE,
  TASKS_ROUTE,
  TASKS_BROWSER_ROUTE,
  TASKS_BROWSER_ROUTE
] as const;

// This returns a boolean used to hide the left menu navbar when the user is on these routes
export const isDocumentView = (pathname: string) => {
  return isOnRoute(pathname, [
    RECORD_ROUTE,
    TASKS_PROCESSING_ROUTE,
    BULK_TASKS_PROCESSING_ROUTE,
    BOX_PROCESSING_ROUTE,
    SPLIT_CLASSIFY_ROUTE,
    TASK_ROUTE
  ]);
};

/**
 * This type is used anywhere that we build a "fake" OMS Object used to generate a link or
 * breadcrumb of some kind. It has all kinds of extra flags that may only be used in one area,
 * and some properties are changed. It should be a superset of the OMSObject type, and it is a
 * tad bit recursive with containing it's own type in the path property.
 */
export type LinkablePseudoObject = Partial<
  Omit<OMSObject, 'label' | 'path'>
> & {
  label?: ReactNode;
  breadcrumbKey?: string;
  id?: string | null | undefined;
  name?: string;
  taxonomyLinkFilters?: ParsedTaxonomyUrlSplat;
  personal?: boolean;
  currentPath?: string[];
  path?: Array<LinkablePseudoObject>;
};

export const genCanopyLinkToObject = (
  /**
   * personal: true triggers the link to go to the personal link instead of the library link in
   * case we don't have enough info to determine it from the object alone
   */
  omsObject: LinkablePseudoObject
) => {
  /**
   *  It is imperative that pathname is inserted into the object first
   *  so that we can easily destructure the returns into a history.push
   */
  const link: Partial<Location & { state?: any }> = {};
  const location = history?.location;
  const newGoBackUrl = isOnRoute(
    location?.pathname,
    LISTING_ROUTES as unknown as string[]
  )
    ? `${location?.pathname}${location?.search}`
    : null;
  const currentGoBackUrl = location?.state?.gobackUrl;
  const gobackUrl = !isOnRoute(newGoBackUrl, RECORD_ROUTE)
    ? newGoBackUrl
    : currentGoBackUrl;
  if (omsObject.id && typeof omsObject.taxonomyLinkFilters !== 'undefined') {
    // taxonomy 2.0 path generation
    link.pathname = generatePath(TAXONOMY_ROUTE, {
      id: omsObject.id,
      // @ts-expect-error This is a hack using some internals of RRD to use raw splats
      0: buildTaxonomyUrlSplat(omsObject.taxonomyLinkFilters)
    });
    link.search = qs.stringify({
      searchMode: omsObject.source ? SEARCHMODES.SOURCE : undefined
    });
  } else if (omsObject.category === CATEGORIES.CONTAINER) {
    if (omsObject.type === BASE_TYPE.NOTEBOOK) {
      link.pathname = generatePath(WORKSPACE_ROUTE, {
        id: omsObject.id
      });
    } else if (omsObject.source) {
      link.pathname = generatePath(SOURCE_ROUTE, {
        parentId: omsObject.id
      });
    } else {
      if (
        omsObject?.path?.some(({ type }) => type === BASE_TYPE.HOME_FOLDER) ||
        omsObject?.personal
      ) {
        link.pathname = generatePath(PERSONAL_ROUTE, {
          parentId: omsObject.id
        });
      } else {
        link.pathname = generatePath(LIBRARY_ROUTE, {
          parentId: omsObject.id
        });
      }
    }
  } else if (omsObject.type === BASE_TYPE.TAXONOMY) {
    // legacy (1.0) taxonomy path generation
    link.pathname = generateLegacyTaxonomyPath(
      omsObject.id!,
      omsObject.currentPath
    );
  } else if (omsObject.type === BASE_TYPE.COLLECTION) {
    link.pathname = generatePath(COLLECTION_ROUTE, {
      id: omsObject.id
    });
    link.state = { gobackUrl };
  } else {
    link.pathname = generatePath(RECORD_ROUTE, {
      recordId: omsObject.id!
    });
    link.state = { gobackUrl };
  }
  return link as Location & { state?: any };
};

export const genCanopyLinkToTask = (
  flowableTask: FlowableTask,
  view: 'error' = 'error'
) => {
  /**
   *  It is imperative that pathname is inserted into the object first
   *  so that we can easily destructure the returns into a history.push
   */
  const link: Partial<RipcordLocation> = {};
  const location = history?.location;
  const currentPath = location?.pathname;
  const currentGoBackUrl = location?.state?.gobackUrl;
  const gobackUrl = !isOnRoute(currentPath, TASK_ROUTE)
    ? currentPath
    : currentGoBackUrl;
  link.pathname = generatePath(TASK_ROUTE, { view, taskId: flowableTask.id });
  link.state = { gobackUrl, queryKey: flowableTask.queryKey };
  return link;
};

export const generateLegacyTaxonomyPath = (
  id: string,
  taxonomyPath: string[] = []
) => {
  return generatePath(TAXONOMY_ROUTE, {
    id,
    // @ts-expect-error This is a hack using some internals of RRD to use raw splats
    0: compileLegacyTaxonomyPath(taxonomyPath)
  });
};

history.listen(() => {
  window.scrollTo(0, 0);
});

/*
ways to get route information:

React:
  * useHistory, useLocation, useParams, useRouteMatch for functional components
  * withRouter for class components
*/

export const LISTING_PAGES = {
  [AUDIT_SEARCH_ROUTE]: 'audit',
  [COLLECTION_ROUTE]: 'collection',
  [COLLECTIONS_ROUTE]: 'collections listing',
  [FAVORITES_ROUTE]: 'favorites',
  [LIBRARY_ROUTE]: 'library',
  [METRICS_ROUTE]: 'metrics',
  [PERSONAL_ROUTE]: 'personal',
  [RECENT_ROUTE]: 'recent',
  [SAVED_SEARCHES_ROUTE]: 'saved search',
  [SEARCH_ROUTE]: 'search',
  [SOURCE_ROUTE]: 'source',
  [TASKS_ROUTE]: 'tasks',
  [TASKS_BROWSING_ROUTE]: 'task browsing',
  [TAXONOMY_ROUTE]: 'taxonomy',
  [TAXONOMY_LISTING_ROUTE]: 'taxonomy listing',
  [WORKSPACES_ROUTE]: 'workspaces listing'
} as const;

export type PossibleListingPageNames = ValueOf<typeof LISTING_PAGES>;

export const RECORD_PAGES = {
  [BULK_TASKS_PROCESSING_ROUTE]: 'bulk processing',
  [BOX_PROCESSING_ROUTE]: 'box processing',
  [RECORD_ROUTE]: 'record',
  [SPLIT_CLASSIFY_ROUTE]: 'split classify',
  [TASKS_PROCESSING_ROUTE]: 'task processing',
  [TASKS_BROWSER_ROUTE]: 'task viewer'
} as const;

export type PossibleRecordPageNames = ValueOf<typeof RECORD_PAGES>;

export const PAGES = {
  ...LISTING_PAGES,
  ...RECORD_PAGES
} as const;

export type PossiblePageNames =
  | PossibleListingPageNames
  | PossibleRecordPageNames;
