/**
 * A utility for evaluating the JS nullish coalescing operator (??) as a conditional.
 * Built to be chained with further checks.
 */
export function isNullish<T = never>(
  value: unknown,
  additionalChecks: T[] = []
): value is null | undefined | T {
  return [null, undefined, ...additionalChecks].some((val) => val === value);
}
/**
 * A utility for evaluating nullish values for filters, where empty string is also
 * nullish.
 * Built to be chained with further checks.
 */
export const isNullishFilterValue = (
  filterValue: any,
  additionalChecks: any[] = []
) => {
  return isNullish(filterValue, ['', ...additionalChecks]);
};
