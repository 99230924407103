import { css } from 'styled-components';

export const ellipsis = {
  display: 'block',
  textOverflow: 'ellipsis',
  overflow: 'hidden'
} as const;

export const resetButton = {
  background: 'none',
  border: 0,
  padding: 0,
  margin: 0
} as const;

export const overflow = {
  overflow: 'hidden'
} as const;

export const FloatingVerticalScrollbarStyles = css`
  ::-webkit-scrollbar {
    width: 24px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    border: 8px solid transparent;
    border-radius: 16px;
    min-height: 80px;
    background-clip: content-box;
    &:hover,
    &:active {
      background-color: ${({ theme }) => theme.colors.black.T30};
    }
    background-color: ${({ theme }) => theme.colors.black.T20};
  }

  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }
`;
