export const ABSTRACT_FEEDBACK_MODAL = 'ripcord/ABSTRACT_FEEDBACK_MODAL';
export const ACL_MODAL = 'ripcord/ACL_MODAL';
export const ADD_TO_COLLECTION_MODAL = 'ripcord/ADD_TO_COLLECTION_MODAL';
export const CLASSIFICATION_MODAL = 'ripcord/CLASSIFICATION_MODAL';
export const CREATE_COLLECTION_MODAL = 'ripcord/CREATE_COLLECTION_MODAL';
export const CREATE_FOLDER_MODAL = 'ripcord/CREATE_FOLDER_MODAL';
export const CREATE_TAXONOMY_MODAL = 'ripcord/CREATE_TAXONOMY_MODAL';
export const CREATE_VERSION_MODAL = 'ripcord/CREATE_VERSION_MODAL';
export const CREATE_RELATIONSHIP_MODAL = 'ripcord/CREATE_RELATIONSHIP_MODAL';
export const ADD_TO_WORKSPACE_MODAL = 'ripcord/ADD_TO_WORKSPACE_MODAL';
export const CREATE_WORKSPACE_MODAL = 'ripcord/CREATE_WORKSPACE_MODAL';
export const EDIT_COLLECTION_MODAL = 'ripcord/EDIT_COLLECTION_MODAL';
export const EDIT_NOTE_MODAL = 'ripcord/EDIT_NOTE_MODAL';
export const EDIT_WORKSPACE_MODAL = 'ripcord/EDIT_WORKSPACE_MODAL';
export const EXPORT_MODAL = 'ripcord/EXPORT_MODAL';
export const INSTALL_WORKFLOWS_MODAL = 'ripcord/INSTALL_WORKFLOWS_MODAL';
export const MODAL_CONFIRM = 'ripcord/MODAL_CONFIRM';
export const MODAL_DELETE_OMS_OBJECT = 'ripcord/MODAL_DELETE_OMS_OBJECT';
export const MODAL_DOC_REVIEW_MERGE = 'ripcord/MODAL_DOC_REVIEW_MERGE';
export const MODAL_DOC_REVIEW_SPLIT = 'ripcord/MODAL_DOC_REVIEW_SPLIT';
export const MODAL_LEGACY_TAXONOMY = 'ripcord/MODAL_LEGACY_TAXONOMY';
export const MODAL_MOVE_OBJECT_WARNING = 'ripcord/MODAL_MOVE_OBJECT_WARNING';
export const MODAL_NOTES = 'ripcord/MODAL_NOTES';
export const MODAL_OBJECT_TYPE_CHANGE_CONFIRM =
  'ripcord/MODAL_OBJECT_TYPE_CHANGE_CONFIRM';
export const MODAL_PERMISSIONS = 'ripcord/MODAL_PERMISSIONS';
export const MODAL_UPLOAD_MODAL = 'ripcord/MODAL_UPLOAD_MODAL';
export const MULTIPLE_CLASSIFICATIONS_MODAL =
  'ripcord/MULTIPLE_CLASSIFICATIONS_MODAL ';
export const SAVED_SEARCH_MODAL = 'ripcord/SAVED_SEARCH_MODAL';
export const TAXONOMY_GROUPING_MODAL = 'ripcord/TAXONOMY_GROUPING_MODAL';
export const TEMP_PASSWORD_MODAL = 'ripcord/TEMP_PASSWORD_MODAL';
export const VIEW_RENDITION_ATTRIBUTES_MODAL =
  'ripcord/VIEW_RENDITION_ATTRIBUTES_MODAL';
export const MERGE_DOCUMENTS_MODAL = 'ripcord/MERGE_DOCUMENTS_MODAL';
