import { AND } from '@rsql/ast';

import type { ComparisonNode, ExpressionNode } from '@rsql/ast';

import {
  emit,
  getAttributeNameFromNode,
  parse,
  walkLogicBranch
} from '../../utils/ast';
import builder, { ILIKE } from '../../utils/rsql-builder';

export type ParsedTaxonomyUrlSplat = Array<ExpressionNode>;

export function parseTaxonomyUrlSplat(
  taxSplat: string
): ParsedTaxonomyUrlSplat {
  // * decodeURIComponent must be used here, not decodeURI. decodeURI will miss special characters (CAT-202)
  return taxSplat
    .split('/')
    .filter((s) => !!s)
    .map((s) => parse(decodeURIComponent(s)));
}

export function buildTaxonomyUrlSplat(parsedFilters: ParsedTaxonomyUrlSplat) {
  return (
    // * encodeURIComponent must be used here, not encodeURI. encodeURI will miss special characters (CAT-202)
    parsedFilters.map((filter) => encodeURIComponent(emit(filter))).join('/') +
    (parsedFilters.length > 0 ? '/' : '')
  );
}

export function buildLeafNodesFilterString(
  parsedFilters: ParsedTaxonomyUrlSplat,
  taxonomyBaseType: string,
  definitionQueryFilter: string
) {
  let expr = builder.and(...parsedFilters, builder.eq('type', taxonomyBaseType));
  if (!!definitionQueryFilter) {
    expr = builder.and(parse(definitionQueryFilter), expr);
  }
  return emit(expr);
}

/**
 * Returns just the AST of the filters which are for the given attribute
 */
export function extractFiltersForThisLevel(
  node: ExpressionNode,
  attributeName?: string,
  nodeToRemove?: { operator: string; value: string }
) {
  const comparisonNodes: ComparisonNode[] = [];
  walkLogicBranch(AND, node, (compNode) =>
    comparisonNodes.push(compNode as ComparisonNode)
  );
  let hasRemoved = false;

  const filteredComparisonNodes = comparisonNodes.filter((compNode) => {
    // shouldRemove is pulling the filter panel filter out of the bucket filter
    // hasRemoved is fixing a bug where if the panel filter equals the bucket filter they were both being removed and no label was shown
    const shouldRemove =
      hasRemoved || !nodeToRemove
        ? false
        : compNode?.operator === nodeToRemove?.operator &&
          (compNode?.right?.value! === nodeToRemove?.value ||
            (compNode?.operator === ILIKE &&
              compNode?.right?.value! === `*${nodeToRemove?.value}*`));
    if (shouldRemove) {
      hasRemoved = true;
    }

    return (
      (attributeName == null ||
        getAttributeNameFromNode(compNode) === attributeName) &&
      !shouldRemove
    );
  });
  return filteredComparisonNodes.length
    ? builder.and(...filteredComparisonNodes)
    : null;
}
