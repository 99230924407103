/* eslint-disable @typescript-eslint/no-restricted-imports */
import {
  generatePath as generatePathRRD,
  Redirect as RedirectRRD,
  Route as RouteRRD,
  Switch as SwitchRRD
} from 'react-router-dom';

import type { ComponentType } from 'react';
import type {
  generatePath as generatePathType,
  RedirectProps,
  RouteProps,
  SwitchProps
} from '../generatedTsTypes/React-Router-Dom';

import { ERR_404_ROUTE as AC_ERR_404_ROUTE } from '../app/admin-console/routes';
import { ERR_404_ROUTE as CANOPY_ERR_404_ROUTE } from '../app/canopy/routes';

// Whenever we are creating a link, we should use the `generatePath` function and one of the route
// constants to build the link programmatically. See https://v5.reactrouter.com/web/api/generatePath
// export it here to fix the type so it's not any
export const generatePath = generatePathRRD as typeof generatePathType;

export const Redirect = RedirectRRD as ComponentType<RedirectProps>;
export const Route = RouteRRD as ComponentType<RouteProps>;
export const Switch = SwitchRRD as ComponentType<SwitchProps>;

/**
 * This has to be a function that exports the variables to avoid circular dependencies. Otherwise it tries to use the route before they're fully defined
 */
export const getUniversal404Route = () =>
  process.env.APP_NAME === 'canopy' ? CANOPY_ERR_404_ROUTE : AC_ERR_404_ROUTE;
