import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useTypedSelector } from '../../store/hooks';

import type { Principal } from './tsTypes';

import { useAutocompleteGroups } from '../groups/hooks';
import { useAutocompleteUsers } from '../users/hooks';
import { requestPrincipalData } from './actions';
import { PRINCIPAL_TYPE } from './utils';

/**
 * Used to resolve principal data from their id. Currently primarily used
 * to resolve a name for the principal. resolvedName already handles all of
 * the fallback logic and can be consumed directly.
 * @param {string} principalId id of principal whose information you need
 */
export function usePrincipalData(principalId: Principal['id'] | null) {
  const dispatch = useDispatch();
  const { resolvedPrincipals } = useTypedSelector(
    (state) => state.mts.principals
  );
  const resolvedPrincipal = resolvedPrincipals.get(principalId ?? '');
  useEffect(() => {
    if (!!principalId && !resolvedPrincipal) {
      dispatch(requestPrincipalData({ principalId }));
    }
  }, [dispatch, resolvedPrincipal, principalId]);

  const principalData = resolvedPrincipal ?? {
    isLoading: true,
    error: null,
    data: null
  };

  return principalData;
}

export function useAutocompletePrincipal() {
  const {
    loading: usersLoading,
    loadingError: usersLoadingError,
    users,
    fetchAutocompleteUsers
  } = useAutocompleteUsers();
  const {
    loading: groupsLoading,
    loadingError: groupsLoadingError,
    groups,
    fetchAutocompleteGroups
  } = useAutocompleteGroups();

  const principals = useMemo(
    () =>
      (
        groups?.map(
          (group) =>
            ({
              ...group,
              principalType: PRINCIPAL_TYPE.GROUP
            } as Principal)
        ) ?? []
      ).concat(
        users?.map(
          (user) =>
            ({
              ...user,
              principalType: PRINCIPAL_TYPE.USER
            } as Principal)
        ) ?? []
      ),
    [groups, users]
  );

  return {
    loading: usersLoading || groupsLoading,
    loadingError: usersLoadingError ?? groupsLoadingError,
    principals,
    fetchAutocomplete: useCallback(
      (searchValue) => {
        fetchAutocompleteUsers(searchValue);
        fetchAutocompleteGroups(searchValue);
      },
      [fetchAutocompleteGroups, fetchAutocompleteUsers]
    )
  } as const;
}
