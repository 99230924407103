import { createAction } from '@reduxjs/toolkit';

import type { State } from './reducer';
import type { Group } from './tsTypes';

export const fetchGroupsListSucceeded = createAction(
  'ripcord/mts/list-groups/FETCH_GROUP_LIST_SUCCEEDED',
  ({
    groups,
    paginationParams
  }: {
    groups: Group[];
    paginationParams: {
      number: number;
      size: number;
      totalElements: number;
      totalPages: number;
    };
  }) => ({
    payload: { groups, paginationParams }
  })
);
export const fetchGroupsListFailed = createAction(
  'ripcord/mts/list-groups/FETCH_GROUP_LIST_FAILED',
  (error: State['groupLoadingError']) => ({ payload: error })
);
export const fetchGroupsList = createAction(
  'ripcord/mts/list-groups/FETCH_GROUP_LIST',
  (queryParams: State['queryParams']) => ({
    payload: {
      queryParams,
      success: fetchGroupsListSucceeded,
      failure: fetchGroupsListFailed
    }
  })
);

export const fetchGroupSucceeded = createAction(
  'ripcord/mts/list-groups/FETCH_GROUP_SUCCEEDED',
  (group: Group | null) => ({ payload: group })
);
export const fetchGroupFailed = createAction(
  'ripcord/mts/list-groups/FETCH_GROUP_FAILED',
  (error: State['groupLoadingError']) => ({ payload: error })
);
export const fetchGroup = createAction(
  'ripcord/mts/list-groups/FETCH_GROUP',
  (id: Group['id']) => ({
    payload: {
      id,
      success: fetchGroupSucceeded,
      failure: fetchGroupFailed
    }
  })
);

export const fetchAutocompleteGroupsSucceeded = createAction(
  'ripcord/mts/list-groups/FETCH_AUTOCOMPLETE_GROUPS_SUCCEEDED',
  (groups: Group[]) => ({ payload: groups! })
);
export const fetchAutocompleteGroupsFailed = createAction(
  'ripcord/mts/list-groups/FETCH_AUTOCOMPLETE_GROUPS_FAILED',
  (error: State['autocompleteLoadingError']) => ({ payload: error })
);

export const fetchAutocompleteGroups = createAction(
  'ripcord/mts/list-groups/FETCH_AUTOCOMPLETE_GROUPS',
  (searchValue?: string) => ({
    payload: {
      searchValue,
      success: fetchAutocompleteGroupsSucceeded,
      failure: fetchAutocompleteGroupsFailed
    }
  })
);

export const fetchGroupMembersSucceeded = createAction(
  'ripcord/mts/list-groups/FETCH_GROUP_MEMBERS_SUCCEEDED',
  ({
    groupMembers,
    moreMembersAvailable
  }: {
    groupMembers: State['groupMembers'];
    moreMembersAvailable: State['moreMembersAvailable'];
  }) => ({
    payload: { groupMembers, moreMembersAvailable }
  })
);
export const fetchGroupMembersFailed = createAction(
  'ripcord/mts/list-groups/FETCH_GROUP_MEMBERS_FAILED',
  (error: State['groupMembersLoadingError']) => ({ payload: error })
);
export const fetchGroupMembers = createAction(
  'ripcord/mts/list-groups/FETCH_GROUP_MEMBERS',
  (groupMemberQueryParams?: State['groupMemberQueryParams']) => ({
    payload: {
      groupMemberQueryParams,
      success: fetchGroupMembersSucceeded,
      failure: fetchGroupMembersFailed
    }
  })
);

export const saveGroupSucceeded = createAction(
  'ripcord/mts/list-groups/SAVE_GROUP_SUCCEEDED',
  (group: Group) => ({ payload: { group } })
);
export const saveGroupFailed = createAction(
  'ripcord/mts/list-groups/SAVE_GROUP_FAILED',
  (error: State['savingError']) => ({ payload: error })
);
export const saveGroup = createAction(
  'ripcord/mts/list-groups/SAVE_GROUP',
  (group: Partial<Group>) => ({
    payload: {
      group,
      success: saveGroupSucceeded,
      failure: saveGroupFailed
    }
  })
);

export const clearGroupErrors = createAction(
  'ripcord/mts/list-groups/CLEAR_GROUP_ERRORS'
);
