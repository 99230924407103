import React from 'react';
import { t } from '@lingui/macro';
import styled, { css, useTheme } from 'styled-components';

import type { JSONSchema7 } from 'json-schema';
import type { MouseEvent } from 'react';
import type { PicklistOption } from '../../../oms/attributes/tsTypes';
import type {
  PossibleAttributeDataTypes,
  PossibleNumberDataTypes,
  PossibleTextDataTypes
} from '../../../oms/tsTypes';

import { DATETIME_FORMATS } from '../../../config';
import { OMS_CODE_DATA_TYPES, OMS_DATA_TYPES } from '../../../constants';
import { userNameOmsAttributes } from '../../../mts/users/utils';
import { ellipsis } from '../../../ui/components/mixins.styled';
import { ReadOnlyDate } from '../../../ui/components/read-only-date';
import { ReadOnlyDateRange } from '../../../ui/components/read-only-date-range';
import { isNullishFilterValue } from '../../../utils/isNullish';
import { JSONEditorMonacoOptions } from '../../constants';
import SuspenseThrobber from '../../loading/progress-indicator/suspenseThrobber';
import Icon from './IconButton';
import { RipcordPrincipalName } from './RipcordPrincipalName';

const JSONEditor = React.lazy(
  () => import('../admin-console/CodeEditor/JSONEditor')
);
const OMSFiltersInput = React.lazy(
  () => import('../admin-console/CodeEditor/OMSFiltersInput')
);

export type Props = {
  className?: string;
  isFilter?: boolean;
  attributeType?: PossibleAttributeDataTypes;
  attributeName?: string;
  attributeSchema?: JSONSchema7;
  options?: PicklistOption[];
  label?: string;
  value?: any;
  required?: boolean;
  emptyDisplayValue?: string;
  onClick?: ((event: MouseEvent<HTMLDivElement>) => void) | null;
  hasChips?: boolean;
  shouldUseTableStyles?: boolean;
  noLeftPadding?: boolean;
  deleteRelationship?: () => void;
};

/*
  Label + value display component
  @param {string} label - Name / label of the field
  @param {string} value - Value probably from an OMS object
* */
export default function RipcordDisplayValue({
  className,
  isFilter,
  attributeType = OMS_DATA_TYPES.STRING,
  attributeName,
  attributeSchema,
  options,
  label,
  value,
  required,
  emptyDisplayValue = '--',
  onClick,
  hasChips,
  shouldUseTableStyles,
  deleteRelationship,
  ...restProps
}: Props) {
  const theme = useTheme();
  // eslint-disable-next-line no-undef
  let displayValue: undefined | string | JSX.Element;
  const isTypeAttr = attributeName === 'type';

  switch (attributeType) {
    case OMS_DATA_TYPES.BOOLEAN: {
      displayValue = value ? t`Yes` : t`No`;
      break;
    }
    case OMS_DATA_TYPES.DATE: {
      if (isFilter) {
        const isoDateRange = isFilter && !!value ? value?.split(' ', 3) : null;
        const startISO = !isNullishFilterValue(isoDateRange?.[0], [
          'null',
          'undefined'
        ])
          ? isoDateRange?.[0]
          : null;
        const endISO = !isNullishFilterValue(isoDateRange?.[2], [
          'null',
          'undefined'
        ])
          ? isoDateRange?.[2]
          : null;
        displayValue = (
          <ReadOnlyDateRange
            startISO={startISO}
            endISO={endISO}
            textFormat={DATETIME_FORMATS.DATE.SHORT}
            tooltipFormat={DATETIME_FORMATS.DATE.LONG}
          />
        );
      } else {
        displayValue = (
          <ReadOnlyDate
            isoString={value}
            textFormat={DATETIME_FORMATS.DATE.SHORT}
            tooltipFormat={DATETIME_FORMATS.DATE.LONG}
          />
        );
      }
      break;
    }
    case OMS_DATA_TYPES.DATETIME: {
      if (isFilter) {
        const isoDateRange = isFilter && !!value ? value?.split(' ', 3) : null;
        const startISO = !isNullishFilterValue(isoDateRange?.[0], [
          'null',
          'undefined'
        ])
          ? isoDateRange?.[0]
          : null;
        const endISO = !isNullishFilterValue(isoDateRange?.[2], [
          'null',
          'undefined'
        ])
          ? isoDateRange?.[2]
          : null;
        displayValue = (
          <ReadOnlyDateRange
            startISO={startISO}
            endISO={endISO}
            isoString={value}
            textFormat={DATETIME_FORMATS.DATETIME.SHORT}
            tooltipFormat={DATETIME_FORMATS.DATETIME.LONG}
          />
        );
      } else {
        displayValue = (
          <ReadOnlyDate
            isoString={value}
            textFormat={DATETIME_FORMATS.DATETIME.SHORT}
            tooltipFormat={DATETIME_FORMATS.DATETIME.LONG}
          />
        );
      }
      break;
    }
    case OMS_DATA_TYPES.PICK_LIST: {
      displayValue = options?.find((option) => option.value === value)?.label;
      break;
    }
    case OMS_DATA_TYPES.QUERY_FILTER: {
      displayValue = (
        <OMSFiltersInput
          editorId={`query-filter-${attributeName}`}
          filterString={value}
        />
      );
      break;
    }
    case OMS_DATA_TYPES.PROPERTY_BAG: {
      displayValue = (
        <StyledJSONEditor
          jsonSchema={{
            name: attributeName,
            // This URI isn't actually a real location, it's just an identifier, but jsonschema requires them to be valid URIs so i'm using a fake one here. Keeping it on the ripcord.com domain will prevent hijacking if future iterations of the product ever try to fetch this endpoint.
            uri: `https://ripcord.com/fake-schema-path/${attributeName}.json`,
            schema: attributeSchema
          }}
          stringJsonData={JSON.stringify(value ?? {}, undefined, 2)}
          readOnly
          additionalMonacoOptions={JSONEditorMonacoOptions}
        />
      );

      break;
    }
    case OMS_DATA_TYPES.STRING:
    case OMS_DATA_TYPES.TEXT:
    case OMS_DATA_TYPES.UUID:
    default: {
      attributeType satisfies PossibleNumberDataTypes | PossibleTextDataTypes;
      if (userNameOmsAttributes.has(attributeName!)) {
        displayValue = <RipcordPrincipalName principalId={value} />;
      } else {
        displayValue = value;
      }
      break;
    }
  }
  const isEditable = typeof onClick === 'function';
  const finalDisplayValue =
    typeof value === 'undefined' || value === null || value === ''
      ? emptyDisplayValue
      : displayValue;
  const hasLabel = !!label;
  const showingValue = !hasChips && finalDisplayValue !== '';
  const isPropertyBag = attributeType === OMS_DATA_TYPES.PROPERTY_BAG;

  return (
    <Container
      $noLeftPadding={restProps.noLeftPadding}
      $hasLabel={hasLabel}
      $isEditable={isEditable}
      $noHoverBackground={isPropertyBag}
      className={className}
      onClick={!!onClick ? onClick : undefined}
      {...restProps}>
      <SuspenseThrobber>
        <ContentWrapper
          $hasLabel={hasLabel}
          $showingValue={showingValue}
          $isTypeAttr={isTypeAttr}
          $showingCodeViewer={attributeType in OMS_CODE_DATA_TYPES}>
          {hasLabel && !shouldUseTableStyles && (
            <StyledLabelParagraph $isTypeAttr={isTypeAttr}>
              {label}
              {!!required ? <StyledSpan>*</StyledSpan> : null}
            </StyledLabelParagraph>
          )}
          {showingValue && (
            <StyledParagraph>{finalDisplayValue}</StyledParagraph>
          )}
        </ContentWrapper>
        {isEditable && !isPropertyBag && (
          <EditIcon
            variant={'rename'}
            size={18}
            iconColorHover={theme.colors.gray[500]}
          />
        )}
        {!!deleteRelationship && attributeName === 'relationshipId' && (
          <DeleteIcon
            variant={'deleteIcon'}
            size={18}
            iconColorHover={theme.colors.red[500]}
            onClick={deleteRelationship}
          />
        )}
      </SuspenseThrobber>
    </Container>
  );
}

const DeleteIcon = styled(Icon)`
  && {
    position: absolute;
    right: 5px;
    color: ${({ theme }) => theme.colors.gray[500]};
    &:hover {
      color: ${({ theme }) => theme.colors.red[500]};
      background-color: unset;
    }
  }
  visibility: hidden;
`;

const Container = styled.div<{
  $noLeftPadding?: boolean;
  $hasLabel?: boolean;
  $isEditable: boolean;
  $noHoverBackground?: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${(p) =>
    p.$noLeftPadding
      ? 'padding: 7px 0px;'
      : p.$hasLabel
      ? 'padding: 10px 0 10px 0;'
      : ''}
  justify-content: space-between;
  ${({ $isEditable, $noHoverBackground }) => {
    if ($isEditable) {
      return css`
        &:hover {
          background-color: ${({ theme }) =>
            $noHoverBackground
              ? 'unset'
              : theme.muiTheme.palette.primary['100']} 
          cursor: pointer;
          button {
            visibility: visible;
          }
        }
      `;
    }
  }};
  &:hover {
    ${DeleteIcon} {
      visibility: visible;
    }
  }
`;

const ContentWrapper = styled.div<{
  $hasLabel: boolean;
  $showingValue: boolean;
  $isTypeAttr: boolean;
  $showingCodeViewer: boolean;
}>`
  width: 100%;
  ${(props) =>
    props.$showingCodeViewer
      ? ''
      : props.$isTypeAttr
      ? 'height: 46px;'
      : props.$showingValue && props.$hasLabel
      ? 'height: 42px;'
      : ''}
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const StyledLabelParagraph = styled.p<{ $isTypeAttr: boolean }>`
  ${ellipsis};
  color: ${({ theme }) => theme.colors.gray['400']};
  font-size: 12px;
  font-weight: 400;
  margin: 0;
  margin-bottom: ${(p) => (p.$isTypeAttr ? '4px' : 0)};
`;

const StyledParagraph = styled.div`
  margin: 0;
  color: ${({ theme }) => theme.colors.gray['800']};
  ${ellipsis};
`;

export const StyledSpan = styled.span`
  color: ${({ theme }) => theme.colors.common.error};
`;

const EditIcon = styled(Icon)`
  && {
    position: absolute;
    right: 5px;
    color: ${({ theme }) => theme.colors.gray[500]};
    &:hover {
      color: ${({ theme }) => theme.colors.gray[500]};
      background-color: unset;
    }
  }
  visibility: hidden;
`;

const StyledJSONEditor = styled(JSONEditor)`
  && {
    height: 150px;
  }
`;
