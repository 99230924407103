import styled, { css, keyframes } from 'styled-components';

const loadingTickAnimation = keyframes`
    0%,
  100% {
    transform: scaleY(0.65);
    opacity: 0.4;
  }
  50% {
    transform: scaleY(1);
    opacity: 1;
  }
`;
const loadingDotAnimation = keyframes`
    0%,
  100% {
    opacity: 0.4;
  }
  50% {
    opacity: 1;
  }
`;

const Tick = styled.div<{
  $isDot: boolean;
}>`
  background-color: ${({ theme }) => theme.muiTheme.palette.primary.main};
  ${({ $isDot }) =>
    $isDot
      ? css`
          height: 4px;
          width: 4px;
          border-radius: 50%;
          animation: ${loadingDotAnimation} 1s infinite ease-in-out;
        `
      : css`
          height: 40px;
          width: 8px;
          animation: ${loadingTickAnimation} 1s infinite ease-in-out;
        `}
  margin-left: 8px;

  &:first-child {
    margin-left: 0;
  }
`;

export const LoadingThrobber = styled.div<{ relative?: boolean }>`
  ${({ relative }) =>
    !!relative
      ? `
          height: 100%;
          place-content: center;
          place-items: center;
        `
      : `
          margin: auto;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        `}
  z-index: 100;
  display: flex;
`;

export const LeftTick = styled(Tick)`
  left: 21px;
  animation-delay: -0.6s;
`;

export const MiddleTick = styled(Tick)`
  left: 46px;
  animation-delay: -0.3s;
`;

export const RightTick = styled(Tick)`
  left: 71px;
  animation-delay: -0s;
`;
