import { createAction } from '@reduxjs/toolkit';

import type { OMSResponsePagination } from '../../oms/objects/tsTypes';
import type { UUID } from '../../oms/tsTypes';
import type { Group } from '../groups/tsTypes';
import type { User, UsersEndpointQueryParams } from './tsTypes';

export const fetchUsersList = createAction(
  'ripcord/mts/list-users/FETCH_USER_LIST',
  (queryParams: UsersEndpointQueryParams) => ({
    payload: {
      queryParams
    }
  })
);

export const fetchUsersListSucceeded = createAction(
  'ripcord/mts/list-users/FETCH_USER_LIST_SUCCEEDED',
  (users: Array<User> | undefined, page: OMSResponsePagination) => ({
    payload: {
      users,
      page
    }
  })
);
export const fetchUsersListFailed = createAction(
  'ripcord/mts/list-users/FETCH_USER_LIST_FAILED',
  (error: Error | null) => ({
    payload: error
  })
);

export const fetchUser = createAction(
  'ripcord/mts/list-users/FETCH_USER',
  (id: UUID) => ({
    payload: {
      id
    }
  })
);

export const fetchUserSucceeded = createAction(
  'ripcord/mts/list-users/FETCH_USER_SUCCEEDED',
  (user: User | null | undefined) => ({
    payload: user
  })
);
export const fetchUserFailed = createAction(
  'ripcord/mts/list-users/FETCH_USER_FAILED',
  (error: Error | null) => ({
    payload: error
  })
);

export const fetchAutocompleteUsers = createAction(
  'ripcord/mts/list-users/FETCH_AUTOCOMPLETE_USERS',
  (searchValue: string) => ({
    payload: {
      searchValue
    }
  })
);

export const fetchAutocompleteUsersSucceeded = createAction(
  'ripcord/mts/list-users/FETCH_AUTOCOMPLETE_USERS_SUCCEEDED',
  (users: Array<User> | undefined) => ({
    payload: users
  })
);
export const fetchAutocompleteUsersFailed = createAction(
  'ripcord/mts/list-users/FETCH_AUTOCOMPLETE_USERS_FAILED',
  (error: Error | null) => ({
    payload: error
  })
);

export const saveUser = createAction(
  'ripcord/mts/list-users/SAVE_USER',
  (
    user: Partial<User>,
    toastMessage?: string,
    updateGroupsPayload?: {
      userId: string;
      group: Group;
      addOrRemove: 'add' | 'remove';
    }
  ) => ({
    payload: {
      user,
      toastMessage,
      updateGroupsPayload
    }
  })
);

export const saveUserSucceeded = createAction(
  'ripcord/mts/list-users/SAVE_USER_SUCCEEDED',
  (user: User) => ({
    payload: {
      user
    }
  })
);

export const saveUserFailed = createAction(
  'ripcord/mts/list-users/SAVE_USER_FAILED',
  (error: Error | null) => ({
    payload: error
  })
);

export const deleteUser = createAction(
  'ripcord/mts/users/DELETE_USER',
  (id: UUID) => ({
    payload: {
      id
    }
  })
);

export const deleteUserSucceeded = createAction(
  'ripcord/mts/users/DELETE_USER_SUCCEEDED',
  (id: UUID) => ({
    payload: {
      id
    }
  })
);

export const deleteUserFailed = createAction(
  'ripcord/mts/users/DELETE_USER_FAILED',
  (error: Error | null) => ({
    payload: error
  })
);
