import React, { useCallback, useEffect, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { useDispatch, useTypedSelector } from '../../store/hooks';

import type { UUID } from '../../oms/tsTypes';
import type { Group } from '../groups/tsTypes';
import type { State } from './reducer';
import type { User, UsersEndpointQueryParams } from './tsTypes';

import { logout } from '../../auth/keycloak';
import { getUserUUID } from '../../auth/keycloakSelectors';
import { useConfirmModal } from '../../modals/components/confirm-modal/hooks';
import { RipcordPrincipalName } from '../../ripcord-components/components/ripcord/RipcordPrincipalName';
import { useAdvancedLoading } from '../../utils/hooks';
import { getUsersById } from '../selectors';
import {
  deleteUser,
  fetchAutocompleteUsers,
  fetchUser,
  fetchUsersList,
  fetchUserSucceeded,
  saveUser
} from './actions';

export function useUsers(queryParams: UsersEndpointQueryParams) {
  const dispatch = useDispatch();
  const { loading, loadingError, totalNumUsers, users, saving, savingError } =
    useTypedSelector((state) => state.mts.users);
  const usersById = useTypedSelector(getUsersById);

  useEffect(() => {
    dispatch(fetchUsersList(queryParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, JSON.stringify(queryParams)]); // Use fake deep object comparison

  return {
    loading,
    loadingError,
    users,
    saving,
    savingError,
    usersById,
    totalNumUsers,
    queryParams
  } as const;
}

export function useSaveUser() {
  const dispatch = useDispatch();
  const { saving, savingError } = useTypedSelector((state) => state.mts.users);

  return {
    saveUser: useCallback(
      <
        UpdateGroupsPayload extends
          | {
              userId: string;
              group: Group;
              addOrRemove: 'add' | 'remove';
            }
          | undefined
      >(
        user: UpdateGroupsPayload extends undefined ? Partial<User> : null,
        toastMessage?: string,
        updateGroupsPayload?: UpdateGroupsPayload
      ) => {
        return dispatch(saveUser(user!, toastMessage, updateGroupsPayload));
      },
      [dispatch]
    ),
    saving,
    savingError
  } as const;
}

export function useUser(id: UUID | null) {
  const dispatch = useDispatch();
  const { userLoading, userLoadingError, user } = useTypedSelector(
    (state) => state.mts.users
  );

  useEffect(() => {
    if (!!id) {
      dispatch(fetchUser(id));
    }

    return () => {
      // clear out the user object when leaving the page so we don't leave old data around
      dispatch(fetchUserSucceeded(null));
    };
  }, [dispatch, id]);

  return {
    loading: userLoading,
    loadingError: userLoadingError,
    user: user as State['user']
  } as const;
}

export function useAutocompleteUsers() {
  const dispatch = useDispatch();
  const { autocompleteLoading, autocompleteLoadingError, autocompleteUsers } =
    useTypedSelector((state) => state.mts.users);

  return {
    loading: autocompleteLoading,
    loadingError: autocompleteLoadingError,
    users: autocompleteUsers,
    fetchAutocompleteUsers: useCallback(
      (searchValue: string) => {
        dispatch(fetchAutocompleteUsers(searchValue));
      },
      [dispatch]
    )
  } as const;
}

export function useDeleteUser() {
  const dispatch = useDispatch();
  const [isDeletingOwnAccount, setIsDeletingOwnAccount] =
    useState<boolean>(false);
  const { deleting, deletingError } = useTypedSelector(
    (state) => state.mts.users
  );

  const showConfirmationDialog = useConfirmModal();

  const deleteUserCallback = useCallback(
    (userId: UUID) => {
      const isOwnAccount = userId === getUserUUID();
      showConfirmationDialog({
        title: t`Confirm Account Deletion`,
        showWarningIcon: true,
        showLineAboveActions: true,
        text: isOwnAccount ? (
          <Trans>
            Are you sure you want to delete your account?
            <br />
            <br />
            This will delete all data associated with your account, and there is
            no way to undo this!
          </Trans>
        ) : (
          <Trans>
            Are you sure you want to delete the
            <RipcordPrincipalName showSmallerIcon principalId={userId} />{' '}
            account?
            <br />
            <br />
            This will delete all data associated with the account, and there is
            no way to undo this!
          </Trans>
        ),
        confirmText: 'Delete',
        onConfirm: () => {
          setIsDeletingOwnAccount(isOwnAccount);
          dispatch(deleteUser(userId));
        }
      });
    },
    [dispatch, showConfirmationDialog]
  );

  const { submittedSuccessfully } = useAdvancedLoading(deleting, deletingError);

  useEffect(() => {
    if (submittedSuccessfully && isDeletingOwnAccount) {
      logout();
    }
  }, [submittedSuccessfully, isDeletingOwnAccount]);

  return {
    deleteUser: deleteUserCallback,
    deleting,
    deletingError,
    userDeleted: submittedSuccessfully
  } as const;
}
