import { useEffect, type ReactNode } from 'react';
import { useHistory } from 'react-router-dom';
import timber from '../timber/macro';
import { RipcordError } from '../errors';

export type PossiblePostMessagePayloads =
  | {
      type: 'readyForHydration';
    }
  | {
      type: 'hydrate';
      state: unknown;
    };

export default function openInNewTab({
  pathname,
  search,
  hash,
  state
}: {
  pathname: string;
  search?: string;
  hash?: string;
  state?: unknown;
}) {
  return new Promise<void>((resolve, reject) => {
    const timeout = setTimeout(() => {
      window.removeEventListener('message', handler);
      reject(
        new RipcordError({
          message: 'timed out trying to send data to the new tab'
        })
      );
    }, 15000);

    const handler = (event: MessageEvent<PossiblePostMessagePayloads>) => {
      if (
        event.source === newTabWindow &&
        event.data.type === 'readyForHydration'
      ) {
        newTabWindow?.postMessage(
          {
            type: 'hydrate',
            state
          },
          window.origin
        );
        window.removeEventListener('message', handler);
        clearTimeout(timeout);
        resolve();
      }
    };

    window.addEventListener('message', handler);

    const newTabWindow = window.open(
      `${pathname}${search ?? ''}${hash ?? ''}`,
      '_blank'
    );
  });
}

export type Props = {
  children: ReactNode;
};
export function HydrateRouterStateProvider({ children }: Props) {
  const history = useHistory();

  useEffect(() => {
    const handlePostMessage = (
      event: MessageEvent<PossiblePostMessagePayloads>
    ) => {
      if (
        event.source === window.opener &&
        event.data?.type === 'hydrate' &&
        event.data?.state
      ) {
        timber.debug(
          'Hydrating the react-router-dom state with data from postmessage...'
        );
        history.replace(
          history.location,
          event.data?.state ?? history.location.state ?? {}
        );
      }
    };

    if (window.opener) {
      window.addEventListener('message', handlePostMessage);

      // announce to the parent (if we have one) that we are ready to receive the data
      window.opener.postMessage(
        {
          type: 'readyForHydration'
        },
        window.origin
      );
    }
    return () => {
      window.removeEventListener('message', handlePostMessage);
    };
  }, [history]);

  return children;
}
