import { createAction } from '@reduxjs/toolkit';

import type { State } from '../reducers';

export const resetAccountInfo = createAction(
  'ripcord/account/RESET_ACCOUNT_INFO'
);

export const fetchAccountInfo = createAction(
  'ripcord/account/FETCH_ACCOUNT_INFO_REQUESTED'
);
export const fetchAccountInfoSucceeded = createAction(
  'ripcord/account/FETCH_ACCOUNT_INFO_SUCCEEDED',
  (
    payload: {
      accountData: State['data'];
    } & Pick<
      State,
      | 'rootFolder'
      | 'sourceRootFolder'
      | 'userHomeRootFolder'
      | 'savedSearchRootFolder'
      | 'userPersonalRootFolder'
      | 'taxonomyRootFolder'
      | 'collectionsRootFolder'
      | 'notebooksRootFolder'
    >
  ) => ({ payload })
);
export const fetchAccountInfoFailed = createAction(
  'ripcord/account/FETCH_ACCOUNT_INFO_FAILED',
  (error: NonNullable<State['fetchAccountError']>) => ({
    payload: error
  })
);
