import React from 'react';
import PropTypes from 'prop-types';
import ReactSVG from 'react-svg';

import { IconStyled } from './index.styled';

const IconButton = ({
  alt,
  iconSrc,
  isDisabled,
  onClick,
  href,
  // groupId is pulled out to avoid invalid prop warnings. It was never used here and is most likely accidentally included.
  groupId,
  isActive,
  ...otherProps
}) => {
  return (
    <IconStyled
      TouchRippleProps={{ classes: { ripple: 'icon-ripple' } }}
      onClick={isDisabled ? undefined : onClick}
      disabled={!!isDisabled}
      href={isDisabled ? undefined : href}
      $isActive={isActive}
      {...otherProps}>
      <ReactSVG title={alt} src={iconSrc} />
    </IconStyled>
  );
};

IconButton.propTypes = {
  alt: PropTypes.string,
  iconSrc: PropTypes.string,
  href: PropTypes.string,
  isActive: PropTypes.bool,
  isDisabled: PropTypes.any,
  onClick: PropTypes.func
};

IconButton.defaultProps = {
  isDisabled: false,
  isActive: false,
  iconSrc: ''
};

export default IconButton;
