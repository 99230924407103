import { matchPath } from 'react-router';

/**
 * Returns true if the given pathname matches the given route
 * or one of given routes
 */
export const isOnRoute = (
  pathname: string | null,
  compare: string | string[]
) => {
  switch (typeof compare) {
    case 'string':
      return matchPath(pathname, { path: compare });
    case 'object':
      return !!compare?.some
        ? compare.some((route) => matchPath(pathname, { path: route }))
        : false;
    default:
      return false;
  }
};
