import { DateTime } from 'luxon';
import type { ValueOf } from '../utils/tsUtilityTypes';

/**
 * These are presets from Luxon.
 * https://moment.github.io/luxon/#/formatting?id=presets
 * They take the form of Intl DateTimeFormat configuration objects.
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat#parameters
 * These are inherently locale agnostic and adapt to the locale in terms of symbols used and ordering.
 * These are not compatible with token-based formatting (e.g. MM/DD/YY)
 */
export const DATETIME_FORMATS = {
  DATE: {
    SHORT: DateTime.DATE_SHORT,
    LONG: DateTime.DATE_FULL
  },
  TIME: {
    SHORT: DateTime.TIME_SIMPLE,
    LONG: DateTime.TIME_WITH_LONG_OFFSET
  },
  DATETIME: {
    MINI: DateTime.DATETIME_SHORT,
    SHORT: DateTime.DATETIME_SHORT_WITH_SECONDS,
    LONG: DateTime.DATETIME_FULL_WITH_SECONDS
  },
  RELATIVE: 'relative'
} as const;

export type PossibleExactDateTimeFormats = ValueOf<
  ValueOf<Omit<typeof DATETIME_FORMATS, 'RELATIVE'>>
>;
export type PossibleDatetimeFormats =
  | PossibleExactDateTimeFormats
  | ValueOf<Pick<typeof DATETIME_FORMATS, 'RELATIVE'>>;

export const IMPORT_SUPPORTED_MIME_TYPES = {
  JSON: 'application/json',
  CSV: 'text/csv'
} as const;
export type PossibleImportSupportedMimeTypes = ValueOf<
  typeof IMPORT_SUPPORTED_MIME_TYPES
>;

export const API = {
  MTS: { USERS: { DEBOUNCE_WAIT: 300 }, PRINCIPALS: { DEBOUNCE_WAIT: 300 } },
  OMS: { OBJECTS: { DEBOUNCE_WAIT: 300 } },
  JOB: { INSTANCES: { POLL_WAIT: 5000 } },
  MIXPANEL: { DEBOUNCE_WAIT: 300 }
} as const;

export const PAGINATION = {
  DEFAULT_PAGE: 0,
  DEFAULT_SIZE: 24,
  PAGER_SIZE: 10
} as const;

export const TENANT_POLLING_TIME_MS = 5000;

export const BETA_FEATURES = [];
