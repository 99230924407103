import { createBrowserHistory } from 'history';
import { matchPath } from 'react-router-dom';

import type { History } from 'history';

export { isOnRoute } from '../sharedUtils';

// paths are defined using the underlying implementation used in react router:
// https://github.com/pillarjs/path-to-regexp/tree/v1.7.0
// the BASE_ROUTE here must be hardcoded, it cannot be generated from app name.
export const BASE_ROUTE = '/admin-console';

export const ERR_404_ROUTE = `${BASE_ROUTE}/404` as const;
export const TENANT_NOT_FOUND_ROUTE = `${BASE_ROUTE}/tenant-not-found` as const;
export const DEMO_PAGE_ROUTE = `${BASE_ROUTE}/demo-ripcord-components` as const;
export const DUMP_PAGE_ROUTE = `${BASE_ROUTE}/dump-dev-data` as const;
export const TENANTS_ROUTE = `${BASE_ROUTE}/tenants` as const;
export const WORKFLOW_MANAGER_ROUTE = `${BASE_ROUTE}/workflowManager` as const;
export const TENANT_CREATE_ROUTE = `${BASE_ROUTE}/createTenant` as const;
export const GLOBAL_ADMINS_ROUTE = `${BASE_ROUTE}/admins` as const;
export const GLOBAL_GROUPS_ROUTE = `${BASE_ROUTE}/groups` as const;
export const TENANT_URL_PREFIX = `${BASE_ROUTE}/tenant/:realmName?` as const;

export const ERR_TENANT_404_ROUTE = `${TENANT_URL_PREFIX}/404` as const;
export const TENANT_GENERAL_ROUTE = `${TENANT_URL_PREFIX}/general` as const;
export const DATA_MODELS_ROUTE = `${TENANT_URL_PREFIX}/data-models` as const;
export const TENANT_GROUPS_ROUTE = `${TENANT_URL_PREFIX}/groups` as const;
export const TENANT_PERMISSIONS_ROUTE =
  `${TENANT_URL_PREFIX}/permissions` as const;
export const LEGACY_TENANT_DISPLAY_CONFIG_ROUTE =
  `${TENANT_URL_PREFIX}/display-configuration/:featureName?` as const;
export const TENANT_DISPLAY_CONFIG_ROUTE =
  `${TENANT_URL_PREFIX}/display-configurations/:tabPath?/:featureName?` as const;
export const TENANT_PROPOSAL_ROUTE =
  `${TENANT_URL_PREFIX}/proposal/:tabPath?` as const;
export const TENANT_USERS_ROUTE = `${TENANT_URL_PREFIX}/users` as const;
export const GLOBAL_ADMINS_FORM_ROUTE =
  `${GLOBAL_ADMINS_ROUTE}/:userId` as const;
export const TENANT_JOBS_ROUTE = `${TENANT_URL_PREFIX}/jobs` as const;

export const MODEL_TYPE_ROUTE = `${DATA_MODELS_ROUTE}/:modelType` as const;
export const TENANT_GROUP_PAGE_ROUTE =
  `${TENANT_GROUPS_ROUTE}/:groupId/:tabName?` as const;
export const TENANT_PERMISSIONS_PAGE_ROUTE =
  `${TENANT_PERMISSIONS_ROUTE}/:templateId/:tabName?` as const;
export const TENANT_USERS_FORM_ROUTE = `${TENANT_USERS_ROUTE}/:userId` as const;

export const MODEL_DEFINITION_ROUTE =
  `${DATA_MODELS_ROUTE}/:modelType/:modelId` as const;
export const TENANT_JOBS_CONFIGURATION_ROUTE =
  `${TENANT_JOBS_ROUTE}/:jobId/:tabName?` as const;

// Try to use the `useHistory` hook, the `withRouter` HOC, or use connected-redux-router's push/replace.
// if you have no other option, this is exported here.
export const history: History = createBrowserHistory();

/**
 * Returns the params object extracted from the URL, or null if the URL doesn't match
 */
export const getParamsFromPath = (pathname: string, route: string) =>
  matchPath(pathname, { path: route })?.params;

history.listen(() => {
  window.scrollTo(0, 0);
});

/*
ways to get route information:

React:
  * useHistory, useLocation, useParams, useRouteMatch for functional components
  * withRouter for class components
Redux:
  * state.router.* gives us all the internal router state in the redux world
*/
