import { createSlice } from '@reduxjs/toolkit';

import type { ValueOf } from '../utils/tsUtilityTypes';

export const ORIENTATIONS = {
  PORTRAIT: 'portrait',
  LANDSCAPE: 'landscape',
  SQUARE: 'square'
};
export type PossibleOrientations = ValueOf<typeof ORIENTATIONS>;

export const SEARCHMODES = {
  SOURCE: 'source',
  LIBRARY: 'library',
  ADVANCED: 'advanced'
} as const;
export type PossibleSearchModes = ValueOf<typeof SEARCHMODES>;
export type PossibleSearchModesNoAdvanced = Exclude<
  ValueOf<typeof SEARCHMODES>,
  (typeof SEARCHMODES)['ADVANCED']
>;

export const TRAY_MODES = {
  MINIMIZED: 'MINIMIZED',
  MODAL: 'MODAL',
  TRAY: 'TRAY',
  CLOSED: 'CLOSED'
} as const;
export type PossibleTrayModes = ValueOf<typeof TRAY_MODES>;

export const PANELS = {
  ABSTRACT: 'ABSTRACT',
  AI: 'AI',
  FILTERS: 'FILTERS',
  INFO: 'INFO',
  TREE: 'TREE',
  RENDITIONS: 'RENDITIONS',
  RELATIONSHIPS: 'RELATIONSHIPS',
  VERSIONS: 'VERSIONS',
  NEW_VERSION: 'NEW_VERSIONS',
  WORKSPACE: 'WORKSPACE'
} as const;
export type PossiblePanels = ValueOf<typeof PANELS>;

export type State = {
  trayState: PossibleTrayModes;
  gridViewOrientation: PossibleOrientations;
  gridViewScale: number;

  rightPanelOpen: boolean;
  rightPanelWidth: number;
  activePanel: PossiblePanels | null;

  searchString: string | null;
  inSearchSession: boolean;
  startSearchSession: boolean;
};

const localStorageRightPanelOpen = localStorage.getItem('rightPanelOpen');
const localStorageActivePanel = localStorage.getItem('activePanel');

const initialState: State = {
  trayState: TRAY_MODES.CLOSED,
  gridViewOrientation: 'square',
  gridViewScale: 1,

  rightPanelOpen: localStorageRightPanelOpen === 'true',
  rightPanelWidth: 0,
  activePanel:
    localStorageRightPanelOpen === 'true' && localStorageActivePanel != null
      ? PANELS[localStorageActivePanel]
      : null,

  searchString: null,
  inSearchSession: false,
  startSearchSession: false
};

export const {
  reducer,
  actions: {
    setTrayState,
    setGridViewOrientation,
    setGridViewScale,
    setRightPanelOpen,
    setRightPanelWidth,
    toggleRightPanel,
    setActivePanel,
    setSearchString,
    setInSearchSession,
    setStartSearchSession
  }
} = createSlice({
  name: 'ripcord/ui/appstate',
  initialState,
  reducers: {
    setTrayState(
      state: State,
      { payload }: { payload: PossibleTrayModes }
    ): State {
      return {
        ...state,
        trayState: payload
      };
    },
    setGridViewOrientation(
      state: State,
      { payload }: { payload: PossibleOrientations }
    ): State {
      return {
        ...state,
        gridViewOrientation: payload
      };
    },
    setGridViewScale(state: State, { payload }: { payload: number }): State {
      return {
        ...state,
        gridViewScale: payload
      };
    },
    setRightPanelOpen(state: State, { payload }: { payload: boolean }): State {
      return {
        ...state,
        rightPanelOpen: payload
      };
    },
    setRightPanelWidth(state: State, { payload }: { payload: number }): State {
      return {
        ...state,
        rightPanelWidth: payload
      };
    },
    toggleRightPanel(state: State): State {
      return {
        ...state,
        rightPanelOpen: !state.rightPanelOpen
      };
    },
    setActivePanel(
      state: State,
      { payload }: { payload: PossiblePanels | null }
    ): State {
      return {
        ...state,
        activePanel: payload
      };
    },
    setSearchString(
      state: State,
      { payload }: { payload: string | null }
    ): State {
      return {
        ...state,
        searchString: payload,
        inSearchSession: false,
        startSearchSession: false
      };
    },
    setInSearchSession(state: State, { payload }: { payload: boolean }): State {
      return {
        ...state,
        inSearchSession: payload
      };
    },
    setStartSearchSession(
      state: State,
      { payload }: { payload: boolean }
    ): State {
      return {
        ...state,
        startSearchSession: payload
      };
    }
  }
});
