import 'tippy.js/dist/tippy.css';

import React, { memo } from 'react';
import Tippy from '@tippyjs/react';
import styled, { css } from 'styled-components';

import type { ComponentProps, ReactNode } from 'react';

const variants = {
  /** standard, dark themed tooltip */
  standard: css`
    .tippy-content {
      background-color: ${({ theme }) => theme.colors.gray['700']};
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.24);
      border-radius: 4px;
    }
  `,
  /** light themed "popover" styled */
  lightPop: css`
    &&,
    .tippy-content {
      background-color: ${({ theme }) => theme.colors.shades.white};
      border-radius: 8px;
      border: 1px solid ${({ theme }) => theme.colors.black.T10};
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08),
        0px 8px 12px rgba(0, 0, 0, 0.16);
      overflow: hidden;
    }
  `,
  /** Hello Mr McGuideface */
  guidey: css`
    background: ${({ theme }) => theme.colors.black.T10};
    border: 1px solid ${({ theme }) => theme.colors.black.T10};
    box-sizing: content-box;
    border-radius: 8px;
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.16),
      0px -1px 0px 0px rgba(0, 0, 0, 0.12) inset;

    .tippy-content {
      border-radius: 7px;
      background-color: ${({ theme }) => theme.colors.primary['600']};
    }

    &[data-placement^='left'],
    &[data-placement^='right'] {
      .tippy-arrow {
        color: ${({ theme }) => theme.colors.primary['600']};
        transform-style: preserve-3d;
        top: 12px !important;

        &::before {
          color: ${({ theme }) => theme.colors.primary['600']};
        }

        &::after {
          content: '';
          position: absolute;
          border-top: 8px solid transparent;
          border-bottom: 8px solid transparent;
          border-right: 8px solid ${({ theme }) => theme.colors.black.T10};
          z-index: -10;
          transform: rotate(180deg);
        }
      }

      &[data-placement^='left-start'] {
        top: -12px;

        .tippy-arrow {
          top: 12px !important;
          &::after {
            top: 0px;
            left: 17px;
            transform: translateZ(-1px) rotate(180deg);
          }
        }
      }

      &[data-placement^='left-end'] {
        top: 12px;

        .tippy-arrow {
          top: -12px !important;
          &::after {
            top: 0px;
            left: 17px;
            transform: translateZ(-1px) rotate(180deg);
          }
        }
      }

      &[data-placement^='right-start'] {
        top: -12px;

        .tippy-arrow {
          top: 12px !important;
          &::after {
            top: 0px;
            left: -9px;
            transform: translateZ(-1px) rotate(0deg);
          }
        }
      }

      &[data-placement^='right-end'] {
        top: 12px;

        .tippy-arrow {
          top: -12px !important;
          &::after {
            top: 0px;
            left: -9px;
            transform: translateZ(-1px) rotate(0deg);
          }
        }
      }
    }

    &[data-placement^='bottom'],
    &[data-placement^='top'] {
      .tippy-arrow {
        color: ${({ theme }) => theme.colors.primary['600']};
        transform-style: preserve-3d;

        &::before {
          color: ${({ theme }) => theme.colors.primary['600']};
        }

        &::after {
          content: '';
          position: absolute;
          right: 4px;
          border-top: 8px solid transparent;
          border-bottom: 8px solid transparent;
          border-right: 8px solid ${({ theme }) => theme.colors.black.T10};
          z-index: -10;
        }
      }

      &[data-placement^='top-start'] .tippy-arrow {
        &::after {
          top: 13px;
          transform: translateZ(-1px) rotate(-90deg);
        }
      }

      &[data-placement^='top-end'] .tippy-arrow {
        &::after {
          top: 13px;
          transform: translateZ(-1px) rotate(-90deg);
        }
      }

      &[data-placement^='bottom-start'] .tippy-arrow {
        &::after {
          top: -13px;
          transform: translateZ(-1px) rotate(90deg);
        }
      }

      &[data-placement^='bottom-end'] .tippy-arrow {
        &::after {
          top: -13px;
          transform: translateZ(-1px) rotate(90deg);
        }
      }
    }
  `
};

export type PossibleRippyTippyVariants = keyof typeof variants;

export type Props = {
  variant?: PossibleRippyTippyVariants;
  showArrow?: boolean;
  className?: string;
  applyClassNameToTippy?: boolean;
  /** The content of the tooltip. Possible values: string, Element, or (reference) => Element. */
  content?: ReactNode;
  children?: ReactNode;
  fixedWidth?: string;
  maxTooltipWidth?: string;
  borderRadius?: number;
  noPadding?: boolean;
  /** boolean return decides if tooltip will be displayed when triggered to show */
  onShow?: () => boolean;
} & Omit<ComponentProps<typeof Tippy>, 'theme' | 'onShow'>;

/**
 * Docs for the tippyjs/react library are here:
 * https://github.com/atomiks/tippyjs-react
 */
export default memo(function RippyTippy({
  variant = 'standard',
  showArrow = false,
  className,
  content,
  children,
  fixedWidth,
  maxTooltipWidth = fixedWidth ?? '256px',
  borderRadius,
  noPadding,
  onShow,
  ...rest
}: Props) {
  const hideIfEmpty = () => content ?? false; // defaults "onShow" to return false when there is no content to prevent empty tooltips from being displayed
  return (
    <StyledTippy
      onShow={onShow ?? (hideIfEmpty as any)}
      content={content}
      $maxTooltipWidth={maxTooltipWidth}
      maxWidth={maxTooltipWidth}
      $fixedWidth={fixedWidth}
      $variant={variant}
      $showArrow={showArrow}
      $borderRadius={borderRadius}
      $noPadding={noPadding}
      {...rest}>
      {/* note: StyledTippy does not render as the parent of the children, so className is applied to the tooltip div here to make this styled componentable from other files */}
      {/* tippy needs an html element as a child, div wrapper around children prevents crashes */}
      {children && <div className={className}>{children}</div>}
    </StyledTippy>
  );
});

const StyledTippy = styled(Tippy)<{
  $maxTooltipWidth: string;
  $fixedWidth?: string;
  $variant: PossibleRippyTippyVariants;
  $showArrow?: boolean;
  $borderRadius?: number;
  $noPadding?: boolean;
}>`
  .tippy-content {
    width: ${({ $fixedWidth }) => $fixedWidth ?? 'max-content'};
    max-width: ${({ $maxTooltipWidth }) => $maxTooltipWidth};
    overflow-wrap: break-word;
    font-family: Circular-Std, sans-serif;
    font-size: 10px;
    line-height: 16px;
  }

  ${(props) => (variants[props.$variant] ? variants[props.$variant] : null)}

  .tippy-content {
    ${({ $borderRadius }) =>
      $borderRadius ? `border-radius: ${$borderRadius}px;` : ``}
    ${({ $noPadding }) => ($noPadding ? `padding: 0;` : ``)}
  }

  ${({ $showArrow }) =>
    !$showArrow &&
    `
      .tippy-arrow { display: none; }
    `}
`;
