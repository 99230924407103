import { call, cancelled } from 'typed-redux-saga/macro';
import qs from 'qs';
import { t } from '@lingui/macro';

import { getKeycloakConfig, keycloak } from '../auth/keycloak';
import type { RipcordError } from '../errors';
import { queryStringToQueryObject } from '../query-params/utils';
import timber from '../timber/macro';
import { track } from '../utils/mixpanel';

export type PossibleMethods = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
export type PossibleApiVersions = 'v1' | 'v2';
export type PossiblePaths = `/${string}`;
export type OtherParams = { [key: string]: string | number | boolean };
// eslint-disable-next-line no-undef
type FetchParams = Omit<RequestInit, 'signal'>;
export type OMSBatchCommands = Array<
  {
    method: PossibleMethods;
    uri:
      | PossiblePaths
      | {
          $xpr: `'${PossiblePaths}` | `#ref('${PossiblePaths}`;
        };
    apiVersion?: PossibleApiVersions;
    body?: object;
  } & (
    | { method: Extract<PossibleMethods, 'GET' | 'DELETE'> }
    | { method: Exclude<PossibleMethods, 'GET' | 'DELETE'>; body: object }
  )
>;
export type OMSCommandResult<BodyShape = unknown> = {
  body: BodyShape;
  status: number;
};
export type OMSCommandResults = {
  commands: Array<{
    response: OMSCommandResult;
  }>;
};

export function getCommonHeaders() {
  const { realm, clientId } = getKeycloakConfig();
  return {
    Connection: 'keep-alive',
    'Accept-Encoding': 'br, gzip, deflate',
    Authorization: 'Bearer ' + keycloak.token,
    'RC-ACCOUNT-REALM': realm,
    'RC-TOKEN-REALM': realm,
    'X-RC-TOKEN-REALM': realm,
    'RC-APP-ID': clientId
  } as const;
}

export const headersSymbol = Symbol('headers');

export function* abortableFetch(url: string, params: FetchParams) {
  const abortController = new AbortController();
  try {
    return yield* call(fetch, url, {
      ...params,
      signal: abortController.signal
    });
  } finally {
    if (yield* cancelled()) {
      abortController.abort();
    }
  }
}

/**
 * Prepares the batch commands by injecting the prefix URL and stringifying any bodies as needed
 */
export function prepareBatchCommands(commands: OMSBatchCommands) {
  return commands.map(
    ({ method, uri: rawUri, body: rawBody, apiVersion = 'v1' }) => {
      const body = method !== 'GET' && rawBody ? rawBody : undefined;

      const rawUriString = typeof rawUri === 'string' ? rawUri : rawUri.$xpr;
      assertUrlNotIncludingVersion(rawUriString);
      const query = method === 'GET' && rawBody ? qs.stringify(rawBody) : '';

      if (typeof rawUri === 'string') {
        return {
          request: {
            method,
            uri:
              `/api/${apiVersion}` +
              rawUriString +
              (query?.length ? `?${query}` : ''),
            body
          }
        };
      } else {
        return {
          request: {
            method,
            uri: {
              ...rawUri,
              $xpr:
                `'/api/${apiVersion}'+` +
                rawUriString +
                (query?.length ? `+'?${query}'` : '')
            },
            body
          }
        };
      }
    }
  );
}

export function assertUrlNotIncludingVersion(url: string) {
  if (url.match(/^'?\/?api\/v\d/i)) {
    timber.error(
      'URL passed to API request function cannot contain API version string (like "/api/v1")',
      'This is most likely a bug!'
    );
  }
}

export const trackRequest = ({
  serviceName,
  startTime,
  methodName,
  url,
  responseHeaders,
  batchDetail,
  batchCommandCount,
  samplingPercentage = 0.035
}: {
  serviceName: string;
  startTime: number;
  methodName: PossibleMethods;
  url: string;
  responseHeaders?: Headers;
  batchDetail?: string;
  batchCommandCount?: number;
  samplingPercentage?: number;
}) => {
  if (Math.random() <= samplingPercentage) {
    const loadTime = performance.now() - startTime;
    const size = queryStringToQueryObject(url)?.size as string | undefined;
    track('Platform Request', {
      Service: serviceName,
      'Load Time': loadTime,
      'Request ID':
        (responseHeaders && responseHeaders.get('X-Request-Id')) || undefined,
      Endpoint: url,
      Method: methodName,
      'Batch Detail': batchDetail || undefined,
      'Batch Command Count': batchCommandCount || undefined,
      'Request Size': size ? Number(size) : 'N/A',
      'Trace Id': responseHeaders?.get('X-B3-TraceId') || undefined
    });
  }
};

export const trackRequestError = ({
  response,
  serviceName,
  methodName,
  url,
  batchDetail,
  batchCommandCount
}: {
  response: Response | OMSCommandResult | RipcordError | any;
  serviceName: string;
  methodName: PossibleMethods;
  url: string;
  batchDetail?: string;
  batchCommandCount?: number;
}) => {
  // try/catch wrapper is just to be careful that any mixpanel issues don't get thrown to api request error handlings
  try {
    track('Error', {
      'Feature that Failed': 'Platform Request',
      'Status Code': response?.status ?? 'N/A',
      'Failure Reason': response?.message ?? response,
      'Additional Data': {
        Service: serviceName,
        Endpoint: url,
        Method: methodName,
        'Batch Detail': batchDetail || undefined,
        'Batch Command Count': batchCommandCount || undefined
      }
    });
  } catch (err) {
    timber.error(t`Error tracking trackRequestError`, err);
  }
};
