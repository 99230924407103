import log from 'loglevel';

import type { LogLevelNames } from 'loglevel';

import { getUserUUID } from '../auth/keycloakSelectors';
import { enqueue } from './ringBuffer';

/**
 * ------------------- WARNING! -------------------
 * Don't import this file directly. You should almost always import the macro unless
 * you really know what you're doing!
 */

/**
 * by adjusting this log level, you can adjust what will be logged locally and what
 * will be sent to the server on a log event
 */
log.setLevel('debug');

/**
 * loglevel has this concept of a methodFactory. it's done this way to ensure you're actually
 * calling the console function, so that things like the stack trace and a link to the source
 * code all still work. But we also want to get a hook that the log happened, as well as
 * potentially inject additional information into both the buffer and the console logged line.
 *
 * So my solution was to attack this in 2 parts.
 *
 * 1. I curried the logging functions in the method factory below. This makes it so you'd need
 * to do something like `timber.log('args', 'here')()` in order to actually
 * log something. This lets me use the first invocation to run internal work and bind the data
 * to the console function call, then the second call actually invokes the console function
 * (therefore maintaining the stack).
 *
 * 2. A babel macro is used to rewrite any time you call `timber.log()` to convert it to
 * `timber.log()()` without the developer having to do anything! It also injects some additional
 * information like line number and file name so we have them in the remote logging environment.
 */
const originalFactory = log.methodFactory;
log.methodFactory = function (
  methodName,
  logLevel,
  loggerName: 'timber' | 'remoteOnlyTimber' | 'localOnlyTimber'
) {
  const rawMethod = originalFactory(methodName, logLevel, loggerName);

  return function (filename, lineNumber, ...args) {
    const logTimestamp = new Date();

    if (loggerName !== 'localOnlyTimber') {
      let user;
      try {
        user = getUserUUID();
      } catch (err) {
        // if we can't get the user, just log it as unknown
        // this should stop errors for logs that happen before auth is fully complete
        user = 'unknown';
      }
      /**
       * enqueue the log object to be sent to the server, injecting as much information at this
       * point as is reasonable. (we potentially don't have access to redux, or even keycloak at
       * this point, so we can't add any of that here)
       */
      enqueue({
        // https://github.com/MoffettData/lib-core-common/blob/33dc3eda47a87c6a4c13f8e61453df41d20a39f1/src/main/resources/logback-common.xml#L53-L74
        // These are "standardized" fields for logs that all other services at ripcord use
        severity: methodName.toUpperCase() as Uppercase<LogLevelNames>,
        service: `${process.env.APP_NAME}-frontend`,
        message: args?.join(' '),
        user,
        '@timestamp': logTimestamp.toISOString(),
        caller: `${filename}:${lineNumber}`,
        version: process.env.RP_CHART_VERSION
      });
    }

    if (loggerName === 'remoteOnlyTimber') {
      return () => null;
    } else {
      /**
       * bind the data to the real console function call, but don't invoke it.
       * Instead return it, and let the caller invoke it themselves to maintain the call stack
       */
      return rawMethod.bind(
        undefined,
        // add a locale-specific timestamp to the log line to make it easier to tell when it happened in your local timezone
        `[${logTimestamp.toLocaleTimeString()}]`,
        ...args
      );
    }
  };
};

// Be sure to call setLevel method in order to apply plugin
log.setLevel(log.getLevel());

export default log.getLogger('timber');

// export a second logger that will only log to the server, and not to the console
export const remoteOnlyTimber = log.getLogger('remoteOnlyTimber');

// and export a 3rd logger that will only log to the console, and not to the server
export const localOnlyTimber = log.getLogger('localOnlyTimber');

// @ts-expect-error
log.debug?.(undefined, undefined, 'Timber initialized!')?.();
