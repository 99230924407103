import { createAction } from '@reduxjs/toolkit';

import type {
  PossibleModalNames,
  PossibleModalProps
} from '../../modals/components/modal-root';

export const setNotification = createAction(
  'UI/NOTIFICATION_SET',
  (
    text: string,
    secondaryActionId?: string,
    secondaryActionText?: string,
    notificationType?: string
  ) => ({
    payload: { text, secondaryActionId, secondaryActionText, notificationType }
  })
);
export const closeNotification = createAction('UI/CLOSE_NOTIFICATION');
export const triggerSecondary = createAction('UI/TRIGGER_SECONDARY');

export const showFullScreenModal = createAction(
  'UI/UI_SHOW_FULL_SCREEN_MODAL',
  (component: any | null, props?: object) => ({
    payload: { component, props }
  })
);
export const hideFullScreenModal = createAction('UI/UI_HIDE_FULL_SCREEN_MODAL');

export const toggleMenu = createAction('UI/TOGGLE_MENU');

export const changeMenuOpenState = createAction(
  'UI/CHANGE_MENU_OPEN_STATE',
  (open) => ({ payload: open })
);

export const setHasMinimizedState = createAction(
  'UI/SET_HAS_MINIMIZED_MENU',
  (hasMinimized) => ({
    payload: hasMinimized
  })
);

export const showModal = createAction(
  'UI/UI_SHOW_MODAL_SHOW',
  (type: PossibleModalNames, props?: PossibleModalProps) => {
    const propsWithFocus = props?.focusOn
      ? props
      : { ...props, focusOn: document.activeElement };
    return { payload: { type, props: propsWithFocus } } as const;
  }
);
export const hideModal = createAction('UI/UI_HIDE_MODAL_HIDE');

export const changeViewport = createAction(
  'UI/UI_CHANGE_VIEWPORT',
  (screenSize: string) => ({
    payload: screenSize
  })
);

export const setMenuAutoCollapseState = createAction(
  'UI/SET_MENU_AUTO_COLLAPSE_STATE',
  (payload) => ({
    payload
  })
);

export const setDocumentViewerZoomScale = createAction(
  'UI/DOCUMENT_VIEWER_SET_ZOOM_SCALE',
  (payload, silent = false) => ({
    payload,
    meta: { silent }
  })
);
