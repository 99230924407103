import { i18n } from '@lingui/core';
import {
  fromNavigator,
  fromStorage,
  multipleDetect
} from '@lingui/detect-locale';
import { en, eo, fr, id, ja, ms, zh } from 'make-plural/plurals';
import { setLocaleData as registerMonacoLocale } from 'monaco-editor-nls';
import { registerLocale as registerDateLocale } from 'react-datepicker';

import type { ValueOf } from '../utils/tsUtilityTypes';

import timber from '../timber/macro';

/** Map our supported locales to their correct translation po files */
const supportedLocales = {
  en: 'en-US',
  'en-US': 'en-US',
  eo: 'eo',
  ja: 'ja',
  'ja-JP': 'ja',
  fr: 'fr',
  'fr-CA': 'fr',
  'fr-FR': 'fr',
  'zh-TW': 'zh-TW',
  ms: 'ms',
  'ms-BN': 'ms',
  'ms-MY': 'ms',
  'ms-SG': 'ms',
  id: 'id',
  'id-ID': 'id'
} as const;
export type PossibleInputLocales = keyof typeof supportedLocales;
export type SupportedLocales = ValueOf<typeof supportedLocales>;

const cronstrueLocales = {
  'en-US': 'en',
  eo: 'en',
  ja: 'ja',
  fr: 'fr',
  'zh-TW': 'zh_TW',
  ms: 'en',
  id: 'en'
} as const satisfies Record<SupportedLocales, string>;

let currentLocale: SupportedLocales;
export function getCurrentLocale() {
  return currentLocale;
}

// loads the plurals data for all locales up front, it tree shakes better that way
i18n.loadLocaleData({
  en: { plurals: en },
  'en-US': { plurals: en },
  eo: { plurals: eo },
  ja: { plurals: ja },
  'ja-JP': { plurals: ja },
  fr: { plurals: fr },
  'fr-CA': { plurals: fr },
  'fr-FR': { plurals: fr },
  'zh-TW': { plurals: zh },
  ms: { plurals: ms },
  'ms-BN': { plurals: ms },
  'ms-MY': { plurals: ms },
  'ms-SG': { plurals: ms },
  id: { plurals: id },
  'id-ID': { plurals: id }
});

const getLocales = () =>
  multipleDetect(
    /** localStorage.setItem('lang', 'eo'); */
    fromStorage('lang'),
    /** chrome://settings/languages > Language > Esperanto */
    fromNavigator(),
    /** chrome:DevTools > Sensors > Location > Locale > eo */
    Intl.DateTimeFormat().resolvedOptions().locale,
    /** fallback to English */
    () => 'en-US'
  ) as unknown as Array<PossibleInputLocales>;

const fallbackLocale = Object.keys(supportedLocales)[0] as 'en-US';
const configureLocale = async (localeOverride?: PossibleInputLocales) => {
  const detectedLocales = getLocales();
  const firstSupportedFromDetectedLocales = detectedLocales.find(
    (locale) => supportedLocales[locale]
  );
  let locale: PossibleInputLocales =
    localeOverride ?? firstSupportedFromDetectedLocales!;
  if (!supportedLocales[locale]) {
    timber.warn(
      `The locale of ${locale} is not yet supported by our application. Application will instead display in ${fallbackLocale}.`
    );
    locale = fallbackLocale;
  }

  const normalizedFinalLocale = supportedLocales[locale];

  currentLocale = normalizedFinalLocale;

  // Import and install all of the locale data for this locale
  await Promise.all([
    // Ripcord strings
    installAndSetupRipcordStrings(normalizedFinalLocale),
    // date-fns strings
    installAndSetupDateFnsStrings(normalizedFinalLocale),
    // monaco editor strings. Ignores any errors from loading unsupported locales (like eo, our test locale)
    installAndSetupMonacoStrings(normalizedFinalLocale),
    // cronstrue strings
    installCronstrueStrings(normalizedFinalLocale)
  ]);

  timber.debug(`Application displaying in ${locale}.`);
};

export default configureLocale;

async function installAndSetupRipcordStrings(
  normalizedLocale: SupportedLocales
) {
  try {
    const { messages } = await import(
      /* webpackInclude: /(en\-US|eo|ja|fr|zh\-TW|ms|id)\.po/ */
      `./locales/${normalizedLocale}.po`
    );
    i18n.load(normalizedLocale, messages);
  } catch (err) {
    timber.error(
      `Failed to load ripcord strings for locale ${normalizedLocale}`,
      err
    );
  } finally {
    i18n.activate(normalizedLocale);
  }
}

async function installAndSetupDateFnsStrings(
  normalizedLocale: SupportedLocales
) {
  try {
    const datePickerLocale = await import(
      /* webpackInclude: /(en\-US|eo|ja|fr|zh\-TW|ms|id)/ */
      `../../node_modules/date-fns/locale/${normalizedLocale}/index.js`
    );
    registerDateLocale(normalizedLocale, datePickerLocale);
  } catch (err) {
    timber.error(
      `Failed to load date-fns strings for locale ${normalizedLocale}`,
      err
    );
  }
}

async function installAndSetupMonacoStrings(
  normalizedLocale: SupportedLocales
) {
  const normalizedMonacoLocaleCode = normalizedLocale
    .replace('zh-TW', 'zh-hant')
    .replace('eo', 'qps-ploc');

  try {
    const monacoLocale = await import(
      /* webpackInclude: /(qps\-ploc|ja|fr|zh\-hant|ms|id)\.json/ */
      `../../node_modules/monaco-editor-nls/locale/${normalizedMonacoLocaleCode}.json`
    );
    registerMonacoLocale(monacoLocale);
  } catch (err) {
    timber.warn(
      `Failed to load monaco strings for locale ${normalizedMonacoLocaleCode}`,
      err
    );
  }
}

async function installCronstrueStrings(normalizedLocale: SupportedLocales) {
  try {
    await import(
      /* webpackInclude: /(en|ja|fr|zh\_TW)\.js/ */
      `cronstrue/locales/${cronstrueLocales[normalizedLocale]}.js`
    );
  } catch (err) {
    timber.error(
      `Failed to load cronstrue strings for locale ${normalizedLocale}`,
      err
    );
  }
}
