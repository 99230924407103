import type { ValueOf } from '../utils/tsUtilityTypes';

export const INPUT_MODES = {
  /** starts out as view mode, but transitions to edit mode on focus and back on blur */
  HYBRID: 'hybrid',
  /** forces it into a read-only view mode */
  VIEW: 'view',
  /** forces it into an edit-only input mode */
  EDIT: 'edit',
  /** configures the component for the filters panel, adding facets support and changing default empty display values */
  FILTER: 'filter',
  /** for use in the TableDrawer- allows edits and has components disable certain styling. Also prevents the drawer from infinitely looping more drawers */
  TABLE: 'table'
} as const;
export type PossibleInputModes = ValueOf<typeof INPUT_MODES>;

export const JSONEditorMonacoOptions = {
  lineNumbers: 'off',
  scrollbar: {
    vertical: 'auto',
    horizontal: 'auto'
  }
} as const;
