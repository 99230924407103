import { useCallback, useMemo, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from '../store/hooks';

import type { RipcordLocation } from './utils';

import openInNewTabFunction from '../utils/openInNewTab';
import {
  addDefaultsAndStoredValues,
  locationToRipcordLocation,
  ripcordLocationToLocation
} from './utils';

function openInNewTabShim(location: Location, state?: any) {
  openInNewTabFunction({
    ...location,
    state
  });
}

export default function useRipcordLocation<LocationStateShape = any>() {
  const { push, replace, location, ...restOfHistory } = useHistory();
  const params = useParams() as Record<string, string> & {
    /**
     * 0 shouldn't be used from here to grab a "splat" from the URL. React-router-dom has some
     * bugs which make it not handle URI decoding correctly and instead you'll need to write a
     * custom hook to pull the value directly from the URL if you need it.
     *
     * An example is implemented in the function useTaxonomySplatStringFromURL
     */
    0: void;
  };

  /* On first load, pull from redux as the tenant may not have been
  set in session storage yet */
  const { dataRealm } = useSelector<any, { dataRealm: string }>(
    (state) => state.api
  );

  const ripcordLocationObjRef = useRef<RipcordLocation<LocationStateShape>>(
    locationToRipcordLocation(location as any)
  );

  const ripcordLocation: RipcordLocation<LocationStateShape> = useMemo(() => {
    ripcordLocationObjRef.current = locationToRipcordLocation(location as any);
    return addDefaultsAndStoredValues(ripcordLocationObjRef.current, dataRealm);
  }, [dataRealm, location]);

  const send = useCallback(
    (
      pushOrReplace: (location: Location, state?: any) => void,
      newLocationObject:
        | RipcordLocation
        | ((location: RipcordLocation) => RipcordLocation)
    ) => {
      if (typeof newLocationObject === 'function') {
        ripcordLocationObjRef.current = newLocationObject(
          ripcordLocationObjRef.current
        );
      } else {
        ripcordLocationObjRef.current = newLocationObject;
      }

      const { state, ...restOfLocation } = ripcordLocationToLocation(
        ripcordLocationObjRef.current
      );
      pushOrReplace(restOfLocation, state);
    },
    []
  );

  const replaceQueryParams = useCallback(
    (
      newLocationObject:
        | Partial<RipcordLocation>
        | ((location: RipcordLocation) => RipcordLocation)
    ) => send(replace, newLocationObject as RipcordLocation),
    [replace, send]
  );

  const pushQueryParams = useCallback(
    (
      newLocationObject:
        | Partial<RipcordLocation>
        | ((location: RipcordLocation) => RipcordLocation)
    ) => send(push, newLocationObject as RipcordLocation),
    [push, send]
  );

  const openInNewTab = useCallback(
    (
      newLocationObject:
        | RipcordLocation
        | ((location: RipcordLocation) => RipcordLocation)
    ) => send(openInNewTabShim, newLocationObject),
    [send]
  );

  return {
    ...(restOfHistory as object),
    /**
     * Any params pulled from the path of the URL based on the matched route
     */
    params: params ?? {},
    /**
     * A RipcordLocation object with the query parameters fully parsed out and defaults applied
     */
    location: ripcordLocation,
    /**
     * Navigates the user to the new location object, adding to history
     */
    push: pushQueryParams,
    /**
     * Navigates the user to the new location object, replacing the current history entry
     */
    replace: replaceQueryParams,
    /**
     * Opens the new location object in a new tab, supports all the same options as push/replace, including location-state
     */
    openInNewTab
  } as const;
}
