import timber from '../timber/macro';

const _cio: any[] = [];

export default function configureCustomerIo(siteId: string) {
  // The script below was taken from the Customer.io integration page:
  // https://fly.customer.io/workspaces/143228/journeys/integrations/customerio
  // and lightly updated to work with typescript and passing in the site id as a variable

  try {
    let c;

    const a = function (f) {
      return function () {
        // eslint-disable-next-line prefer-rest-params
        _cio.push([f].concat(Array.prototype.slice.call(arguments, 0)));
      };
    };

    const b = ['load', 'identify', 'sidentify', 'track', 'page'];

    for (c = 0; c < b.length; c++) {
      _cio[b[c]] = a(b[c]);
    }

    const t = document.createElement('script');
    const s = document.getElementsByTagName('script')[0];

    t.async = true;
    t.id = 'cio-tracker';
    t.setAttribute('data-site-id', siteId);
    t.src = 'https://assets.customer.io/assets/track.js';
    s.parentNode?.insertBefore(t, s);

    global._cio = _cio;
  } catch (e) {
    timber.error('Error configuring Customer.io', e);
  }
}
