// eslint-disable-next-line deprecate/import
import builder from '@rsql/builder';

import type { ComparisonNode, ExpressionNode, LogicNode } from '@rsql/ast';

/*
  This file is used to combine the default operators available from @rsql/builder
   and our custom operators (such as ILIKE)
 */
export const ILIKE = '=ilike=';
export const UNILIKE = '=unilike=';
export const LIKE = '=like=';
export const UNLIKE = '=unlike=';
export const SEQ = '=seq=';
export const SNE = '=sne=';
export const SIN = '=sin=';
export const SOUT = '=sout=';

export default {
  ...builder,
  /**
   * A "safer" version of or that will ignore any nodes which are undefined.
   * If there is only one node left, it'll just be passed through unchanged.
   * If no nodes are left, this will return undefined itself.
   **/
  maybeOr(...nodes: Array<ExpressionNode | undefined | null>) {
    const filteredNodes = nodes.filter(
      (node) => node != null
    ) as ExpressionNode[];

    if (filteredNodes.length === 0) {
      return undefined;
    } else {
      return builder.or(...filteredNodes);
    }
  },
  /**
   * A "safer" version of and that will ignore any nodes which are undefined.
   * If there is only one node left, it'll just be passed through unchanged.
   * If no nodes are left, this will return undefined itself.
   **/
  maybeAnd(...nodes: Array<ExpressionNode | undefined | null>) {
    const filteredNodes = nodes.filter(
      (node) => node != null
    ) as ExpressionNode[];

    if (filteredNodes.length === 0) {
      return undefined;
    } else {
      return builder.and(...filteredNodes);
    }
  },
  /** a "frontend only" operator that adds wildcards around the value before building it */
  icontains(selector: string, value: string) {
    const formattedValue = `*${value}*`;
    return builder.comparison(selector, ILIKE, formattedValue);
  },
  ilike(selector: string, value: string) {
    return builder.comparison(selector, ILIKE, value);
  },
  unilike(selector: string, value: string) {
    return builder.comparison(selector, UNILIKE, value);
  },
  like(selector: string, value: string) {
    return builder.comparison(selector, LIKE, value);
  },
  unlike(selector: string, value: string) {
    return builder.comparison(selector, UNLIKE, value);
  },
  seq(selector: 'type', value: string) {
    return builder.comparison(selector, SEQ, value);
  },
  sne(selector: 'type', value: string) {
    return builder.comparison(selector, SNE, value);
  },
  sin(selector: 'type', values: Array<string | number>) {
    return builder.comparison(selector, SIN, values);
  },
  sout(selector: 'type', values: Array<string | number>) {
    return builder.comparison(selector, SOUT, values);
  }
};
