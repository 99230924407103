import Icon from '@material-ui/core/IconButton';
import styled from 'styled-components';

const getButtonStyles = (props) => {
  return props.light
    ? props.theme.muiTheme.iconButtonLight
    : props.theme.muiTheme.iconButton;
};

const getIconColor = (props) => {
  const buttonColors = getButtonStyles(props);
  if (props.$isActive) {
    return buttonColors.colorHover;
  }
  if (props.disabled) {
    return buttonColors.colorDisabled;
  }

  return buttonColors.color;
};

export const IconStyled = styled(Icon)`
  && {
    padding: 11px 12px;
    background: ${(props) => getButtonStyles(props).backgroundColor};
    color: ${getIconColor};
    cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
    pointer-events: auto !important; /* enables showing title when button disabled */

    &:hover {
      background: ${(props) => getButtonStyles(props).backgroundColorHover};
      color: ${(props) =>
        props.disabled
          ? getButtonStyles(props).colorDisabled
          : getButtonStyles(props).colorHover};
    }

    .icon-ripple {
      color: ${(props) => getButtonStyles(props).rippleColor};
    }
  }
`;
