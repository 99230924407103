import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { MODAL_CONFIRM } from '../../constants';
import { showModal } from '../../../ui/actions';
import type { ConfirmModalProps } from './index';

export function useConfirmModal() {
  const dispatch = useDispatch();

  return useCallback(
    (props: ConfirmModalProps) => {
      dispatch(showModal(MODAL_CONFIRM, props));
    },
    [dispatch]
  );
}
