import styled from 'styled-components';
import { ellipsis } from '../../../../theme/mixins';

export const LIST_VIEWS = {
  TABLE: 'table',
  GRID: 'grid',
  TREE: 'tree'
} as const;

export const DISPLAY_MODE_CACHE_KEY = 'ripcord/listing/displayMode';

export const LabelText = styled.span`
  display: block;
  ${ellipsis};
`;
