/**
 * This is the ring buffer implementation for the logger.
 * This is meant to be used as a standalone module so that all uses in the app will share the same
 * buffer, without having to worry about passing it around. This becomes important here because
 * the logger must work before app initialization is complete, but a saga must be able to read it,
 * so we were limited in where we could put the data and still have it accessible in all the
 * required places.
 */
import type { LogLevelNames } from 'loglevel';

/** the max number of items that can be in the buffer before the oldest start dropping off */
export const RING_BUFFER_MAX_LINES = 500;

export type LogObjectShape = {
  severity: Uppercase<LogLevelNames>;
  service: `${typeof process.env.APP_NAME}-frontend`;
  message: string;
  user: string;
  '@timestamp': string;
  /** should be the filepath:linenumber */
  caller: string;
  version?: string;
};

/**
 * the actual ring buffer, a standard array of objects. We enqueue items on the end, and shift them
 * off the front when it gets too large.
 */
let ringBuffer: LogObjectShape[] = [];

/**
 * Enqueue one or more log message objects to the buffer
 */
export function enqueue(logLine: LogObjectShape | LogObjectShape[]) {
  const lines = Array.isArray(logLine) ? [...logLine] : [logLine];

  // first shift enough off lines to ensure it's under the max lines
  // otherwise we go into an infinite loop in the next step
  while (lines.length >= RING_BUFFER_MAX_LINES) {
    lines.shift();
  }

  // then shift off enough lines from the ringBuffer to make room for the new lines
  while (ringBuffer.length + lines.length >= RING_BUFFER_MAX_LINES) {
    ringBuffer.shift();
  }

  ringBuffer.push(...lines);
}

/**
 * return the entire buffer, and completely clear it out as well
 */
export function flush() {
  const buffer = [...ringBuffer];
  ringBuffer = [];
  return buffer;
}
