import { createSelector } from 'reselect';
import { keyBy } from 'lodash';
import type { AnyAppRootState } from '../store/tsTypes';

export const getGroupsById = createSelector(
  (state: AnyAppRootState) => state.mts.groups.groups,
  (groups) => keyBy(groups, 'id')
);

export const getUsersById = createSelector(
  (state: AnyAppRootState) => state.mts.users.users,
  (users) => keyBy(users, 'id')
);
