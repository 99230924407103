import React, { forwardRef } from 'react';
// this is one of the only places allowed to use ReactSVG!
// eslint-disable-next-line deprecate/import
import ReactSVG from 'react-svg';
import styled, { useTheme } from 'styled-components';

import type { ComponentPropsWithoutRef, Ref } from 'react';
import type {
  PossibleColors as ColorType,
  PossibleIconNames as IconType
} from '../../../../theme/tsTypes';

const StyledReactSVG = styled(ReactSVG)<{
  $color?: ColorType;
  $hoverColor?: ColorType;
  $activeColor?: ColorType;
  $fitWidth?: boolean;
  $size?: number | null;
  $fitHeight?: boolean;
  $rotate?: number;
  $flipHorizontal?: boolean;
  $flipVertical?: boolean;
}>`
  div {
    display: flex;

    svg {
      color: ${({ $color }) => $color};
      :hover {
        color: ${({ $hoverColor, $color }) => $hoverColor ?? $color};
      }
      :active {
        color: ${({ $activeColor, $hoverColor, $color }) =>
          $activeColor ?? $hoverColor ?? $color};
      }
      ${({ $fitWidth, $size }) =>
        $fitWidth || $size
          ? `width: ${$fitWidth ? '100%' : `${$size}px`};`
          : ''}
      ${({ $fitHeight, $size }) =>
        $fitHeight || $size
          ? `height: ${$fitHeight ? '100%' : `${$size}px`};}`
          : ''}
      ${({ $rotate, $flipHorizontal, $flipVertical }) =>
        ($rotate || $flipHorizontal || $flipVertical) &&
        `
      transform: ${$rotate ? `rotate(${$rotate}deg)` : ``} ${
          $flipHorizontal ? `scaleX(-1)` : ``
        } ${$flipVertical ? `scaleY(-1)` : ``};
      `}
    }
  }
`;

export type Props = {
  variant: IconType;
  className?: string;
  color?: ColorType;
  hoverColor?: ColorType;
  activeColor?: ColorType;
  rotate?: number;
  flipHorizontal?: boolean;
  flipVertical?: boolean;
  size?: number | null;
  fitWidth?: boolean;
  fitHeight?: boolean;
} & Omit<ComponentPropsWithoutRef<typeof ReactSVG>, 'src'>;

const Icon = forwardRef(
  (
    {
      variant,
      className,
      color,
      hoverColor,
      activeColor,
      rotate, // number or degrees to rotate
      flipHorizontal, // boolean
      flipVertical, // boolean
      size = 24,
      fitWidth,
      fitHeight,
      ...rest
    }: Props,
    ref: Ref<any>
  ) => {
    const theme = useTheme();
    const iconName = theme?.assets?.icons[variant];

    if (!iconName) {
      return null;
    }

    return (
      <StyledReactSVG
        ref={ref}
        data-test-id={rest['data-test-id'] ?? `icon-${variant}`}
        className={className}
        src={iconName}
        $size={size}
        $fitWidth={fitWidth}
        $fitHeight={fitHeight}
        $color={color}
        $hoverColor={hoverColor}
        $activeColor={activeColor}
        $rotate={rotate}
        $flipHorizontal={flipHorizontal}
        $flipVertical={flipVertical}
        {...rest}
      />
    );
  }
);

export default Icon;
