import { t } from '@lingui/macro';
import { keyBy } from 'lodash';

import type { ValueOf } from '../../utils/tsUtilityTypes';
import type { Principal, PrincipalsListResponse } from './tsTypes';

export const PRINCIPAL_TYPE = {
  USER: 'user',
  ALIAS: 'alias',
  GROUP: 'group'
} as const;

export type PossiblePrincipalTypes = ValueOf<typeof PRINCIPAL_TYPE>;

export const KNOWN_ALIASES = {
  OWNERS: {
    id: '00000000-0000-0000-0000-000000000001',
    name: t`Owners`,
    principalType: PRINCIPAL_TYPE.ALIAS
  },
  EVERYONE: {
    id: '00000000-0000-0000-0000-000000000002',
    name: t`Everyone Else`,
    principalType: PRINCIPAL_TYPE.ALIAS
  }
} as const;

/** Response selectors */
export const getPrincipalsListById = (mtsResponse: PrincipalsListResponse) => {
  const principals = mtsResponse?._embedded?.principals ?? [];
  return keyBy(principals, 'id');
};

export const getDisplayStringForPrincipal = (
  resolvedData: Principal | undefined | null
) => {
  const { firstName, lastName, username, name } = resolvedData ?? {};
  return (firstName?.length ?? 0) > 0 || (lastName?.length ?? 0) > 0
    ? t({
        comment:
          'the first parameter is a first name, and the second is a last name',
        message: `${firstName} ${lastName}`
      }) // using a custom id with the lingui macros appears to be buggy in production
    : !!username
    ? username
    : name;
};
