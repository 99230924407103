import { useCallback, useEffect } from 'react';
import { useDispatch, useTypedSelector } from '../../store/hooks';

import type { State } from './reducer';
import type { Group } from './tsTypes';

import { getGroupsById } from '../selectors';
import {
  fetchAutocompleteGroups,
  fetchGroup,
  fetchGroupMembers,
  fetchGroupsList,
  fetchGroupSucceeded,
  saveGroup
} from './actions';

export function useGroups(queryParams: State['queryParams']) {
  const dispatch = useDispatch();
  const { dataRealm } = useTypedSelector((state) => state.api);
  const { loading, loadingError, totalNumGroups, groups, saving, savingError } =
    useTypedSelector((state) => state.mts.groups);
  const groupsById = useTypedSelector((state) => getGroupsById(state));

  useEffect(() => {
    if (dataRealm) {
      dispatch(fetchGroupsList(queryParams));
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, dataRealm, JSON.stringify(queryParams)]);

  return {
    loading,
    loadingError,
    groups,
    saving,
    savingError,
    groupsById,
    totalNumGroups,
    queryParams
  } as const;
}

export function useSaveGroup() {
  const dispatch = useDispatch();
  const { saving, savingError, savedGroupId } = useTypedSelector(
    (state) => state.mts.groups
  );

  return {
    saveGroup: useCallback(
      (group: Partial<Group>) => {
        return dispatch(saveGroup(group));
      },
      [dispatch]
    ),
    saving,
    savingError,
    savedGroupId
  } as const;
}

export function useGroup(id: Group['id'] | null | undefined) {
  const dispatch = useDispatch();
  const { groupLoading, groupLoadingError, group } = useTypedSelector(
    (state) => state.mts.groups
  );

  useEffect(() => {
    if (!!id) {
      dispatch(fetchGroup(id));
    }

    return () => {
      // clear out the group object when leaving the page so we don't leave old data around
      dispatch(fetchGroupSucceeded(null));
    };
  }, [dispatch, id]);

  return {
    loading: groupLoading,
    loadingError: groupLoadingError,
    group
  } as const;
}

export function useAutocompleteGroups() {
  const dispatch = useDispatch();
  const { autocompleteLoading, autocompleteLoadingError, autocompleteGroups } =
    useTypedSelector((state) => state.mts.groups);

  return {
    loading: autocompleteLoading,
    loadingError: autocompleteLoadingError,
    groups: autocompleteGroups,
    fetchAutocompleteGroups: useCallback(
      (searchValue?: string) => {
        dispatch(fetchAutocompleteGroups(searchValue));
      },
      [dispatch]
    )
  } as const;
}

export function useGroupMembers(queryParams: State['groupMemberQueryParams']) {
  const dispatch = useDispatch();
  const {
    groupMembersLoading,
    groupMembersLoadingError,
    groupMembers,
    moreMembersAvailable
  } = useTypedSelector((state) => state.mts.groups);

  useEffect(
    () => {
      if (!!queryParams.groupId) {
        dispatch(fetchGroupMembers(queryParams));
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, JSON.stringify(queryParams)]
  );

  return {
    loading: groupMembersLoading,
    loadingError: groupMembersLoadingError,
    groupMembers,
    moreMembersAvailable
  } as const;
}
