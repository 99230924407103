import type { PossibleKnownRoles } from '../constants';

import { REALM_TYPE, WELL_KNOWN_ROLES } from '../constants';
import { keycloak } from './keycloak';

export const ROLES = {
  ...WELL_KNOWN_ROLES,
  // list of roles that can be assigned to an admin-console superuser level user
  AC_SUPERUSER_ROLES: [
    WELL_KNOWN_ROLES.GLOBAL_ADMIN,
    WELL_KNOWN_ROLES.TENANT_PROVISIONER
  ],
  // list of roles that can be assigned to a canopy tenant level user
  CANOPY_TENANT_ROLES: [
    WELL_KNOWN_ROLES.TENANT_ADMIN,
    WELL_KNOWN_ROLES.CUSTOMER_ADMIN,
    WELL_KNOWN_ROLES.ACCOUNT_ADMIN,
    WELL_KNOWN_ROLES.AUDIT_MANAGER,
    WELL_KNOWN_ROLES.AUDIT_USER
  ]
} as const;

const getRealmAccessRoles = () => {
  return keycloak.tokenParsed?.realm_access?.roles ?? [];
};

const tokenHasRole = (roleToCheck: PossibleKnownRoles) => {
  const rolesFromToken = getRealmAccessRoles();
  return rolesFromToken.includes(roleToCheck);
};

/** Used to check if the token has at least one of the given roles  */
export const tokenHasOneOfRoles = (
  rolesToCheck: PossibleKnownRoles[] | undefined
) => rolesToCheck?.some((roleToCheck) => tokenHasRole(roleToCheck)) ?? false;

/** Used to check if the token has ALL of the given roles */
export const tokenHasAllOfRoles = (rolesToCheck: PossibleKnownRoles[]) =>
  rolesToCheck.every((roleToCheck) => tokenHasRole(roleToCheck));

export const tokenRealmName = () => {
  const { tokenParsed } = keycloak;
  return tokenParsed?.realm_name;
};

export const tokenRealmType = () => {
  const { tokenParsed } = keycloak;
  return tokenParsed?.realm_type;
};

/**
 * This is only used in the very few scenarios where we want to filter out
 * partner realm names. Specifically when interacting with mts/tenants endpoint
 * which does not treat partners as tenants. For most use cases, use tokenRealmName
 */
export const tokenTenantRealm = () => {
  const { tokenParsed } = keycloak;
  return tokenParsed?.realm_type === 'tenant' ? tokenParsed?.realm_name : null;
};

export function getUserUUID() {
  return keycloak?.tokenParsed?.sub ?? '';
}

export function getHotjarUserInfo() {
  return {
    id: keycloak?.tokenParsed?.sub ?? null,
    email: keycloak?.tokenParsed?.email ?? '',
    name: keycloak?.tokenParsed?.name ?? '',
    realm_name: keycloak?.tokenParsed?.realm_name ?? '',
    realm_type: keycloak?.tokenParsed?.realm_type ?? ''
  };
}

export const isPartnerTenant = () => {
  return keycloak.tokenParsed?.realm_type === REALM_TYPE.PARTNER;
};

const INTERNAL_DOMAINS = [
  'ripcord.com',
  'docufai.com',
  'fujifilm-ripcord.com',
  'dummy-n.local',
  'contoso.local',
  'bk.mufg.jp'
] as const;
export function getMixpanelInfo({ tenantId }: { tenantId: string }) {
  // Determine if this email is internal or external by checking the domain
  // to which the email is associated.
  const email = keycloak?.tokenParsed?.email;
  const isInternalUser = INTERNAL_DOMAINS.reduce(
    (acc, domain) => acc || email?.indexOf(domain) !== -1,
    false
  );

  // Note: These field names (with spaces, etc) were specifically requested.
  // Please ignore the urge to rename them as camelCase, etc etc.
  const superPropertyPayload = {
    tenant_id: tenantId,
    'User Roles': keycloak?.tokenParsed?.realm_access?.roles,
    'User Groups': keycloak?.tokenParsed?.groups,
    Application: process.env.APP_NAME,
    'Is Internal User': isInternalUser,
    'Realm Name': keycloak?.tokenParsed?.realm_name,
    'Is Docufai User': tokenHasOneOfRoles([ROLES.DOCUFAI])
  };

  const mixpanelPayload = {
    'Is Internal User': isInternalUser,
    'Is External User': !isInternalUser,
    'Realm Name': keycloak?.tokenParsed?.realm_name,
    tenant_id: tenantId
  };

  return {
    superPropertyPayload,
    mixpanelPayload
  };
}
