import { css } from 'styled-components';

export const ellipsis = `
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const limitLines = (size = 14, linesNum = 2) => `
  display: -webkit-box;
  align-items: center;
  -webkit-line-clamp: ${linesNum};
  -webkit-box-orient: vertical;
  -webkit-box-align: unset;
  line-height: ${size}px;
  overflow: hidden;
`;

export const styledTextFieldAsterisk = css`
  &&.MuiFormControl-root {
    & .MuiFormLabel-asterisk.MuiInputLabel-asterisk {
      color: ${({ theme }) => theme.colors.red['500']};
    }
  }
`;

export const styledFormLabelAsterisk = css`
  & span.MuiFormLabel-asterisk {
    color: ${({ theme }) => theme.colors.red['500']};
  }
`;
