import React, { useEffect, useLayoutEffect, useState } from 'react';
import Keycloak from 'keycloak-js';
import mixpanel from 'mixpanel-browser';

import type { PossibleKnownRoles, PossibleRealmTypes } from '../constants';

import { TENANT_NOT_FOUND_ROUTE as AC_ROUTE } from '../app/admin-console/routes';
import {
  TENANT_NOT_FOUND_ROUTE as CANOPY_ROUTE,
  isOnRoute
} from '../app/canopy/routes';
import { APP_IDS } from '../constants';
import { RipcordError } from '../errors';
import useRipcordLocation from '../query-params/useRipcordLocation';
import ProgressIndicator from '../ripcord-components/loading/progress-indicator';
import timber from '../timber/macro';
import { KEYCLOAK_TOKEN_REFRESH_MS } from './constants';

export function getKeycloakConfig(location = window.location): {
  readonly url: string;
  readonly realm: string;
  readonly clientId: string;
} {
  switch (location.hostname) {
    case 'local.ripcordops.com': {
      const clientId = process.env.APP_NAME;
      return {
        url: `${process.env.API_URL!.replace(/\/+$/, '')}/auth`,
        realm: /\/\/(.*?)\./.exec(process.env.API_URL!)![1],
        clientId
      };
    }
    default:
      return {
        url: `${location.origin}/auth`,
        realm: /(.*?)\./.exec(location.hostname)?.[1] ?? 'REALM_NOT_FOUND', // the REALM_NOT_FOUND is so tests will continue to work
        clientId: process.env.APP_NAME
      };
  }
}

type RipcordKeycloakInstance = Omit<Keycloak, 'tokenParsed'> & {
  // tokenParsed is a mess from the keycloak library, so i'm overwriting the types here
  tokenParsed:
    | undefined
    | {
        iss?: string;
        sub?: string;
        aud?: string;
        exp?: number;
        iat?: number;
        auth_time?: number;
        nonce?: string;
        acr?: string;
        amr?: string;
        azp?: string;
        session_state?: string;
        /** use this one for checking roles */
        realm_access?: {
          roles: PossibleKnownRoles[];
        };
        /** this exists but shouldn't be used to check roles */
        resource_access?: {
          roles: string[];
        };
        groups?: string[];
        groups_ids?: string[];
        partner_tenants?: string;
        realm_type?: PossibleRealmTypes;
        realm_name?: string;
        tier_name?: string;
        email?: string;
        name?: string;
      };
};

export const keycloak = new Keycloak(
  getKeycloakConfig()
) as RipcordKeycloakInstance;

export function logout() {
  try {
    mixpanel.reset();
  } catch (error) {
    timber.error(error);
  }

  sessionStorage.removeItem('dataRealm');
  keycloak.logout();
}

const keycloakInitOptions = {
  enableLogging: true,
  useNonce: true,
  onLoad: 'check-sso',
  silentCheckSsoRedirectUri: `${window.location.origin}/${
    process.env.APP_NAME === APP_IDS.DOCUFAI_PLUS
      ? 'plus'
      : (process.env.APP_NAME as string)
  }/silent-check-sso.html`,
  silentCheckSsoFallback: true,
  pkceMethod: 'S256',
  checkLoginIframe: true,
  checkLoginIframeInterval: KEYCLOAK_TOKEN_REFRESH_MS / 1000,
  messageReceiveTimeout: 5000
} as const;

export function AuthLoader({ children }) {
  const {
    location: { pathname },
    replace
  } = useRipcordLocation();
  const [isAuthed, setIsAuthed] = useState(false);

  const isAuthLoading =
    !isAuthed && !isOnRoute(pathname, [CANOPY_ROUTE, AC_ROUTE]);

  useLayoutEffect(() => {
    keycloak.onReady = (authenticated) => {
      timber.debug(`Keycloak Initialized`);
      if (!authenticated) {
        timber.debug(
          'Keycloak Authentication failed, forwarding to login screen...'
        );
        keycloak.login();
      } else {
        timber.debug('Keycloak Authentication successful!');
        setIsAuthed(true);
      }
    };
    // the init must be called after the onReady function is assigned or it will not call onReady
    // in many of the error conditions (like lack of 3rd party cookie support), leading to the
    // user being stuck on the loading screen
    keycloak.init(keycloakInitOptions);
  }, []);

  useEffect(() => {
    let checkForTenant404Interval;

    if (isAuthLoading) {
      checkForTenant404Interval = setInterval(() => {
        try {
          /* If the tenant doesn't exist in development while proxying, a cors error will be thrown when trying to read this iframe
           * If the tenant doesn't exist in production, this will return a fancy keycloak 404 page,
           * which we can detect by reading the title of the page.
           *
           * We can't use the `load` event as that doesn't fire when it's a cors issue
           */
          if (
            (
              document.querySelector(
                'body > iframe[title="keycloak-session-iframe"]'
              ) as any
            )?.contentDocument?.title?.includes('Keycloak')
          ) {
            throw new RipcordError({ message: '404 session iframe check' });
          } else if (
            (
              document.querySelector(
                'body > iframe[title="keycloak-3p-check-iframe"]'
              ) as any
            )?.contentDocument?.title?.includes('Keycloak')
          ) {
            throw new RipcordError({
              message: '404 3rd party cookie check iframe'
            });
          }
        } catch (e) {
          timber.error(
            'keycloak iframes not found, assuming the tenant does not exist...'
          );
          // we can't access the iframe, so it's probably a 404 error and we should redirect to the 404 tenant page
          clearInterval(checkForTenant404Interval);
          replace({
            pathname:
              process.env.APP_NAME === APP_IDS.CANOPY ||
              process.env.APP_NAME === APP_IDS.DOCUFAI_PLUS
                ? CANOPY_ROUTE
                : AC_ROUTE
          });
        }
      }, 500);
    } else {
      clearInterval(checkForTenant404Interval);
    }

    return () => clearInterval(checkForTenant404Interval);
  }, [isAuthLoading, replace]);

  useEffect(() => {
    let interval;
    if (isAuthed) {
      timber.debug('Setting up token refresh interval...');
      interval = setInterval(async () => {
        timber.debug('Checking if token refresh is needed...');

        try {
          const refreshed = await keycloak.updateToken(0);

          if (!refreshed) {
            const tokenValidSeconds = Math.round(
              keycloak.tokenParsed!.exp! +
                keycloak.timeSkew! -
                new Date().getTime() / 1000
            );
            timber.debug(
              `Token not refreshed, valid for ${tokenValidSeconds} more seconds`
            );
          } else {
            timber.debug('Token refreshed!');
          }
        } catch (err) {
          timber.error('Error refreshing token!', err);
          keycloak.login();
        }
      }, KEYCLOAK_TOKEN_REFRESH_MS);
    }

    return () => {
      timber.debug('Tearing down token refresh interval...');
      clearInterval(interval);
    };
  }, [isAuthed]);

  if (isAuthLoading) {
    return <ProgressIndicator data-test-id="progress-indicator" />;
  } else {
    return children;
  }
}
