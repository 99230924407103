import React from 'react';

import { isoToLocale } from '../../../utils/datetime-utils';
import { DATETIME_FORMATS } from '../../../config';

export const ReadOnlyDateRange = ({
  startISO,
  endISO,
  textFormat = DATETIME_FORMATS.DATETIME.SHORT,
  tooltipFormat = textFormat
}) => {
  const startTextDateTime = isoToLocale(startISO, textFormat);
  const startTooltipDateTime = isoToLocale(startISO, tooltipFormat);

  const endTextDateTime = isoToLocale(endISO, textFormat);
  const endTooltipDateTime = isoToLocale(endISO, tooltipFormat);

  return (
    <>
      <span
        data-test-id="readOnlyDate"
        aria-label={startTooltipDateTime}
        role="text"
        title={startTooltipDateTime}>
        {startTextDateTime}
      </span>
      {!!endISO && (
        <>
          <span> - </span>
          <span
            data-test-id="readOnlyDate"
            aria-label={endTooltipDateTime}
            role="text"
            title={endTooltipDateTime}>
            {endTextDateTime}
          </span>
        </>
      )}
    </>
  );
};

export default ReadOnlyDateRange;
