export const isDevInstance = (url: string | URL) =>
  new URL(url).hostname.endsWith('ripcord-labs.com');

export function headersToObject(
  headers: Headers | undefined
): Record<string, string> {
  const headerObj = {};
  headers?.forEach((key, val) => {
    headerObj[key] = val;
  });
  return headerObj;
}
