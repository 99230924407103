import {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState
} from 'react';
import { useLingui } from '@lingui/react';
import { useHistory, useLocation } from 'react-router-dom';

import type { RefObject } from 'react';
import type { SupportedLocales } from '../translations/configure-locale';
import type { Keymap } from '../translations/key-bindings';

import { getBindingWithFallback } from '../translations/key-bindings';

export function useLocationState(key) {
  const history = useHistory();
  const location = useLocation();

  const setVal = useCallback(
    (nextVal) =>
      history.replace(location.pathname, {
        ...location.state,
        [key]: nextVal
      }),
    [history, key, location.pathname, location.state]
  );

  return [location.state?.[key], setVal] as const;
}

export function useToggle(defaultValue?: any) {
  const [val, setVal] = useState(defaultValue);
  const toggleVal = useCallback(() => setVal((prevState) => !prevState), []);

  return [val, setVal, toggleVal] as const;
}

export function useForceRender() {
  const [, setRenderState] = useState(false);
  return useCallback(() => setRenderState((prev) => !prev), []);
}

/** Stolen from https://usehooks.com/usePrevious/ */
export function usePrevious<Value>(value: Value): Value | undefined {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef<Value>();

  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current;
}

/*
useAdvancedLoading: custom hook to evaluate functionality based on a more granular status than just a boolean such as 'isLoading'
 * @param {boolean} isInProgress: status of an action (ie isLoading, isCreating, isDeleting, etc)
 * @param {object} error: error that corresponds to the isInProgress status
 * @param {object} data: relevant data
 *
 * @return {boolean} neverInProgress: true when this has never been in progress
 * @return {boolean} inProgressFirstPaint: true when in progress for the first time
 * @return {boolean} submittedSuccessfully: true when you are finished an action successfully (ie saveSuccessful, deleteSuccessful, etc)
 */
export function useAdvancedLoading(
  isInProgress: boolean,
  error: Error | null = null
) {
  const isPrevInProgress = usePrevious(isInProgress);
  const neverInProgressRef = useRef(true);
  const inProgressFirstPaintRef = useRef(false);

  useEffect(() => {
    if (isInProgress) {
      neverInProgressRef.current = false;
    }
  }, [isInProgress]);

  useEffect(() => {
    if (!isPrevInProgress && isInProgress) {
      inProgressFirstPaintRef.current = true;
    }
  }, [isPrevInProgress, isInProgress]);
  const submittedSuccessfully = useMemo(
    () => !!isPrevInProgress && !isInProgress && error === null,
    [isPrevInProgress, isInProgress, error]
  );
  return {
    neverInProgress: neverInProgressRef?.current,
    inProgressFirstPaint: inProgressFirstPaintRef?.current,
    submittedSuccessfully
  } as const;
}

export function useKeybinding(
  enabled,
  keymap: Keymap,
  onPress: (e: any) => void,
  capture = false,
  ref: RefObject<HTMLInputElement> | null = null
) {
  const {
    i18n: { locale }
  } = useLingui() as { i18n: { locale: SupportedLocales } };
  const {
    binding,
    label,
    ignoreAutorepeat = false
  } = getBindingWithFallback(keymap, locale);

  useEffect(() => {
    const currentRef = ref?.current;
    const handleKeyDown = (e) => {
      /**
       * When ignoreAutorepeat is turned on, the keybind
       * will only register once per keydown. Keyboard autorepeat
       * is ignored.
       */
      if (ignoreAutorepeat && e?.repeat) {
        return;
      }
      // ignore if user is typing in input field (unless they provide a ref, in which case accept everything)
      if (
        currentRef == null &&
        (e?.target?.nodeName === 'INPUT' || e?.target?.nodeName === 'TEXTAREA')
      ) {
        return;
      }
      const key = `${e.ctrlKey ? 'Control+' : ''}${e.shiftKey ? 'Shift+' : ''}${
        e.code
      }`;
      if (Array.isArray(binding) ? binding.includes(key) : binding === key) {
        // only prevent default if we match this keybinding
        e.preventDefault();
        onPress?.(e);
      }
    };

    if (enabled) {
      (currentRef ?? window).addEventListener('keydown', handleKeyDown, {
        capture
      });

      return () => {
        (currentRef ?? window).removeEventListener('keydown', handleKeyDown);
      };
    }
  }, [binding, capture, enabled, ignoreAutorepeat, onPress, ref]);

  return enabled ? label : '';
}

/**
 * A custom hook that helps handle form state.
 * Usage:
 *   const [formState, initForm] = useFormState();
 *
 *   Call initForm when the initial values are ready and it will setup objects to handle each one.
 *   Then formState contains an object with the value and onChange handlers for that input.
 *
 *   each form component has multiple handler functions. If the component returns bare state, use
 *   the `onChange` handler. if it returns an event, use the `onChangeEvent` handler.
 */
export function useFormState<MappedData extends object = any>() {
  const [isFormDirty, setIsFormDirty] = useState(false);

  const handleDirty = (value, newValue) => {
    if (!!value !== !!newValue) {
      setIsFormDirty(true);
    } else if (Array.isArray(value) && Array.isArray(newValue)) {
      if (value.length !== newValue.length) {
        setIsFormDirty(true);
      } else {
        for (let i = 0; i < value.length; i++) {
          if (
            value[i]?.value !== newValue[i]?.value ||
            value[i]?.label !== newValue[i]?.label
          ) {
            setIsFormDirty(true);
            break;
          }
        }
      }
    } else {
      setIsFormDirty(true);
    }
  };

  const genStateObj = <ValueType>(key: string, value: ValueType) => ({
    value,
    onChange: (newValue: ValueType | null | undefined) => {
      // this fixes CAC-2228 where setIsFormDirty was triggering true after clicking into the definition tab for picklist attributes.
      // this prevents the initial render from triggering true, but is not an ideal/long-term fix
      // isFormDirty is a cheap unsaved changes check- a better solution would be to compare initial vs current form values for changes
      // instead of just checking for any change. this does not reset to false if the changes are changed back to initial values
      handleDirty(value, newValue);
      return dispatch({
        type: 'ON_CHANGE',
        payload: {
          key,
          value: newValue
        }
      });
    },
    onChangeEvent: (e) => {
      setIsFormDirty(true);
      return dispatch({
        type: 'ON_CHANGE',
        payload: {
          key,
          value: e.target.value as ValueType
        }
      });
    }
  });
  const [formState, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case 'ON_CHANGE': {
        return {
          ...state,
          [action.payload.key]: genStateObj(
            action.payload.key,
            action.payload.value
          )
        };
      }
      case 'INIT': {
        setIsFormDirty(false);
        return Object.fromEntries(
          Object.entries(action.payload).map(([key, value]) => [
            key,
            genStateObj(key, value)
          ])
        );
      }
    }
  }, {}) as [
    {
      [K in keyof MappedData]: {
        // eslint-disable-next-line no-use-before-define
        value: MappedData[K] | undefined;
        // eslint-disable-next-line no-use-before-define
        onChange: (newValue: MappedData[K] | undefined) => void;
        onChangeEvent: (e: any) => void;
      };
    },
    (action: any) => void
  ];

  const initForm = useCallback(
    (initObject: Partial<MappedData>) =>
      dispatch({
        type: 'INIT',
        payload: initObject
      }),
    []
  );

  return [formState, initForm, isFormDirty] as const;
}

export function useCrudForm<MappedData extends object>(
  mapDataToForm: () => MappedData
) {
  const [formState, init, isFormDirty] = useFormState<MappedData>();

  const resetForm = useCallback(
    () => init(mapDataToForm()),
    [init, mapDataToForm]
  );

  useEffect(() => {
    resetForm();
  }, [resetForm]);

  return {
    formState,
    resetForm,
    isFormDirty
  } as const;
}
