import { useEffect } from 'react';
import { t } from '@lingui/macro';
import { useDispatch, useTypedSelector } from '../store/hooks';

import type { OMSObject } from '../oms/tsTypes';
import type { ValueOf } from '../utils/tsUtilityTypes';
import type { AccountData } from './tsTypes';

import { isNullish } from '../utils/isNullish';
import { fetchAccountInfo } from './actions';

export const FOLDERS_WITH_VISIBILITY_SUPPORT = {
  COLLECTIONS: 'collections',
  TAXONOMY: 'taxonomy',
  WORKSPACES: 'workspaces',
  NOTEBOOKS: 'notebooks',
  SAVED_SEARCH: 'savedSearch'
} as const;

export const VISIBILITY_TYPE = {
  [FOLDERS_WITH_VISIBILITY_SUPPORT.COLLECTIONS]: t`collection`,
  [FOLDERS_WITH_VISIBILITY_SUPPORT.TAXONOMY]: t`taxonomy`,
  [FOLDERS_WITH_VISIBILITY_SUPPORT.WORKSPACES]: t`workspace`,
  [FOLDERS_WITH_VISIBILITY_SUPPORT.NOTEBOOKS]: t`workspace`,
  [FOLDERS_WITH_VISIBILITY_SUPPORT.SAVED_SEARCH]: t`search`
} as const;

export type PossibleVisibilityFolders = ValueOf<
  typeof FOLDERS_WITH_VISIBILITY_SUPPORT
>;

export const getAclsByRootFolderId = (accountData: AccountData) => {
  const config = accountData?.config;
  const aclsByRootFolderId = Object.entries(config ?? {}).reduce(
    (acc, [key, value]) => {
      if (
        Object.values(FOLDERS_WITH_VISIBILITY_SUPPORT).includes(
          key as PossibleVisibilityFolders
        ) &&
        value?.rootFolder?.id
      ) {
        acc[value.rootFolder.id] = {
          privateAclId: value?.privateAcl?.id,
          publicAclId: value?.publicAcl?.id,
          folder: key
        };
      }
      return acc;
    },
    {}
  );
  return aclsByRootFolderId;
};

export const usePublicPrivateAcls = ({
  object
}: {
  object?: OMSObject | null;
}) => {
  const rootFolderId = object?.path?.[0]?.id;
  const { aclsByRootFolderId, isLoading, error } = useDefaultAclIds();
  return {
    publicAclId: !rootFolderId
      ? null
      : aclsByRootFolderId?.[rootFolderId]?.publicAclId,
    privateAclId: !rootFolderId
      ? null
      : aclsByRootFolderId?.[rootFolderId]?.privateAclId,
    isLoading,
    error
  } as const;
};

export const useDefaultAclIds = () => {
  const { account, isLoading, error } = useAccountData();
  const accountDefaultAcls = account?.config;
  const aclsByRootFolderId = getAclsByRootFolderId(account);
  return {
    accountDefaultAcls,
    aclsByRootFolderId,
    isLoading,
    error
  } as const;
};

// ! note: there is an issue with the fetchAccountInfo saga- after creating a tenant it will likely return accountData: undefined and null/errors for other fields.
// these are not valid or expected types for the reducer and can cause crashes if trying to destructure from account elsewhere
// not a major issue to address now and is fixed on page refresh, but be aware!
export function useAccountData() {
  const dispatch = useDispatch();
  const { accountDataLoading, data, fetchAccountError, ...restOfAccountState } =
    useTypedSelector((state) => state.account);
  const { dataRealm } = useTypedSelector((state) => state.api) as {
    dataRealm: string | null;
  };

  useEffect(() => {
    if (!data || (!isNullish(dataRealm) && data?.realmName !== dataRealm)) {
      dispatch(fetchAccountInfo());
    }
  }, [data, dataRealm, dispatch]);

  return {
    isLoading: accountDataLoading,
    error: fetchAccountError,
    account: data ?? {},
    ...restOfAccountState
  } as const;
}
