import { find } from 'lodash';

import type {
  CATEGORIES,
  PossibleKnownObjectLinks,
  PossibleKnownRenditionTypes,
  PossibleRenditionLinks
} from '../../constants';
import type {
  OMSCompositeRenditionAttributes,
  OMSObject,
  OMSRendition
} from '../../oms/tsTypes';

import {
  LOCAL_DEV_URL,
  RENDITION_LINKS,
  SPECIAL_RENDITION_MIME_TYPES
} from '../../constants';

/**
 * Grabs the MIME type from the OMS object
 * @param {object} omsObject object from OMS
 * @param {string} renditionType see src/constants/RENDITION_TYPES
 * @returns string MIME type if found, falsey otherwise
 */
export const getRenditionMimeType = (
  omsObject: Pick<OMSObject<typeof CATEGORIES.CONTENT>, 'renditions'>,
  renditionType?: PossibleKnownRenditionTypes,
  onlyReturnSpecifiedRendition?: boolean
) => {
  const rendition = getRenditionObject(
    omsObject,
    renditionType,
    onlyReturnSpecifiedRendition
  );
  return rendition?.mimeType;
};

/**
 * Grabs the href from the _links object in an OMS object
 * Dives into a specific rendition if one is specified
 * @param {object} omsObject object from OMS
 * @param {string} linkType see src/constants/RENDITION_LINKS
 * @param {string} renditionType see src/constants/RENDITION_TYPES
 * @returns string href if link found, falsey otherwise
 */
export function getLinkFromOmsObject(
  omsObject: Pick<OMSObject, '_links'> | null | undefined,
  linkType: PossibleKnownObjectLinks | null | undefined
) {
  if (!omsObject || !linkType) {
    return null;
  }

  return omsObject?._links?.[linkType]?.href;
}

export function getLinkFromRenditionObject(
  rendition: OMSRendition | null,
  linkType: PossibleRenditionLinks
) {
  if (
    window.location.hostname === LOCAL_DEV_URL &&
    [RENDITION_LINKS.DOWNLOAD, RENDITION_LINKS.PRINT].includes(linkType)
  ) {
    return rendition?.contentId
      ? `${window.location.origin}/content-storage-service/api/v1/contents/${rendition?.contentId}`
      : null;
  } else {
    return rendition?._links?.[linkType]?.href;
  }
}

/**
 * A function to get an href from a _link on any object
 * should automatically replace the url with the local one if runnning on localhost
 */
export function getLinkFromAnythingWorksLocally(
  object: { _links: Record<string, { href: string }> },
  linkType: string
) {
  const href = object?._links?.[linkType]?.href;
  if (window.location.hostname === LOCAL_DEV_URL && !!href) {
    const url = new URL(href);
    url.hostname = window.location.hostname;
    url.port = window.location.port;
    url.protocol = window.location.protocol;
    return url.toString();
  } else {
    return href;
  }
}

/**
 * Gets a rendition off an omsObject
 * @param {object} omsObject object from OMS
 * @param {string} renditionType see src/constants/RENDITION_TYPES
 * @returns {object} rendition object or null
 */
export const getRenditionObject = (
  omsObject:
    | Pick<OMSObject<typeof CATEGORIES.CONTENT>, 'renditions'>
    | null
    | undefined,
  renditionType?: PossibleKnownRenditionTypes,
  onlyReturnSpecifiedRendition = false
) => {
  if (!omsObject?.renditions?.length) {
    return null;
  } else {
    const lcRenditionType = renditionType?.toLowerCase();
    const { renditions } = omsObject;

    let specifiedRendition = find(
      renditions,
      (rendition) => lcRenditionType === rendition?.renditionType?.toLowerCase()
    );

    const primaryRendition = find(
      renditions,
      (rendition) => rendition.primaryFlag === true
    );

    /**
     * We need this for backwards compatibility
     * Some flowable tasks will request specifically the primaryFlag rendition
     */
    if (lcRenditionType === 'primaryflag') {
      specifiedRendition = primaryRendition;
    }

    if (renditionType && !specifiedRendition && onlyReturnSpecifiedRendition) {
      return null;
    }

    return specifiedRendition ?? primaryRendition ?? renditions[0];
  }
};

export const splitCompositeRenditions = (
  omsObject: OMSObject<typeof CATEGORIES.CONTENT>,
  compositeRenditionType: PossibleKnownRenditionTypes,
  onlyReturnSpecifiedRendition = false
) => {
  const compositeRendition: OMSRendition<OMSCompositeRenditionAttributes> | null =
    getRenditionObject(
      omsObject,
      compositeRenditionType,
      onlyReturnSpecifiedRendition
    );

  return {
    imageRendition: getRenditionObject(
      omsObject,
      // find the first rendition that is not a DTL, as it must be an image
      compositeRendition?.attributes?.renditions?.find(
        (renditionName) =>
          getRenditionMimeType(omsObject, renditionName, true) !==
          SPECIAL_RENDITION_MIME_TYPES.DTL
      ),
      true
    ),
    dtlRendition: getRenditionObject(
      omsObject,
      // find the first rendition that IS a DTL
      compositeRendition?.attributes?.renditions?.find(
        (renditionName) =>
          getRenditionMimeType(omsObject, renditionName, true) ===
          SPECIAL_RENDITION_MIME_TYPES.DTL
      ),
      true
    )
  };
};
