import React from 'react';
import styled from 'styled-components';

import type { PossiblePrincipalTypes } from '../../../mts/principals/utils';

import { usePrincipalData } from '../../../mts/principals/hooks';
import {
  getDisplayStringForPrincipal,
  PRINCIPAL_TYPE
} from '../../../mts/principals/utils';
import timber from '../../../timber/macro';
import TextLoadingShimmer from '../../../ui/components/text-loading-shimmer';
import Icon from './Icon';

export type Props = {
  principalId: string;
  /** If provided, the lookup will be skipped and the provided name will be used instead */
  preResolvedName?: string;
  /** Should be provided if the preResolvedName is provided to determine the icon to use in that case, defaults to a user icon */
  preResolvedPrincipalType?: 'user' | 'group' | 'userAlias';
  setErrorToShow?: (error: Error | null) => void;
  className?: string;
  showSmallerIcon?: boolean;
  hidePrincipalIcon?: boolean;
  title?: string;
};

export const RipcordPrincipalName = ({
  principalId,
  preResolvedName,
  preResolvedPrincipalType,
  setErrorToShow,
  className,
  hidePrincipalIcon,
  showSmallerIcon,
  title
}: Props) => {
  const {
    isLoading,
    error: resolvedError,
    data: resolvedData
  } = usePrincipalData(!!preResolvedName ? null : principalId);

  const resolvedName = !!preResolvedName
    ? preResolvedName
    : !!resolvedError
    ? principalId
    : getDisplayStringForPrincipal(resolvedData);

  const resolvedPrincipalIconName = hidePrincipalIcon
    ? null
    : !!preResolvedPrincipalType
    ? preResolvedPrincipalType
    : getIconForPrincipalType(resolvedData?.principalType);

  return !preResolvedName && isLoading ? (
    <TextLoadingShimmer numChars={8} />
  ) : (
    <StyledPrincipalName
      className={className}
      title={title ?? resolvedName}
      $isClickable={!!resolvedError && !!setErrorToShow}
      $noIcon={!resolvedPrincipalIconName}
      onClick={
        !!resolvedError
          ? () => {
              timber.error(resolvedError);
              setErrorToShow?.(resolvedError);
            }
          : undefined
      }>
      {!!resolvedPrincipalIconName && (
        <StyledIcon
          variant={resolvedPrincipalIconName}
          size={showSmallerIcon ? 16 : 24}
          $showSmallerIcon={showSmallerIcon}
        />
      )}
      {resolvedName}
    </StyledPrincipalName>
  );
};

export const getIconForPrincipalType = (
  principalType: PossiblePrincipalTypes | undefined
) => {
  switch (principalType) {
    case PRINCIPAL_TYPE.USER:
      return 'user';
    case PRINCIPAL_TYPE.GROUP:
      return 'group';
    case PRINCIPAL_TYPE.ALIAS:
      return 'userAlias';
    default:
      principalType satisfies undefined;
  }
};

const StyledPrincipalName = styled.span<{
  $isClickable?: boolean;
  $noIcon?: boolean;
}>`
  cursor: ${({ $isClickable }) => ($isClickable ? 'pointer' : 'default')};
  margin-left: ${({ $noIcon }) => ($noIcon ? 0 : 4)}px;
`;
const StyledIcon = styled(Icon)<{
  $showSmallerIcon?: boolean;
}>`
  &&&& {
    display: inline-block;
    line-height: inherit;
    vertical-align: middle;
    margin-right: ${({ $showSmallerIcon }) =>
      $showSmallerIcon ? '4px' : '7px'};
    ${({ $showSmallerIcon }) =>
      $showSmallerIcon ? 'height: 16px !important;' : ''}
    color: ${({ theme }) => theme.colors.gray['400']};
    width: auto;
    height: 1.5rem;
  }
`;
