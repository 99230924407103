import { createAction } from '@reduxjs/toolkit';

import type { PotentiallyErroredPrincipal, Principal } from './tsTypes';

/**
 * Action used to trigger a request for principal data
 * Use this in order to allow for proper debouncing
 * of requests to MTS
 */
export const requestPrincipalData = createAction(
  'ripcord/mts/principals/REQUEST_PRINCIPAL_DATA',
  ({ principalId }: { principalId: Principal['id'] | Principal['id'][] }) => ({
    payload: { principalId }
  })
);

/**
 * SINGLE PRINCIPAL MTS ENDPOINT
 */
export const resolvePrincipalSucceeded = createAction(
  'ripcord/mts/principals/RESOLVE_PRINCIPAL_SUCCEEDED',
  ({ principal }: { principal: PotentiallyErroredPrincipal }) => ({
    payload: { principal }
  })
);
/**
 * Unlike the multiple principals case, 404s do not
 * silently fail for the single principal endpoint
 */
export const resolvePrincipalFailed = createAction(
  'ripcord/mts/principals/RESOLVE_PRINCIPAL_FAILED',
  ({
    principalId,
    error
  }: {
    principalId: Principal['id'];
    error: Error | null;
  }) => ({
    payload: { principalId, error }
  })
);

/**
 * MULTIPLE PRINCIPALS MTS ENDPOINT
 */
export const resolvePrincipalsSucceeded = createAction(
  'ripcord/mts/principals/RESOLVE_PRINCIPALS_SUCCEEDED',
  ({ principals }: { principals: PotentiallyErroredPrincipal[] }) => ({
    payload: { principals }
  })
);
/**
 * MTS does not currently support partial failures
 * on query for multiple principals so we will propagate
 * the singular error we get back to all principals whose
 * data we requested in the same request
 * 404s silently fail in that MTS only returns what it can find.
 * This is handled separately in the success case.
 */
export const resolvePrincipalsFailed = createAction(
  'ripcord/mts/principals/RESOLVE_PRINCIPALS_FAILED',
  ({
    principalIds,
    error
  }: {
    principalIds: Principal['id'][];
    error: Error | null;
  }) => ({
    payload: { principalIds, error }
  })
);
