import '../../utils/wdyr';
import 'whatwg-fetch';
import '../../styles/main.scss';

import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import smoothscroll from 'smoothscroll-polyfill';

import SuspenseThrobber from '../../ripcord-components/loading/progress-indicator/suspenseThrobber';
import { shipLogsOnUnhandledError } from '../../timber/logShipper';
import configureLocale from '../../translations/configure-locale';
import configureCustomerIo from '../configureCustomerIo';
import configureMixpanel from '../configureMixpanel';

shipLogsOnUnhandledError();

// begin loading the locale ASAP
const localePromise = configureLocale();

configureMixpanel();
configureCustomerIo(
  window.location.hostname.includes('app.us2.docufai.com')
    ? 'd18f05248d10a2db9552'
    : '11939df46dd1fbaf6978'
);

smoothscroll.polyfill();

// don't render until the locale is fully loaded and activated
localePromise.then(async () => {
  const [
    { history },
    { default: rootReducer },
    { default: rootSaga },
    { default: configureStore },
    { default: ParachuteRouter },
    { default: Providers }
  ] = await Promise.all([
    import(/* webpackPreload: true */ '../canopy/routes'),
    import(/* webpackPreload: true */ './reducers'),
    import(/* webpackPreload: true */ './sagas'),
    import(/* webpackPreload: true */ '../../store/configure-store'),
    import(/* webpackPreload: true */ './router'),
    import(/* webpackPreload: true */ '../containers/providers')
  ]);

  const initialState = {};
  const store = configureStore(rootReducer, rootSaga, initialState, history);

  const root = createRoot(document.getElementById('root'));
  root.render(
    <Providers appType={'parachute'} store={store} history={history}>
      <StrictMode>
        <SuspenseThrobber>
          <ParachuteRouter />
        </SuspenseThrobber>
      </StrictMode>
    </Providers>
  );
});
