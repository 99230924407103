import { getCommonHeaders } from '../api/internalApiUtils';
import { track } from '../utils/mixpanel';
import timber from './macro';
import { enqueue, flush } from './ringBuffer';

let numSendFailures = 0;
let trackedMixpanelFailure = false;

export async function shipLogs() {
  if (!trackedMixpanelFailure && numSendFailures > 5) {
    timber.error('Failed to ship logs too many times, giving up');
    track('Log Shipping Failed', {
      numFailures: numSendFailures
    });
    trackedMixpanelFailure = true;
    return;
  }

  let logs: ReturnType<typeof flush> = [];
  try {
    logs = flush();
    if (logs.length) {
      timber.info(`Shipping ${logs.length} logs to Sawmill`);
      const body = JSON.stringify(logs);
      const url = '/core-sawmill/log';
      const request = new Request(url, {
        method: 'POST',
        headers: {
          ...getCommonHeaders(),
          'Content-Type': 'application/json'
        },
        body
      });

      const response = await fetch(request, {
        credentials: 'same-origin',
        cache: 'no-store'
      });

      if (!response.ok) {
        throw new Error(
          `Invalid response from Sawmill while shipping logs. Expected 200 OK, got ${response.status} ${response.statusText}`
        );
      }
    }
  } catch (err) {
    numSendFailures++;
    enqueue(logs);

    timber.error('Failed to ship logs', err);
  }
}

export function shipLogsOnUnhandledError() {
  window.addEventListener('error', (event) => {
    timber.error('Unhandled error', event.error);
    shipLogs();
  });

  window.addEventListener('unhandledrejection', (event) => {
    timber.error('Unhandled Promise Rejection', event.reason);
    shipLogs();
  });
}
