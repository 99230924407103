import React, { forwardRef, useState } from 'react';
import styled, { useTheme } from 'styled-components';

import type { ComponentPropsWithRef, Ref, SyntheticEvent } from 'react';
import type {
  PossibleColors as ColorType,
  PossibleIconNames as IconType
} from '../../../../theme/tsTypes';

import Icon from '../Icon';
import RippyTippy from '../RippyTippy';

const iconButtonStyles = `
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  outline-style: none;
`;

const disabledStyles = `
  cursor: not-allowed;
  opacity: 0.5;
`;

const hiddenStyles = `
  &&&&& {
    display: none;
  }
`;

const Component = styled.button<{
  className?: string;
  disabled?: boolean;
  $hidden?: boolean;
  title?: string;
  $size: number;
  $iconMargin: number;
  $iconColor?: ColorType;
  $hoverColor?: ColorType;
  $activeColor?: ColorType;
}>`
  ${iconButtonStyles};
  ${(props) => (props.disabled ? disabledStyles : '')}
  ${({ $hidden }) => ($hidden ? hiddenStyles : '')}

  background-color: transparent;
  color: ${({ $iconColor }) => $iconColor};

  height: ${({ $size, $iconMargin }) => $size + $iconMargin}px;
  width: ${({ $size, $iconMargin }) => $size + $iconMargin}px;

  ${(props) =>
    props.$iconColor &&
    `
      svg {
        color: ${props.$iconColor};
      }
    `}

  &:hover {
    svg {
      color: ${({ $hoverColor }) => $hoverColor};
    }
  }

  &:active {
    background-color: ${({ theme }) => theme.colors.primary.T010};
    svg {
      color: ${({ $activeColor }) => $activeColor};
    }
  }

  &:active:disabled {
    background-color: transparent;
  }
`;

export type Props = {
  className?: string;
  variant: IconType;
  hoverVariant?: IconType;
  size?: number;
  disabled?: boolean | string;
  hidden?: boolean;
  name?: string;
  iconColor?: ColorType;
  iconColorHover?: ColorType;
  iconColorActive?: ColorType;
  iconFitWidth?: boolean;
  iconFitHeight?: boolean;
  iconMargin?: number;
  rotate?: number; // number or degrees to rotate
  flipHorizontal?: boolean;
  flipVertical?: boolean;
  onClick: (e: SyntheticEvent<HTMLElement>) => void;
} & ComponentPropsWithRef<typeof Component>;
const IconButton = forwardRef(
  (
    {
      className,

      variant,
      hoverVariant,
      size = 24,
      iconMargin = 8,

      disabled,
      hidden,
      title = '',

      iconColor,
      iconColorHover,
      iconColorActive,
      iconFitWidth = false,
      iconFitHeight = false,

      rotate,
      flipHorizontal,
      flipVertical,

      onClick,

      ...restProps
    }: Props,
    ref: Ref<any>
  ) => {
    const [isHovered, setIsHovered] = useState(false);
    const theme = useTheme();

    return (
      <Component
        ref={ref}
        className={className}
        onClick={onClick}
        disabled={disabled}
        $hidden={hidden}
        $size={size}
        $iconMargin={iconMargin}
        $iconColor={iconColor || theme.colors.gray['400']}
        $hoverColor={iconColorHover || theme.colors.primary['500']}
        $activeColor={iconColorActive || theme.colors.primary['600']}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        name={title}
        {...restProps}>
        <RippyTippy
          content={
            !!disabled && typeof disabled === 'string' ? disabled : title
          }
          onShow={() => !!title}>
          <Icon
            variant={isHovered && hoverVariant ? hoverVariant : variant}
            size={size}
            fitWidth={iconFitWidth}
            fitHeight={iconFitHeight}
            rotate={rotate}
            flipHorizontal={flipHorizontal}
            flipVertical={flipVertical}
          />
        </RippyTippy>
      </Component>
    );
  }
);

export default IconButton;
