import qs from 'qs';

import type { PossibleSearchModes } from '../ui/slices';
import type { MaybeExpressionNode } from '../utils/ast';

import { isOnRoute, SOURCE_ROUTE } from '../app/canopy/routes';
import {
  DISPLAY_MODE_CACHE_KEY,
  LIST_VIEWS
} from '../ripcord-components/components/canopy/ListViews/constants';
import { SEARCHMODES } from '../ui/slices';
import {
  filtersObjectToString,
  filtersStringToObject,
  maybeEmit,
  maybeParse
} from '../utils/ast';

export type StandardSortParam = Array<{
  /** the id column this sort refers to */
  id: string;
  /** true if descending */
  desc?: boolean;
}>;

export type RipcordLocation<LocationState = any> = Omit<Location, 'search'> & {
  state: LocationState;
  search: {
    /** used to determine what type of view to load lists of objects in */
    listView?: string;
    /** used to determine which rendition we should load */
    rendition?: string;
    /** array of objects for multi-sort */
    sort?: StandardSortParam;
    /** page size */
    size?: number;
    /** page number, 0-indexed in this form */
    page?: number;
    /** filters for tasks, just an object of strings to filter with */
    taskFilter?: {
      [key: string]: string;
    };
    userFilter?: {
      // filters for users; simple key/value pair for each field
      [key: string]: string;
    };
    /** filters for OMS objects, not typed because it's a doozie! */
    filters?: any;
    /** the source/library split we are search in at any moment */
    searchMode?: PossibleSearchModes;
    /**  the searchMode toggle flag for advanced search */
    advSearchIn?: Omit<PossibleSearchModes, 'advanced'>;
    /** uuid of the container we should search the descendants of for scoped search */
    scoped?: string;
    /** string representing the tenant used for the data realm token for tenant switching or linking from scan/prep app */
    switchedTenant?: string;
    /** Loads a different version of an object by oms id. Only used on the records page. */
    versionId?: string;
    /** Search filters as a parsed set of MaybeExpressionNodes */
    sfilters?: MaybeExpressionNode;
    /** An ID that should be converted to a chronicle ID before being used, currently only used for audit search */
    allVersionsObjectId?: string;
    /** Needed on the audit search pages because of a crazy edge case where we want the default query to include a filter and the non-default to remove it, but we need to tell the default apart from the "manually removed" case */
    includeServiceAccounts?: boolean;
  };
};

/**
 * Handles converting a regular location object into a RipcordLocation object. That includes
 * parsing the search string into an object. No defaults or saved values should be applied in
 * this step, it should just be a pure conversion of the loctation object.
 */
export const locationToRipcordLocation = ({
  search,
  state,
  pathname,
  ...restOfLocation
}: Location & { state?: any }): RipcordLocation => {
  const searchObject = qs.parse(search, { ignoreQueryPrefix: true }) ?? {};

  const ripcordSearchObject = {
    ...(searchObject as object),
    sort: sortStringToObject(searchObject.sort as string) ?? undefined,
    size: !!searchObject?.size
      ? parseInt(searchObject.size as string, 10)
      : undefined,
    page: !!searchObject?.page
      ? parseInt(searchObject.page as string, 10) - 1
      : undefined,
    taskFilter: !!searchObject?.taskFilter
      ? JSON.parse(searchObject?.taskFilter as string)
      : undefined,
    userFilter: !!searchObject?.userFilter
      ? JSON.parse(searchObject?.userFilter as string)
      : undefined,
    filters: !!searchObject?.filters
      ? filtersStringToObject(searchObject.filters as string)
      : undefined,
    sfilters: maybeParse(searchObject?.sfilters as string),
    includeServiceAccounts: searchObject?.includeServiceAccounts
      ? searchObject?.includeServiceAccounts === 'true'
      : undefined
  } as RipcordLocation['search'];

  return {
    ...restOfLocation,
    state,
    pathname,
    search: ripcordSearchObject
  } as const;
};

/**
 * Many of our query params have defaults that should apply when the param is not present, and
 * others have values that should be pulled from localstorage if not present. This function handles
 * adding those to the location object given and returning a new one.
 */
export const addDefaultsAndStoredValues = (
  { pathname, search, state = {}, ...restOfLocation }: RipcordLocation,
  dataRealm?: string
): RipcordLocation => {
  const localstoragePageSize = localStorage.getItem('pagination.pageSize');

  const newSearchObject = {
    ...search,
    // prettier-ignore
    listView: !!search?.listView
      ? search.listView
      : (localStorage.getItem(DISPLAY_MODE_CACHE_KEY) ?? LIST_VIEWS.TABLE),
    searchMode: (!!search?.searchMode
      ? search.searchMode
      : isOnRoute(pathname, SOURCE_ROUTE)
      ? SEARCHMODES.SOURCE
      : SEARCHMODES.LIBRARY) as PossibleSearchModes,
    advSearchIn: (!!search?.advSearchIn
      ? search.advSearchIn
      : SEARCHMODES.LIBRARY) as Omit<PossibleSearchModes, 'advanced'>,
    sort: search?.sort ?? [],
    size: !!search?.size
      ? search.size
      : localstoragePageSize?.length
      ? parseInt(localstoragePageSize, 10)
      : 20,
    page: !!search?.page ? search.page : 0,
    taskFilter: !!search?.taskFilter ? search?.taskFilter : {},
    userFilter: !!search?.userFilter ? search?.userFilter : {},
    filters: !!search?.filters ? search.filters : {},
    // prettier-ignore
    switchedTenant: !!search?.switchedTenant
      ? search.switchedTenant
      : (sessionStorage.getItem('dataRealm') ?? dataRealm ?? undefined)
  } as const;

  return {
    ...restOfLocation,
    pathname,
    state,
    search: newSearchObject
  } as const;
};

/**
 * And of course to round things out, this converts a ripcordLocation object back to a regular
 * location. This should account for "default" values, so for example if a param like `size`
 * defaults to 20, then when the `size` is actually set to 20, it shouldn't set any query param.
 * This keeps the URL free of unnecessary params and much cleaner looking.
 */
export const ripcordLocationToLocation = ({
  search: ripcordSearchObject,
  ...restOfLocation
}: Partial<RipcordLocation>): Location & { state?: any } => {
  const searchObject = {
    ...ripcordSearchObject,
    sort:
      Object.keys((ripcordSearchObject?.sort as any) ?? {}).length > 0
        ? sortObjectToString(ripcordSearchObject!.sort)
        : undefined,
    size:
      ripcordSearchObject?.page == null || ripcordSearchObject?.size === 20
        ? undefined
        : ripcordSearchObject?.size,
    page:
      ripcordSearchObject?.page == null || ripcordSearchObject?.page === 0
        ? undefined
        : Number(ripcordSearchObject?.page) + 1,
    taskFilter:
      Object.keys((ripcordSearchObject?.taskFilter as any) ?? {}).length > 0
        ? JSON.stringify(ripcordSearchObject!.taskFilter)
        : undefined,
    userFilter:
      Object.keys((ripcordSearchObject?.userFilter as any) ?? {}).length > 0
        ? JSON.stringify(ripcordSearchObject!.userFilter)
        : undefined,
    filters:
      Object.keys((ripcordSearchObject?.filters as any) ?? {}).length > 0
        ? filtersObjectToString(ripcordSearchObject!.filters)
        : undefined,
    sfilters: maybeEmit(ripcordSearchObject?.sfilters),
    includeServiceAccounts:
      ripcordSearchObject?.includeServiceAccounts?.toString() ?? undefined
  };

  return {
    ...(restOfLocation as Required<typeof restOfLocation>),
    search: qs.stringify(searchObject, {
      skipNulls: true
    })
  };
};

/**
 * Translate the array of sort objects into a string query parameter
 */
export const sortObjectToString = (
  sorts: NonNullable<RipcordLocation['search']>['sort']
) => {
  return sorts?.map(({ id, desc }) => `${id}${desc ? ' desc' : ''}`).join(', ');
};

/**
 * Translate the string query parameter into an array of sort objects
 */
export const sortStringToObject = (
  sort: string
): NonNullable<RipcordLocation['search']>['sort'] => {
  return (
    sort?.split(', ')?.map((sort) => {
      const [sortKey, dir] = sort.split(' ');
      return {
        id: sortKey,
        desc: dir === 'desc'
      };
    }) || []
  );
};

// Deprecated
export const queryStringToQueryObject = (queryString) => {
  return qs.parse(queryString, { ignoreQueryPrefix: true });
};

// Deprecated
export const queryObjectToQueryString = (queryObject) => {
  return qs.stringify(queryObject, { skipNulls: true });
};

// Deprecated
export const formRequestSortParams = (sort) => {
  return (
    sort?.map?.(({ id, desc }) => id + (desc ? ' desc' : ''))?.join?.(', ') ||
    undefined
  );
};
