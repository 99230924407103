import mixpanel from 'mixpanel-browser';

import timber from '../timber/macro';

const DEV_TOKEN = '725d0912d5324e972d03f22d940a6735';
const PROD_TOKEN = '31a5b2e7a95749c268ea96956626bc95';
const PROD_DOCUFAI_TOKEN = 'f67ac079cb99d7a2ad0e1befe614a70c';

export default function configureMixpanel() {
  try {
    const { protocol, host } = window.location;
    const apiHost = `${protocol}//${host}/mixpanel`;

    const token =
      window.location.hostname.indexOf('.docufai.com') !== -1
        ? PROD_DOCUFAI_TOKEN
        : window.location.hostname.indexOf('.ripcord.com') !== -1 ||
          window.location.hostname.indexOf('.ripcord.co.jp') !== -1
        ? PROD_TOKEN
        : DEV_TOKEN;

    // Note: Below, api_host is used as the API host property. Avoid the urge to
    // change it to apiHost, as that will break the Mixpanel configuration;
    // it expects "api_host".
    mixpanel.init(token, {
      api_host: apiHost
    });
  } catch (e) {
    timber.error('Error initializing mixpanel: ', e);
  }
}
