import React from 'react';
import { t, Trans } from '@lingui/macro';
import styled from 'styled-components';

import { history, TAXONOMY_ROUTE } from '../../app/canopy/routes';
import { ROLES, tokenHasOneOfRoles } from '../../auth/keycloakSelectors';
import {
  BASE_TYPE,
  ICON_COMPLETED,
  OMS_DATA_TYPES,
  OMS_OBJECT_LINKS
} from '../../constants';
import { MODAL_LEGACY_TAXONOMY } from '../../modals/constants';
import RipcordDisplayValue from '../../ripcord-components/components/ripcord/RipcordDisplayValue';
import { getLinkFromOmsObject } from '../../utils/records/url';
import { generatePath } from '../../utils/routing';
import LegacyTaxonomyManageActionsMenuButtons from './LegacyTaxonomyManageActionsMenuButtons';

const getLegacyTaxonomyListItems = (taxonomies, currentId, theme) => {
  return taxonomies.map((taxonomy) => {
    const isSelected = currentId && currentId === taxonomy.id;
    const rightHoverItem = (
      <LegacyTaxonomyManageActionsMenuButtons view={taxonomy} theme={theme} />
    );

    return {
      text: taxonomy.label,
      icon: isSelected ? ICON_COMPLETED : null,
      iconPosition: 'right',
      'data-test-id': `menu-taxonomy-${taxonomy.id}`,
      rightHoverItem,
      action: () => {
        history.push(
          generatePath(TAXONOMY_ROUTE, {
            id: taxonomy.id,
            0: ''
          })
        );
      }
    };
  });
};

function LegacyTaxonomyListWrapper({ children }) {
  return (
    <TaxonomyItemsWrapperContainer>{children}</TaxonomyItemsWrapperContainer>
  );
}

const NewLegacyTaxonomyMenuItem = () => (
  <span>
    <MenuItemIconContainer>
      <MenuItemIcon>+</MenuItemIcon>
    </MenuItemIconContainer>
    <Trans>New taxonomy view</Trans>
  </span>
);

export const getLegacyTaxonomyMenuActions = ({
  taxonomies = [],
  currentId,
  rootFolder,
  theme,
  showModalAction
}) => {
  const canCreateTaxonomy =
    tokenHasOneOfRoles([ROLES.TENANT_ADMIN, ROLES.CUSTOMER_ADMIN]) &&
    !!getLinkFromOmsObject(rootFolder, OMS_OBJECT_LINKS.ADD_DESCENDANTS);
  if (taxonomies?.length) {
    taxonomies = getLegacyTaxonomyListItems(taxonomies, currentId, theme);
    taxonomies = taxonomies.sort((a, b) => a.text.localeCompare(b.text));
  }
  return [
    {
      isGroup: true,
      WrapperComponent: LegacyTaxonomyListWrapper,
      items: taxonomies
    },
    {
      isDivider: true,
      variant: 'small',
      isVisible: canCreateTaxonomy
    },
    {
      text: <NewLegacyTaxonomyMenuItem />,
      icon: '',
      iconPosition: 'none',
      action: () => showModalAction(MODAL_LEGACY_TAXONOMY),
      isVisible: canCreateTaxonomy,
      'data-test-id': 'create-new-taxonomy-view-button'
    }
  ];
};

/**
 * parses the "splat" from react-router (just the raw path data after the params are handled)
 * into an array of arrays which hold the key and value part of the path.
 */
export const parseLegacyTaxonomyPath = (taxSplat) => {
  return taxSplat
    .split('/')
    .filter((segment) => segment.length)
    .map((segment) => decodeURIComponent(segment))
    .reduce((path, segmentPart, index) => {
      const segmentIndex = Math.floor(index / 2);
      path[segmentIndex] = path[segmentIndex]?.concat(segmentPart) ?? [
        segmentPart
      ];
      return path;
    }, []);
};

/**
 * Compiles that array of arrays into a path that can be used to navigate to a taxonomy in the UI
 */
export const compileLegacyTaxonomyPath = (taxPathArray) => {
  return taxPathArray
    ?.flat(1)
    ?.map((segment) => encodeURIComponent(segment))
    ?.join('/');
};

/**
 * Converts the taxonomy path object (array of arrays) into a fiters object that the search hooks can use
 */
export const convertLegacyTaxonomyPathToFiltersObject = (taxPathArray) => {
  return taxPathArray
    .filter(([, value]) => value !== 'Unspecified')
    .reduce((filtersObject, [name, value]) => {
      filtersObject[name] = {
        operator: '==',
        value
      };
      return filtersObject;
    }, {});
};

export const getLegacyTaxonomyBreadcrumbs = (
  taxonomy,
  taxonomyPath,
  isLeafLevel,
  attributeName,
  allAttributesByName,
  typesByName
) => {
  const currentAttributeLabel =
    allAttributesByName?.[attributeName]?.displayLabel;
  const crumbBase = {
    type: BASE_TYPE.TAXONOMY,
    id: taxonomy?.id,
    source: false
  };
  // set root taxonomy label crumb
  const rootTaxBreadrumb = {
    ...crumbBase,
    label: t`${taxonomy?.label}`
  };
  const crumbs = [rootTaxBreadrumb];
  // iterate over current taxonomyPath so each taxonomy level has an associated crumb
  taxonomyPath?.forEach((path, index) => {
    const pathName = path[0];
    const pathValue =
      pathName === 'type' ? typesByName?.[path[1]]?.displayLabel : path[1]; // taxonomy buckets with specific types return the name as the label, so we need to lookup the type displayLabel
    const pathLabel = allAttributesByName?.[pathName]?.displayLabel;
    const attributeDefinition = allAttributesByName?.[pathName];
    const pathDisplayValue = (
      <RipcordDisplayValue
        attributeType={attributeDefinition?.attributeType}
        attributeName={path[0]}
        options={attributeDefinition?.pickList}
        value={path[1]}
      />
    );
    /**
     * Temporarily exclude dates and datetimes from RipcordDisplayValue
     * because the date format is not standard ISO and not parseable by
     * RipcordDisplayValue
     */
    crumbs.push({
      ...crumbBase,
      label:
        currentAttributeLabel &&
        currentAttributeLabel === pathName &&
        !isLeafLevel ? (
          t`${pathLabel}: All`
        ) : pathName === 'type' ||
          [OMS_DATA_TYPES.DATE, OMS_DATA_TYPES.DATETIME].includes(
            attributeDefinition?.attributeType
          ) ? (
          t`${pathLabel}: ${pathValue}`
        ) : (
          <StyledLabelContainer>
            <Trans>
              {pathLabel}: &nbsp;
              {pathDisplayValue}
            </Trans>
          </StyledLabelContainer>
        ),
      path: index === 0 ? [] : taxonomyPath.slice(0, index)
    });
  });
  // specify the current taxonomy level for an associated crumb
  // where the path is the prior levels' crumbs
  const currentLevel = isLeafLevel
    ? crumbs.pop()
    : {
        ...crumbBase,
        label: t`${currentAttributeLabel}: All`
      };
  // set currentLevel currentPath to be one level back
  currentLevel.currentPath = taxonomyPath.slice(0, taxonomyPath.length - 1);
  return { ...currentLevel, path: crumbs };
};

const MenuItemIconContainer = styled.span`
  font-weight: 300;
  padding-right: 8px;
`;

const MenuItemIcon = styled.span`
  font-weight: 900;
  width: 9px;
  height: 8px;
`;

const TaxonomyItemsWrapperContainer = styled.div`
  max-height: 50vh;
  overflow-y: auto;
`;

const StyledLabelContainer = styled.span`
  display: flex;

  &,
  * {
    color: ${({ theme }) => theme.colors.primary.main};
  }
`;
