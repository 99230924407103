import { i18n } from '@lingui/core';
import { DateTime, Interval } from 'luxon';
/** temporary package until luxon sorts out its humanize function
 * https://github.com/moment/luxon/issues/1134 */
import humanizeDuration from 'humanize-duration';

import { DATETIME_FORMATS, type PossibleExactDateTimeFormats } from '../config';

export const isoToLocale = (
  isoString: string,
  format: PossibleExactDateTimeFormats = DATETIME_FORMATS.DATETIME.SHORT
) => {
  /** Pull our locale from lingui */
  const { locale } = i18n;

  /**
   * Luxon automatically translates our datetime into the browser
   * locale and browser timezone from Intl.DateTimeFormat().resolvedOptions().
   */
  const originalDatetime = DateTime.fromISO(isoString);

  /**
   * We override the browser locale with the lingui locale because
   * we are only supporting an explicit list of locales. This will affect the
   * formatting of the date and time. However we do not override the timezone
   * so we will still be using the Intl timezone and times will be shown in
   * the user's local time.
   */
  const localeDatetime = originalDatetime.setLocale(locale);

  /**
   * We create a string representation based on the format specified.
   * We are currently using the Luxon preset formats
   * https://moment.github.io/luxon/#/formatting?id=presets
   * which are wrappers around Intl configurations.
   * We are not using token-based formatting such as MM/DD/YY
   * which requires a separate function
   */
  const localeDatetimeString = localeDatetime.toLocaleString(format);

  return localeDatetimeString;
};

export const isoToLocaleMonth = (isoString: string) => {
  const { locale } = i18n;
  const originalDatetime = DateTime.fromISO(isoString);
  const localeDatetime = originalDatetime.setLocale(locale);
  const localeDatetimeString = localeDatetime.toLocaleString({ month: 'long' });
  return localeDatetimeString;
};

export const isoToLocaleYearMonth = (isoString: string) => {
  const { locale } = i18n;
  const originalDatetime = DateTime.fromISO(isoString);
  const localeDatetime = originalDatetime.setLocale(locale);
  const localeDatetimeString = localeDatetime.toLocaleString({
    year: 'numeric',
    month: 'long'
  });
  return localeDatetimeString;
};

export const isoToRelative = (isoString: string) => {
  /** Pull our locale from lingui */
  const { locale } = i18n;

  /**
   * Luxon automatically translates our datetime into the browser
   * locale and browser timezone from Intl.DateTimeFormat().resolvedOptions().
   */
  const originalDatetime = DateTime.fromISO(isoString);

  /**
   * We override the browser locale with the lingui locale because
   * we are only supporting an explicit list of locales. This will affect the
   * formatting of the date and time. However we do not override the timezone
   * so that when Luxon compares the datetime with the local time it will return
   * the proper time difference.
   */
  const localeDatetime = originalDatetime.setLocale(locale);

  /**
   * We create a string representation of the relative datetime difference
   * between the given datetime and now. This works for both times before and
   * after now.
   */
  const localeDatetimeRelativeString = localeDatetime.toRelative();

  return localeDatetimeRelativeString;
};

export const isoToDuration = (startIso: string, endIso: string) => {
  const { locale } = i18n;
  const startDate = DateTime.fromISO(startIso);
  const endDate = DateTime.fromISO(endIso);
  const duration = Interval.fromDateTimes(
    startDate,
    endDate
  ).toDuration() as unknown as { values: { milliseconds: number } };
  const milliseconds = duration?.values?.milliseconds ?? 0;
  /** This uses a separate package because Luxon's humanize is bad for now */
  const humanDuration = humanizeDuration(milliseconds, {
    language: locale,
    fallbacks: ['en'],
    maxDecimalPoints: 2,
    units: ['y', 'mo', 'w', 'd', 'h', 'm', 's']
  });
  return humanDuration;
};

export const isoToDateObject = (isoString: string, dateOnly = false) => {
  return dateOnly
    ? DateTime.fromISO(isoString).endOf('day').toJSDate()
    : DateTime.fromISO(isoString).toJSDate();
};

export const dateObjectToISO = (localeDatetime: Date, dateOnly = false) => {
  const original = DateTime.fromJSDate(localeDatetime);
  return dateOnly ? original.toISODate() : original.toISO();
};

export const isToday = (isoString: string) => {
  const original = DateTime.fromISO(isoString);
  return original.hasSame(DateTime.local(), 'day');
};
export const isYesterday = (isoString: string) => {
  const original = DateTime.fromISO(isoString);
  return original.hasSame(DateTime.local().minus({ days: 1 }), 'day');
};
export const isThisMonth = (isoString: string) => {
  const original = DateTime.fromISO(isoString);
  return original.hasSame(DateTime.local(), 'month');
};
export const isThisYear = (isoString: string) => {
  const original = DateTime.fromISO(isoString);
  return original.hasSame(DateTime.local(), 'year');
};
