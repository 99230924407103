import type { SupportedLocales } from './configure-locale';

type _Internal_Keymap = {
  binding: string | string[];
  label: string | null;
  ignoreAutorepeat?: boolean;
};
export type Keymap = {
  /** A locale of 'en-US' must be included as the fallback locale for all keybindings */
  'en-US': _Internal_Keymap;
} & {
  // eslint-disable-next-line no-unused-vars
  [locale in SupportedLocales]?: _Internal_Keymap;
};

/**
 * Does a lookup of a keymap but makes sure to fallback to the default locale of `en-US` so we
 * never run into situations where the binding label is not displayed for a specific locale
 */
export function getBindingWithFallback(
  keymap: Keymap,
  locale: SupportedLocales
) {
  // fallback to en-US if no locale-specific keybinding is defined
  const bindingAndLabel = keymap?.[locale] ?? keymap?.['en-US'] ?? {};
  return bindingAndLabel;
}

// task-processing scope
export const TOGGLE_TASK_PROCESSING_INFO_PANEL: Keymap = {
  // other locales can be set here if their keybindings are different, en-US is always used as a fallback
  'en-US': {
    binding: 'KeyI', // bindings must be defined in the order Control+Shift+Key
    label: 'I' // label can be a react node as well if we want to use an icon or something else
  }
};
export const FLAG_SUPPORT: Keymap = {
  'en-US': {
    binding: 'KeyH',
    label: 'H'
  }
};
export const UNFLAG_SUPPORT: Keymap = {
  'en-US': {
    binding: 'Control+KeyH',
    label: 'CTRL+H'
  }
};
export const PREV_TASK: Keymap = {
  'en-US': {
    binding: 'ArrowUp',
    label: '↑'
  }
};
export const NEXT_TASK: Keymap = {
  'en-US': {
    binding: 'ArrowDown',
    label: '↓'
  }
};
export const LEFT: Keymap = {
  'en-US': {
    binding: 'ArrowLeft',
    label: '←'
  }
};
export const RIGHT: Keymap = {
  'en-US': {
    binding: 'ArrowRight',
    label: '→'
  }
};
export const REROUTE: Keymap = {
  'en-US': {
    binding: 'Control+Shift+KeyX',
    label: 'CTRL+SHIFT+X'
  }
};

export const CROP_ACTIVE: Keymap = {
  'en-US': {
    binding: 'KeyC',
    label: 'C'
  }
};
export const STRAIGHTEN_ACTIVE: Keymap = {
  'en-US': {
    binding: 'KeyS',
    label: 'S'
  }
};
export const ROTATE_LEFT: Keymap = {
  'en-US': {
    binding: 'KeyA',
    label: 'A'
  }
};
export const ROTATE_RIGHT: Keymap = {
  'en-US': {
    binding: 'KeyD',
    label: 'D'
  }
};
export const ROTATE_180: Keymap = {
  'en-US': {
    binding: ['KeyW'],
    label: 'W'
  }
};
export const IS_BLANK: Keymap = {
  'en-US': {
    binding: 'KeyB',
    label: 'B',
    ignoreAutorepeat: true
  }
};
export const IS_NOT_BLANK: Keymap = {
  'en-US': {
    binding: 'KeyN',
    label: 'N',
    ignoreAutorepeat: true
  }
};

export const ESCAPE: Keymap = {
  'en-US': {
    binding: 'Escape',
    label: 'ESC'
  }
};

export const DELETE: Keymap = {
  'en-US': {
    // macos doesn't have an easily usable delete key, so we also have backspace do the same thing
    binding: ['Delete', 'Backspace'],
    label: 'Delete'
  }
};

export const SAVE_AND_UNSET_ACTIVE_CTRL_A: Keymap = {
  'en-US': {
    binding: 'Control+KeyA',
    label: 'CTRL+A'
  }
};
export const SAVE_AND_UNSET_ACTIVE_ENTER: Keymap = {
  'en-US': {
    binding: ['Enter', 'NumpadEnter'],
    label: '↩'
  }
};

// box-processing scope
export const MOVE_TO_NEXT_PAGE: Keymap = {
  'en-US': {
    binding: 'ArrowRight',
    label: '→'
  }
};

export const MOVE_TO_PREVIOUS_PAGE: Keymap = {
  'en-US': {
    binding: 'ArrowLeft',
    label: '←'
  }
};

export const MOVE_TO_NEXT_DOC: Keymap = {
  'en-US': {
    binding: 'ArrowDown',
    label: '↓'
  }
};

export const MOVE_TO_PREVIOUS_DOC: Keymap = {
  'en-US': {
    binding: 'ArrowUp',
    label: '↑'
  }
};

export const TOGGLE_BOX_PROCESSING_INFO_PANEL: Keymap = {
  'en-US': {
    binding: 'KeyI',
    label: 'I'
  }
};

export const TOGGLE_TOOLBAR: Keymap = {
  'en-US': {
    binding: 'KeyT',
    label: 'T'
  }
};

export const EXPAND_PAGE_IMAGE: Keymap = {
  'en-US': {
    binding: 'KeyE',
    label: 'E'
  }
};

export const TOGGLE_INFO_PANEL_TAB: Keymap = {
  'en-US': {
    binding: 'Tab',
    label: '⇥'
  }
};

export const COMMAND_AUTOCOMPLETE: Keymap = {
  'en-US': {
    binding: ['Tab', 'Space'],
    label: ''
  }
};

export const SELECT_ALL: Keymap = {
  'en-US': {
    binding: 'Control+KeyA',
    label: 'CTRL+A'
  }
};

export const SELECT_ALL_UNCLASSIFIED: Keymap = {
  'en-US': {
    binding: 'Control+KeyU',
    label: 'CTRL+U'
  }
};

export const SELECT_NONE: Keymap = {
  'en-US': {
    binding: 'Control+KeyN',
    label: 'CTRL+N'
  }
};

export const UP_ARROW_KEY: Keymap = {
  'en-US': { binding: 'ArrowUp', label: null, ignoreAutorepeat: true }
};
export const DOWN_ARROW_KEY: Keymap = {
  'en-US': { binding: 'ArrowDown', label: null, ignoreAutorepeat: true }
};

export const NUMERIC_KEYS: Keymap[] = [
  {
    'en-US': {
      binding: ['Digit1', 'Numpad1'],
      label: '1',
      ignoreAutorepeat: true
    }
  },
  {
    'en-US': {
      binding: ['Digit2', 'Numpad2'],
      label: '2',
      ignoreAutorepeat: true
    }
  },
  {
    'en-US': {
      binding: ['Digit3', 'Numpad3'],
      label: '3',
      ignoreAutorepeat: true
    }
  },
  {
    'en-US': {
      binding: ['Digit4', 'Numpad4'],
      label: '4',
      ignoreAutorepeat: true
    }
  },
  {
    'en-US': {
      binding: ['Digit5', 'Numpad5'],
      label: '5',
      ignoreAutorepeat: true
    }
  },
  {
    'en-US': {
      binding: ['Digit6', 'Numpad6'],
      label: '6',
      ignoreAutorepeat: true
    }
  },
  {
    'en-US': {
      binding: ['Digit7', 'Numpad7'],
      label: '7',
      ignoreAutorepeat: true
    }
  },
  {
    'en-US': {
      binding: ['Digit8', 'Numpad8'],
      label: '8',
      ignoreAutorepeat: true
    }
  },
  {
    'en-US': {
      binding: ['Digit9', 'Numpad9'],
      label: '9',
      ignoreAutorepeat: true
    }
  },
  {
    'en-US': {
      binding: ['Digit0', 'Numpad0'],
      label: '0',
      ignoreAutorepeat: true
    }
  }
];
