import React from 'react';

import { DATETIME_FORMATS } from '../../../config';
import { isoToLocale, isoToRelative } from '../../../utils/datetime-utils';

export const ReadOnlyDate = ({
  isoString,
  textFormat = DATETIME_FORMATS.DATETIME.SHORT,
  tooltipFormat = textFormat,
  withTooltip = true
}) => {
  const textDateTime =
    textFormat === DATETIME_FORMATS.RELATIVE
      ? isoToRelative(isoString)
      : isoToLocale(isoString, textFormat);
  const tooltipDateTime =
    tooltipFormat === DATETIME_FORMATS.RELATIVE
      ? isoToRelative(isoString)
      : isoToLocale(isoString, tooltipFormat);

  return (
    <span
      data-test-id="readOnlyDate"
      aria-label={withTooltip ? tooltipDateTime : null}
      role="text"
      title={withTooltip ? tooltipDateTime : null}>
      {textDateTime}
    </span>
  );
};

export default ReadOnlyDate;
