import React from 'react';
import styled, { css, keyframes } from 'styled-components';

export type ShimmerTextProps = {
  className?: string;
  numChars?: number;
  fillChar?: string;
};
export const ShimmerText = ({
  className,
  numChars = 2,
  fillChar = '\u00A0'
}: ShimmerTextProps) => {
  return (
    <StyledShimmerText className={className}>
      {new Array(numChars).fill(fillChar).join('')}
    </StyledShimmerText>
  );
};

export type ShimmerPlaceholderProps = {
  className?: string;
  type: 'iconText' | 'icon' | 'text' | 'input' | 'fill' | 'block';
  numRows?: number;
};
export default function ShimmerPlaceholder({
  className,
  type,
  numRows = 8
}: ShimmerPlaceholderProps) {
  switch (type) {
    case 'iconText':
      return (
        <FlexRowWrapper className={className}>
          <StyledShimmerBlock width="30px" height="30px" margin="10px 0px" />
          <FlexColWrapper>
            <StyledShimmerBlock
              width="calc(100% - 10px)"
              height="10px"
              margin="6px 5px"
            />
            <StyledShimmerBlock
              width="calc(75% - 10px)"
              height="9px"
              margin="6px 5px"
            />
          </FlexColWrapper>
        </FlexRowWrapper>
      );
    case 'icon':
      return (
        <StyledShimmerBlock
          className={className}
          width="24px"
          height="24px"
          margin="10px"
        />
      );
    case 'text':
      return (
        <div className={className}>
          <StyledShimmerBlock
            width="calc(100% - 10px)"
            height="10px"
            margin="10px"
          />
          <StyledShimmerBlock
            width="calc(75% - 10px)"
            height="9px"
            margin="10px"
          />
        </div>
      );
    case 'input':
      return (
        <InputShimmerContainer>
          {[...new Array(numRows)].map((_, i) => (
            <InputShimmer key={i} width={i % 2 === 0 ? '256px' : '192px'} />
          ))}
        </InputShimmerContainer>
      );
    case 'fill':
      return <StyledShimmerBlock width="100%" height="100%" margin="0" />;
    case 'block':
    default:
      return (
        <StyledShimmerBlock
          className={className}
          width="100%"
          height="15px"
          margin="10px"
        />
      );
  }
}

const shimmer = keyframes`
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
`;

const animatedShimmer = css`
  animation: ${shimmer} 2s infinite linear;
  background: ${({ theme }) =>
    `linear-gradient(45deg, ${theme.colors.gray[50]} 25%, ${theme.colors.primary[50]} 50%, ${theme.colors.gray[50]} 75%)`};
  background-size: 1000px 100%;
`;

const StyledShimmerBlock = styled.div<{
  width: string;
  height: string;
  margin: string;
  float?: string;
}>`
  ${animatedShimmer};
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  margin: ${({ margin }) => margin};
  float: ${({ float = 'none' }) => float};
`;

const FlexRowWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

const FlexColWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-evenly;
`;

const StyledShimmerText = styled.span`
  ${animatedShimmer};
  padding-top: 2px;
`;

const InputShimmerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
const InputShimmer = styled.div<{ width: string }>`
  ${animatedShimmer};
  display: flex;
  flex-direction: row;
  align-items: center;
  width: ${({ width }) => width};
  height: 16px;
`;
