import React from 'react';
import { t } from '@lingui/macro';
import { useDispatch } from 'react-redux';
import styled, { useTheme } from 'styled-components';

import { ROLES, tokenHasOneOfRoles } from '../../auth/keycloakSelectors';
import { OMS_OBJECT_LINKS } from '../../constants';
import { useConfirmModal } from '../../modals/components/confirm-modal/hooks';
import { MODAL_LEGACY_TAXONOMY } from '../../modals/constants';
import { deleteTaxonomy } from '../../oms/legacy-taxonomies/actions';
import { showModal } from '../../ui/actions';
import IconButton from '../../ui/components/icon-button';
import { getLinkFromOmsObject } from '../../utils/records/url';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;

  & svg {
    color: #979d9e !important;
  }
  & > div:hover svg {
    color: #50a1ab !important;
  }

  & > div > div > button > div {
    margin-top: -2px;
  }
`;

export default function LegacyTaxonomyManageActionsMenuButtons({ view }) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const openConfirmModal = useConfirmModal();

  const onDeleteClick = (e) => {
    e.stopPropagation();
    openConfirmModal({
      title: t`Are you sure you want to delete taxonomy view?`,
      text: t`Deleting a taxonomy view does not delete any documents, just the configuration of this view. The view can be recreated in the future.`,
      onConfirm: () => {
        dispatch(deleteTaxonomy(view.id));
      }
    });
  };

  const onEditClick = (e) => {
    e.stopPropagation();
    dispatch(showModal(MODAL_LEGACY_TAXONOMY, { view }));
  };

  return (
    <Wrapper>
      {tokenHasOneOfRoles([ROLES.TENANT_ADMIN, ROLES.CUSTOMER_ADMIN]) &&
        !!getLinkFromOmsObject(view, OMS_OBJECT_LINKS.EDIT) && (
          <IconButtonWrapper>
            <IconButton
              data-test-id="taxonomy-edit-icon"
              title={t`Edit taxonomy`}
              iconSrc={theme.assets.icons.rename}
              onClick={onEditClick}
            />
          </IconButtonWrapper>
        )}
      {tokenHasOneOfRoles([ROLES.TENANT_ADMIN, ROLES.CUSTOMER_ADMIN]) &&
        !!getLinkFromOmsObject(view, OMS_OBJECT_LINKS.DELETE) && (
          <div>
            <IconButton
              data-test-id="taxonomy-delete-icon"
              p
              title={t`Delete taxonomy`}
              iconSrc={theme.assets.icons.deleteIcon}
              onClick={onDeleteClick}
            />
          </div>
        )}
    </Wrapper>
  );
}

const IconButtonWrapper = styled.div`
  width: 18px;
  height: 18px;
  padding-top: 3px;
  margin-right: 8px;
`;
