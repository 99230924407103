import {
  AND,
  AND_VERBOSE,
  EQ,
  GE,
  GE_VERBOSE,
  getSelector,
  getSingleValue,
  getValue,
  GT,
  GT_VERBOSE,
  IN,
  isComparisonNode,
  isLogicNode,
  isValueNode,
  LE,
  LE_VERBOSE,
  LT,
  LT_VERBOSE,
  NEQ,
  OR,
  OR_VERBOSE,
  OUT
} from '@rsql/ast';
import { emit } from '@rsql/emitter';
import { parse } from '@rsql/parser';

import type {
  ComparisonNode,
  ComparisonOperator,
  ExpressionNode,
  LogicNode
} from '@rsql/ast';
import type { OMSObject } from '../oms/tsTypes';

import { RipcordASTError } from '../errors';
import builder, {
  ILIKE,
  LIKE,
  SEQ,
  SNE,
  UNILIKE,
  UNLIKE
} from './rsql-builder';

export { parse } from '@rsql/parser';
export { default as builder } from './rsql-builder';
export { emit } from '@rsql/emitter';

export type MaybeExpressionNode = ExpressionNode | null | undefined;

/** a safer version of emit that allows null/undefined expressions to be passed and will emit undefined in that case */
export function maybeEmit(builder: ExpressionNode | undefined | null) {
  try {
    return emit(builder!);
  } catch (e) {
    return undefined;
  }
}

/**
 * a safer version of parse that allows null/undefined expressions to be passed and will return null in that case
 */
export function maybeParse(expression: string | undefined | null) {
  return expression ? parse(expression) : null;
}

export const OMS_OPERATORS = [
  EQ,
  NEQ,
  LE,
  GE,
  LT,
  GT,
  LE_VERBOSE,
  GE_VERBOSE,
  LT_VERBOSE,
  GT_VERBOSE,
  IN,
  OUT,
  ILIKE,
  UNILIKE,
  LIKE,
  UNLIKE,
  SEQ,
  SNE
] as const;
export type PossibleOMSOperators = (typeof OMS_OPERATORS)[number];

export function getOperator(node: ComparisonNode): ComparisonOperator {
  return node.operator;
}
export function isLogicalOrNode(node: MaybeExpressionNode): node is LogicNode {
  return isLogicNode(node, OR) || isLogicNode(node, OR_VERBOSE);
}
export function isLogicalAndNode(node: MaybeExpressionNode): node is LogicNode {
  return isLogicNode(node, AND) || isLogicNode(node, AND_VERBOSE);
}

/**
 * gets a single attribute name from a parsed filter expression node.
 * If the node is a logic node, it will return the attribute from the left side of the node.
 */
export function getAttributeNameFromNode(node: ExpressionNode): string {
  if (isLogicNode(node)) {
    return getAttributeNameFromNode(node.left);
  } else if (isComparisonNode(node)) {
    return getSelector(node);
  } else {
    throw new RipcordASTError({ message: 'Invalid filter expression', node });
  }
}

/**
 * Returns a tuple of the lower and upper bounds of an OMS filters string. If the range only has one
 * comparson, the other one will be null.
 * In the returned tuple, left side is always the lower bound, right side is always the upper bound.
 * returns the stringified version of the values!
 */
export function getRangeFilterBounds(
  node: MaybeExpressionNode
): [string | null, string | null] {
  let comparisonNodes: ExpressionNode[] = [];

  if (node != null) {
    comparisonNodes = filterLogicBranch(
      AND,
      node,
      isComparisonNode
    ) as ExpressionNode[];
  }

  const rangeBounds: [string | null, string | null] = [null, null];
  for (const comparisonNode of comparisonNodes) {
    switch (comparisonNode.operator) {
      case GE:
      case GE_VERBOSE:
      case GT:
      case GT_VERBOSE:
        rangeBounds[0] = getSingleValue(comparisonNode);
        break;
      case LE:
      case LE_VERBOSE:
      case LT:
      case LT_VERBOSE:
        rangeBounds[1] = getSingleValue(comparisonNode);
        break;
      default:
        throw new RipcordASTError({
          message: 'Invalid filter expression, unsupported operator used',
          node: comparisonNode
        });
    }
  }

  return rangeBounds;
}

/**
 * Walks the branch of AND or OR logic nodes in a filter expression AST, calling the given visitor
 * function for every comparison node it finds.
 *
 * @param node The node of the filter expression AST.
 * @param visitor The function to call for each comparison node in the AST.
 */
export function walkLogicBranch(
  operator: typeof OR | typeof AND,
  node: MaybeExpressionNode,
  visitor: (node: MaybeExpressionNode) => unknown
): void {
  if (operator === OR ? isLogicalOrNode(node) : isLogicalAndNode(node)) {
    walkLogicBranch(operator, (node as LogicNode).left, visitor);
    walkLogicBranch(operator, (node as LogicNode).right, visitor);
  } else {
    visitor(node);
  }
}

/**
 * Acts like a filter but for a specific branch of logic nodes in a filter expression AST.
 */
export function filterLogicBranch(
  operator: typeof OR | typeof AND,
  node: MaybeExpressionNode,
  filter: (node: MaybeExpressionNode) => boolean
): MaybeExpressionNode[] {
  const filteredNodes: MaybeExpressionNode[] = [];
  walkLogicBranch(operator, node, (node) => {
    if (filter(node)) {
      filteredNodes.push(node);
    }
  });
  return filteredNodes;
}

/**
 * Acts like a .find returning the first node that matches the filter in a specific branch of logic
 * nodes in a filter expression AST.
 */
export function findLogicBranch(
  operator: typeof OR | typeof AND,
  node: MaybeExpressionNode,
  filter: (node: MaybeExpressionNode) => boolean
): MaybeExpressionNode | null {
  // yeah this could be more efficient, but it's not worth it for the sizes this will run on
  return filterLogicBranch(operator, node, filter)?.[0] ?? null;
}

/**
 * Returns if the given node is an "unspecified" expression. Because strings use a more complex
 * expression to represent the idea of an "unspecified", we need this function to check for them.
 */
export function isUnspecifiedExpression(
  node: ExpressionNode
): node is LogicNode {
  return (
    // for normal attribute==null expressions
    (isComparisonNode(node, EQ) && getSingleValue(node) === 'null') ||
    // for string attributes which look like (attribute==""||attribute==null)
    (isLogicalOrNode(node) &&
      isComparisonNode(node.left, EQ) &&
      isComparisonNode(node.right, EQ) &&
      // check (attribute==""||attribute==null)
      ((getSingleValue(node.left) === '' &&
        getSingleValue(node.right) === 'null') ||
        // check (attribute==null||attribute=="")
        (getSingleValue(node.left) === 'null' &&
          getSingleValue(node.right) === '')))
  );
}

/**
 * This function takes in a variable number of string filters, parses them, and
 * returns a single filter that is the AND of all of the parsed filters. If any
 * of the filters are invalid, they are ignored. If none of the filters are
 * valid, then the function returns the empty string.
 * Note: filters that have multiple values (see: ViewCollectionPage) should use emit syntax and place them as queryParams: { advancedFilters: emit(...) }
 */
export function andTogetherStringFilters(
  ...filters: Array<string | undefined | null>
): string {
  const parsedFilters = filters.flatMap((filter) => {
    try {
      if (filter == null) {
        return [];
      } else {
        return [parse(filter)];
      }
    } catch (e) {
      return [];
    }
  });
  if (parsedFilters.length) {
    return emit(builder.and(...parsedFilters));
  } else {
    return '';
  }
}

/**
 * Used to apply a filter to a single oms object client-side and get a boolean result on if it
 * matches the filter or not.
 */
export const compareAstToOmsObject = (ast: string, omsObject: OMSObject) => {
  const comparisonNodes = recursiveExtractComparisonNodes(parse(ast));
  const result = comparisonNodes.every((node) => {
    const value = getValue(node);
    const selector = getSelector(node);
    switch (node.operator) {
      case IN:
        return (value as string[]).some((item) =>
          compareOmsObjectToValue(selector, item, omsObject)
        );
      case OUT:
        return !(value as string[]).some((item) =>
          compareOmsObjectToValue(selector, item, omsObject)
        );
      case ILIKE:
        return ilikeCompareOmsObjectToValue(selector, value, omsObject);
      case LIKE:
        return likeCompareOmsObjectToValue(selector, value, omsObject);
      case NEQ:
        return !compareOmsObjectToValue(selector, value, omsObject);
      case EQ:
      default:
        return compareOmsObjectToValue(selector, value, omsObject);
    }
  });
  return result;
};

export const filtersStringToObject = (filtersString: string) => {
  if (filtersString) {
    // parse the filters string and walk the tree to collect all of the filters
    const ast = parse(filtersString);
    const comparisonNodes = recursiveExtractComparisonNodes(ast);

    // then re-combine it into our frontend format (an object of filters keyed by the attribute name)
    return comparisonNodes.reduce(
      (filtersObject: { filters: string }, comparisonNode: ComparisonNode) => {
        const complexFilter = filtersObject?.[getSelector(comparisonNode)];
        if (!complexFilter) {
          filtersObject[getSelector(comparisonNode)] = {
            operator: comparisonNode.operator,
            value: getValueBasedOnOperator(comparisonNode)
          };
        } else {
          /**
           * The only complex filter we are handling right now is date ranges
           * This will likely need to be refactored when we want to start handling
           * other filters that span multiple comparions.
           * We assume that valid date ranges will come with the start of the range
           * comparison node first and the end of the range comparison node next, since
           * that is how we inserted them.
           */
          const isDateRange =
            complexFilter?.operator === GT && comparisonNode?.operator === LT;
          if (isDateRange) {
            filtersObject[getSelector(comparisonNode)] = {
              operator: 'DATERANGE',
              value: `${complexFilter?.value} - ${getValue(comparisonNode)}`
            };
          }
        }
        return filtersObject;
      },
      {}
    );
  }
};

export const filtersObjectToString = (
  filtersObject: Record<string, { operator: string; value: string }> = {}
) => {
  if (Object.keys(filtersObject).length === 0) {
    return undefined;
  } else {
    const expression = Object.entries(filtersObject)
      .map(
        ([attributeName, { operator, value }]: [
          string,
          { operator: string; value: any }
        ]) => buildValueBasedOnOperator(attributeName, operator, value)
      )
      .reduce((ast: ExpressionNode | null, node: ComparisonNode) => {
        const isNonEmptyArray = Array.isArray(node) && !!node?.length;
        if (ast === null) {
          return isNonEmptyArray
            ? builder.and(...node)
            : !Array.isArray(node)
            ? node
            : ast;
        } else {
          return isNonEmptyArray
            ? builder.and(...node, ast)
            : !Array.isArray(node)
            ? builder.and(node, ast)
            : ast;
        }
      }, null);
    return !!expression ? emit(expression) : undefined;
  }
};

export const addFilter = (
  filtersObject = {},
  attributeName: string,
  operator: string,
  value: string
) => {
  return {
    ...filtersObject,
    [attributeName]: {
      operator,
      value
    }
  };
};

export const removeFilter = (
  filtersObject: any = {},
  attributeName: string
) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { [attributeName]: filterToRemove, ...restOfFilters } = filtersObject;
  return restOfFilters;
};

export const modifyFilterProperty = (
  filtersObject = {},
  attributeName: string,
  propertyName: string,
  value: string
) => {
  if (filtersObject[attributeName]) {
    filtersObject[attributeName][propertyName] = value;
  }
  return filtersObject;
};

export const removeArrayOfFilters = (
  filtersObject: { filters: string },
  filtersToRemove: string[]
) => {
  if (!filtersObject?.filters || filtersToRemove?.length === 0) {
    return filtersObject;
  }

  const filtersStringAsObject = filtersStringToObject(filtersObject.filters);
  const currentFilters = Object.keys(filtersStringAsObject);

  for (const filter of currentFilters) {
    if (filtersToRemove?.includes(filter.split('.')[0])) {
      delete filtersStringAsObject[filter];
    }
  }

  return {
    ...filtersObject,
    filters: filtersObjectToString(filtersStringAsObject)
  };
};

function recursiveExtractComparisonNodes(ast: ExpressionNode) {
  if (isLogicNode(ast)) {
    return [
      ...recursiveExtractComparisonNodes(ast.left),
      ...recursiveExtractComparisonNodes(ast.right)
    ];
  } else if (isComparisonNode(ast)) {
    switch (getValue(ast)) {
      case 'true':
        return [overwriteASTValue(ast, true)];
      case 'false':
        return [overwriteASTValue(ast, false)];
      default:
        return [ast];
    }
  }
}

/**
 * getValueBasedOnOperator and buildValueBasedOnOperator must do the same thing but opposite in
 * each function.
 *
 * If you change something in one of them, you need to "undo" that change in the other!
 */

// We can build this out for custom operators not supported by @rsql/builder out of the box and that require special formatting
const getValueBasedOnOperator = (comparisonNode: ComparisonNode) => {
  switch (comparisonNode.operator) {
    case IN:
    case OUT:
      return getValue(comparisonNode);
    case ILIKE:
      return (getValue(comparisonNode) as string)?.split('*')[1];
    default:
      return getValue(comparisonNode);
  }
};

// We can build this out for custom operators not supported by @rsql/builder out of the box and that require special formatting
const buildValueBasedOnOperator = (
  attributeName: string,
  operator: string,
  rawValue: any
) => {
  const value = [IN, OUT].includes(operator as any)
    ? rawValue.map((val) => `${val}` ?? val)
    : `${rawValue}`;

  switch (operator) {
    case ILIKE:
      return builder.icontains(attributeName, value);
    case 'DATERANGE': {
      const isoDateRange = !!value ? value?.split(' ', 3) : null;
      const startDate = isoDateRange?.[0];
      const endDate = isoDateRange?.[2];
      const rangeStartExpression =
        !!startDate && startDate !== 'null'
          ? builder.gt(attributeName, startDate)
          : null;
      const rangeEndExpression =
        !!endDate && endDate !== 'null'
          ? builder.lt(attributeName, endDate)
          : null;
      return [rangeStartExpression, rangeEndExpression].filter((x) => !!x);
    }
    default:
      return builder.comparison(attributeName, operator, value);
  }
};

const compareOmsObjectToValue = (
  selector: string,
  value: any,
  omsObject: OMSObject
) => {
  return (
    omsObject?.[selector] === value ??
    omsObject?.attributes?.[selector] === value
  );
};

const likeCompareOmsObjectToValue = (
  selector: string,
  value: any,
  omsObject: OMSObject
) => {
  return (
    omsObject?.[selector]?.includes(value) ??
    (omsObject?.attributes?.[selector] as object[])?.includes(value)
  );
};

const ilikeCompareOmsObjectToValue = (
  selector: string,
  value: any,
  omsObject: OMSObject
) => {
  const lcValue = value.toLowerCase();
  return (
    omsObject?.[selector]?.toLowerCase()?.includes(lcValue) ??
    (omsObject?.attributes?.[selector] as string)
      ?.toLowerCase()
      ?.includes(lcValue)
  );
};

/**
 * Needed because the builder doesn't allow us to set `true` or `false` as the value of a comparison node without converting it to a string
 */
function overwriteASTValue(ast: ComparisonNode, newValue: any) {
  return {
    ...ast,
    left: {
      ...ast.left,
      value: isValueNode(ast.left) ? newValue : (ast.left as any).value
    },
    right: {
      ...ast.right,
      value: isValueNode(ast.right) ? newValue : (ast.right as any).value
    }
  };
}
