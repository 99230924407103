import { t } from '@lingui/macro';

import type { ValueOf } from './utils/tsUtilityTypes';

/** Keycloak Realm Types */
export const REALM_TYPE = {
  PARTNER: 'partner',
  TENANT: 'tenant'
} as const;
export type PossibleRealmTypes = ValueOf<typeof REALM_TYPE>;

// Categories of object OMS
export const CATEGORIES = {
  CONTAINER: 'Container',
  CONTENT: 'Content',
  ENTITY: 'Entity'
} as const;
// short term solution to utilize category as a picklist until we find a better solution
// system-wide to handle requesting facets
export const CATEGORY_OPTIONS = Object.values(CATEGORIES).map((category) => ({
  label: category,
  value: category
}));

// Processing priorities of object OMS
export const PROCESSING_PRIORITIES = {
  LARGE_CONTENT: 'large-content',
  BULK: 'bulk',
  HIGH_PRIORITY_BATCH: 'high-priority-batch',
  INTERACTIVE: 'interactive'
} as const;

export const PROCESSING_STATES_KEYS = {
  TEXT_EXTRACTION: 'textExtraction',
  SCANNED_PAGE_WORKFLOW: 'scannedPageWorkflow',
  SCANNED_BOX_CASE: 'scannedBoxCase'
} as const;

export const PROCESSING_STATES = {
  DELIVERED: 'delivered',
  DONE: 'done',
  ERRORED: 'error',
  EXPORTING: 'exporting',
  IMPORTING: 'importing',
  NONE: 'none',
  PROCESSING: 'processing',
  STARTING: 'start'
} as const;

// Ingestion methods, internal attribute on an object
export const INGESTION_METHODS = {
  RIPCORD_SCANNED: 'ripcord-scanned',
  USER_UPLOAD: 'user-upload',
  WEB_SCAN: 'web-scan',
  SFTP: 'sftp',
  UNKNOWN: 'unknown'
} as const;

// used in the Data Models tab of Admin Console
export const DATA_MODELS = {
  AUTOFILL_DEFINITIONS: 'autofill-definitions',
  LOOKUP_DEFINITIONS: 'lookup-definitions',
  LOOKUP_OBJECTS: 'lookup-objects',
  TRAIT_DEFINITIONS: 'trait-definitions',
  TYPE_DEFINITIONS: 'type-definitions'
} as const;

// Subset of renditionTypes that the frontend cares about. Updated Oct 21, 2021
export const RENDITION_TYPES = {
  THUMBNAIL: 'Thumbnail',
  ORIGINAL: 'Original',
  TEXT: 'Text'
} as const;
export type PossibleKnownRenditionTypes =
  | ValueOf<typeof RENDITION_TYPES>
  | string;

// Well known _links found on OMS Renditions
export const OMS_OBJECT_LINKS = {
  ACL: 'acl',
  ADD_DESCENDANTS: 'add-descendants',
  ADD_RELATIONS: 'add-relations',
  ADD_VERSIONS: 'add-versions',
  ANCESTORS: 'ancestors',
  DELETE: 'delete',
  DESCENDANTS: 'descendants',
  EDIT: 'edit',
  EDIT_OWNERS: 'edit-owners',
  EDIT_PERMISSIONS: 'edit-permissions',
  EDIT_TYPE: 'edit-type',
  MOVE: 'move',
  OBJECT: 'object',
  RELATIONSHIPS: 'relationships',
  RENDITIONS: 'renditions',
  SELF: 'self',
  TAGS: 'tags'
} as const;
export type PossibleKnownObjectLinks = ValueOf<typeof OMS_OBJECT_LINKS>;

export const RENDITION_LINKS = {
  PRINT: 'print',
  DOWNLOAD: 'download'
} as const;
export type PossibleRenditionLinks = ValueOf<typeof RENDITION_LINKS>;

export const OMS_NUMBER_DATA_TYPES = {
  INTEGER: 'INTEGER',
  DOUBLE: 'DOUBLE',
  LONG: 'LONG',
  FLOAT: 'FLOAT'
} as const;

export const OMS_TEXT_DATA_TYPES = {
  UUID: 'UUID',
  STRING: 'STRING',
  TEXT: 'TEXT'
} as const;

export const OMS_DATE_DATA_TYPES = {
  DATE: 'DATE',
  DATETIME: 'DATETIME'
} as const;

export const OMS_PICKLIST_ISH_DATA_TYPES = {
  PICK_LIST: 'PICK_LIST',
  BOOLEAN: 'BOOLEAN'
} as const;

export const OMS_CODE_DATA_TYPES = {
  QUERY_FILTER: 'QUERY_FILTER',
  PROPERTY_BAG: 'PROPERTY_BAG'
} as const;

export const OMS_DATA_TYPES = {
  ...OMS_NUMBER_DATA_TYPES,
  ...OMS_TEXT_DATA_TYPES,
  ...OMS_DATE_DATA_TYPES,
  ...OMS_PICKLIST_ISH_DATA_TYPES,
  ...OMS_CODE_DATA_TYPES
} as const;

export const SPECIAL_RENDITION_MIME_TYPES = {
  COMPOSITE: 'application/vnd.ripcord.composite',
  INDIRECT: 'application/vnd.ripcord.relationship',
  DTL: 'application/vnd.ripcord.document-text-layout'
} as const;

export const WELL_KNOWN_ROLES = {
  // global
  GLOBAL_ADMIN: 'global-admin',
  TENANT_PROVISIONER: 'tenant-provisioner',
  // tenant level
  TENANT_ADMIN: 'tenant-admin',
  CUSTOMER_ADMIN: 'customer-admin',
  ACCOUNT_ADMIN: 'account-admin',
  AUDIT_MANAGER: 'audit-manager',
  AUDIT_USER: 'audit-user',
  OPS_MANAGER: 'ops-manager',
  OPS_ASSOCIATE: 'ops-associate',
  DOCUFAI: 'docufai',
  DOCUFAI_PLUS: 'docufai-plus'
} as const;
export type PossibleKnownRoles = ValueOf<typeof WELL_KNOWN_ROLES>;

export const NOTEBOOK_ENTRY_TYPES = {
  BASE_FALLBACK_TYPE: 'rc_notebook_entry',
  SINGLE_QA: 'rc_ne_question_answer',
  MULTI_QA: 'rc_ne_qa_multi_doc',
  SUMMARIZE: 'rc_ne_summarize',
  LIBRARIAN_SUMMARIZE: 'rc_ne_lib_summarize',
  LIBRARIAN_QA: 'rc_ne_lib_qa',
  MANUAL_NOTE: 'rc_ne_manual_note',
  DEEP_DIVE: 'rc_ne_deepdive'
} as const;
export type PossibleNotebookEntryTypes = ValueOf<typeof NOTEBOOK_ENTRY_TYPES>;

/** internal types aren't shown in the UI in most normal areas (unless they're flagged on) */
export const INTERNAL_TYPES = {
  NOTEBOOK: 'rc_notebook',
  NOTEBOOK_ENTRY: 'rc_notebook_entry',
  COLLECTION: 'rc_collection',
  TAXONOMY: 'rc_taxonomy',
  SAVED_SEARCH: 'rc_saved_search',
  USER_DATA: 'rc_user_data',
  MERGE_REQUEST_ENTITY: 'rc_merge_request_entity'
} as const;

// OMS base types (AKA System types)
export const BASE_TYPE = {
  BATCH: 'rc_batch',
  BINDER_CLIP: 'rc_binder_clip',
  BOX: 'rc_box',
  COLLECTION_DETAILS_PAGE: 'rc_collection_details_page',
  DOCUMENT: 'rc_document',
  ENVELOPE: 'rc_envelope',
  FASTENER: 'rc_fastener',
  FOLDER: 'rc_folder',
  HARD_DRIVE: 'rc_hard_drive',
  MANILLA_FOLDER: 'rc_envelope_manilla',
  PAGE: 'rc_page',
  PAPER_CLIP: 'rc_paper_clip',
  PHYSICAL_FOLDER: 'rc_physical_folder',
  POINTER: 'rc_pointer',
  POINTER_BATCH: 'rc_pointer_batch',
  POINTER_EXCEPTION: 'rc_pointer_exception',
  RING_BINDER: 'rc_ring_binder',
  RUBBER_BAND: 'rc_rubber_band',
  SEPARATOR: 'rc_separator',
  SHEET: 'rc_sheet',
  SOURCE_CONTAINER: 'rc_source_container',
  SPLIT_PIN: 'rc_split_pin',
  STAPLE: 'rc_staple',
  HOME_FOLDER: 'rc_home_folder',
  ...INTERNAL_TYPES,
  ...NOTEBOOK_ENTRY_TYPES
} as const;

// enrichment-specific types for creation of classification objects and relationships
export const ML_TYPES = {
  DOCUMENT_CLASSIFICATION: 'ml_document_classification',
  NAMED_ENTITY_CLASSIFICATION: 'ml_named_entity_classification'
} as const;
export const ML_ATTRIBUTE_TYPES = {
  ENTITY_TYPE: 'entity_type'
} as const;

// well known OMS object relationships
export const RELATIONSHIP_TYPES = {
  SRC_LIB_CONTAINER: 'rc_source_library_container_relationship',
  ASSEMBLED_DOC: 'rc_assembled_document_relationship',
  DOCUMENT_CLASSIFICATION: 'ml_document_classification_relationship',
  DOCUMENT_CLASSIFICATION_NAMED_ENTITY:
    'ml_document_classification_named_entity_relationship',
  PAGE_NAMED_ENTITY: 'ml_page_named_entity_relationship',
  COLLECTION_CONTENT: 'rc_collection_content_relationship',
  DERIVED_DOC: 'rc_derived_document_relationship',
  NOTEBOOK_ENTRY_CITATION: 'rc_ne_citation',
  NOTEBOOK_DOCUMENT_RELATIONSHIP: 'rc_notebook_doc_relationship',
  NOTEBOOK_LIBRARIAN_ANSWER: 'rc_ne_lib_answer'
} as const;
export type PossibleRelationshipTypes = ValueOf<typeof RELATIONSHIP_TYPES>;

// mufg-specific types that we need to treat differently for various reasons
export const MUFG_TYPES = {
  PAGE: 'mufg_page',
  SLEEVE: 'mufg_sleeve',
  SMALL_BOX: 'mufg_small_box',
  HANKO_DOCUMENT: 'mufg_hanko_document',
  COLLECTION_DETAILS_PAGE: 'mufg_collection_details_page'
} as const;

// OMS labels for the relationship IDs
export const RELATIONSHIP_LINKS = {
  DOCUMENT_ID: 'sourceObjectId',
  PAGE_ID: 'targetObjectId'
} as const;

// OMS reserved tag names
export const RESERVED_TAGS = {
  FAVORITE: 'rc_favorite'
} as const;
export type PossibleReservedTags = ValueOf<typeof RESERVED_TAGS>;

// OMS object scan states
export const SCANSTATE = {
  SCANNED: 'RawSaved',
  RESCAN: 'RescanNeeded'
} as const;

export const LOCAL_DEV_URL = 'local.ripcordops.com';

// how long to wait between retries where they are used by default
export const DEFAULT_RETRY_DELAY_MS = 150;
// how many times to retry by default
export const DEFAULT_NUM_RETRIES = 2;

export const PICK_LIST_OPTION_KEY_DISPLAY_LABEL = 'label';
export const PICK_LIST_OPTION_KEY_VALUE = 'value';

export const HARDCODED_CLASSES = {
  CONTEXT_MENU: 'context-menu'
} as const;

export const APP_IDS = {
  CANOPY: 'canopy',
  DOCUFAI_PLUS: 'docufai-plus',
  PARACHUTE: 'parachute',
  ADMIN_CONSOLE: 'admin-console'
} as const;

export const PERSONAL_LABEL =
  process.env.APP_NAME === APP_IDS.DOCUFAI_PLUS ? t`My files` : t`Personal`;

export const IDLE_TIMEOUT = 3000;

export const VERSIONS_LIMIT = 100;

// helper functions
export const isNumberType = (attributeType) => {
  const numberTypesArray = Object.values(OMS_NUMBER_DATA_TYPES);
  return numberTypesArray.includes(attributeType);
};

export const isNumber = (value) => typeof value === 'number' && !isNaN(value);

export const USER_FILTER_FIELDS = {
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  EMAIL: 'email'
} as const;

export const LARGE_FILE_DOWNLOAD_THRESHOLD = 524_288_000; // 500MB

export type WebScanAttributes = {
  scannedVia: 'web-scan';
  scannerManufacturer: string;
  scannerModel: string;
  browser: string;
  twainDriverVersion: string;
  dynamsoftVersion: string;
};

// -------- BELOW THIS LINE IS LEGACY STUFF ------------------------------------

export const ICON_COMPLETED = 'completed';
