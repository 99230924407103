import React from 'react';
import { t } from '@lingui/macro';
import {
  LeftTick,
  LoadingThrobber,
  MiddleTick,
  RightTick
} from './index.styled';

export type Props = {
  relative?: boolean;
  className?: string;
  /** if true, 3 "blinking" dots will be used instead of the throbbing bars */
  useDots?: boolean;
};
export default function ProgressIndicator({
  className,
  relative,
  useDots = false,
  ...restProps
}: Props) {
  return (
    <LoadingThrobber
      className={className}
      title={t`Loading...`}
      relative={relative}
      {...restProps}>
      <LeftTick $isDot={useDots} />
      <MiddleTick $isDot={useDots} />
      <RightTick $isDot={useDots} />
    </LoadingThrobber>
  );
}
