import React, { Suspense, useEffect, useMemo, useRef } from 'react';

import ProgressIndicator from './index';

export type Props = {
  relative?: boolean;
  isLoading?: boolean;
  children: React.ReactNode;
};
/**
 * This component is used allow easy bundle splitting. If any children components are "suspended"
 * (meaning not ready to render, currently only used for React.lazy right now) then the fallback
 * component will be shown after a short timeout until the component loads.
 */
export default function SuspenseThrobber({
  relative,
  isLoading,
  children
}: Props) {
  return (
    <Suspense fallback={<ProgressIndicator relative={relative} />}>
      {/* @ts-ignore */}
      <PossiblyLoadingChildren isLoading={isLoading}>
        {children}
      </PossiblyLoadingChildren>
    </Suspense>
  );
}

/** This component is a hack for some current shortfalls of react suspense- throwing a promise while loading allows us to manually trigger the suspense fallback.
 * This allows us to not need a separate loading handler for isLoading so that there is less of a loader -> null -> loader ui flash
 */
const PossiblyLoadingChildren = ({
  isLoading,
  children
}: {
  isLoading?: boolean;
  children: React.ReactNode;
}) => {
  const ref = useRef<any>();
  const promise = useMemo(() => {
    return new Promise((resolve) => {
      ref.current = resolve;
    });
  }, []);

  useEffect(() => {
    if (!isLoading) {
      ref?.current?.();
    }
    return () => {
      ref?.current?.();
    };
  }, [isLoading, ref]);

  if (isLoading) {
    throw promise;
  }

  return children;
};
