// this is the only ts file allowed to import useSelector
/* eslint-disable @typescript-eslint/no-restricted-imports */
import { useSelector as useSelectorReactRedux } from 'react-redux';

import type { EqualityFn, NoInfer, TypedUseSelectorHook } from 'react-redux';
import type { AnyAppRootState } from './tsTypes';

export { useDispatch, useSelector } from 'react-redux';

/**
 * This nightmare interface makes it so that the return type of the given function
 * will always be an object even if the selector passed in is nullish.
 *
 * It essentially sets all the properties of the returned object to "possibly undefined"
 * which makes it easier to write selectors for redux modules which aren't in every app
 * without providing default values.
 */
interface TypedUseSelectorHookWithDefaultObj<TState> {
  <TSelected>(
    selector: (state: TState) => TSelected,
    equalityFn?: EqualityFn<NoInfer<TSelected>>
  ): Extract<TSelected, undefined> extends never
    ? TSelected
    : Partial<NonNullable<TSelected>>;
}

/**
 * This is the selector we should use in most cases. It just adds inferred type definitions to
 * the return value of useSelector so you don't need to do anything to use the types!
 */
export const useTypedSelector: TypedUseSelectorHook<AnyAppRootState> =
  useSelectorReactRedux;

/**
 * When destructuring the result of useTypedSelector with a "potentially undefined" redux module,
 * you can use this selector instead to make it a little easier to work with.
 * Example:
 *
 * const { someInnerProperty } = useTypedDestructuredSelector((state) => state.somePossiblyUndefinedModule?.someProperty);
 */
export const useTypedDestructuredSelector: TypedUseSelectorHookWithDefaultObj<
  AnyAppRootState
> = (selector) => useSelectorReactRedux(selector) ?? ({} as any);
