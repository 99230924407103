import { useEffect } from 'react';
import { getUserUUID, getMixpanelInfo } from '../auth/keycloakSelectors';
import { useAccountData } from '../account/hooks';
import mixpanel from 'mixpanel-browser';
import timber from '../timber/macro';
import { getCurrentLocale } from '../translations/configure-locale';

export function useInitMixpanel() {
  const { isLoading, account } = useAccountData();

  useEffect(() => {
    if (!isLoading && account?.id) {
      timber.debug('Setting up mixpanel session');

      try {
        const { superPropertyPayload, mixpanelPayload } = getMixpanelInfo({
          tenantId: account?.id
        });

        // This is where we set the Super Properties on the Mixpanel instance,
        // which associates this user with the events tracked in this session.
        mixpanel.register(superPropertyPayload);

        mixpanel.identify(getUserUUID());
        mixpanel.people.set_once('New Session - First', new Date());
        mixpanel.people.set('New Session - Last', new Date());
        mixpanel.people.increment('New Session - Count', 1);
        mixpanel.people.set(
          'Is Docufai User',
          superPropertyPayload['Is Docufai User']
        );
        const locale = getCurrentLocale(); // this couldn't be added in getMixpanelInfo because it caused storybook builds to fail due to importing the locale config file

        // Next, the usual tracking.
        track('New Session', { ...mixpanelPayload, Language: locale });
      } catch (e) {
        timber.error('Error setting up mixpanel session: ', e);
      }
    }
  }, [account?.id, isLoading]);
}

export function track(event: string, payload?: object) {
  try {
    const eventPayload = {
      ...payload,
      $distinct_id: getUserUUID()
    };
    if (!!sessionStorage.getItem('logDontTrack')) {
      timber.info('Mixpanel Event: ', event, eventPayload);
    } else {
      mixpanel.track(event, eventPayload);
    }
  } catch (e) {
    timber.error('Error tracking mixpanel event: ', e);
  }
}

/**
 * Calling this function from the browser console will store a session variable to log all
 * tracking instead of sending it to Mixpanel for easy development.
 *
 * It will persist through a refresh, but if you fully close the tab and open a new one it should have a new session and go back to normal tracking.
 */
global.logDontTrack = function () {
  timber.warn(
    'Mixpanel disabled for this browser session, logging events to console instead.'
  );
  sessionStorage.setItem('logDontTrack', 'true');
};

export { mixpanel as mixpanelObj };
