import { createAction } from '@reduxjs/toolkit';

/*************************************************************************
 * Request taxonomies.
 * This is meant to be only used for listing taxonomies or fetching
 * a single taxonomy tree
 */
export const fetchTaxonomies = createAction(
  'ripcord/oms/objects/FETCH_TAXONOMIES_REQUESTED'
);

export const fetchTaxonomiesSucceeded = createAction(
  'ripcord/oms/objects/FETCH_TAXONOMIES_SUCCEEDED',
  (taxonomies) => ({
    payload: taxonomies
  })
);

export const fetchTaxonomiesFailed = createAction(
  'ripcord/oms/objects/FETCH_TAXONOMIES_FAILED',
  (error) => ({
    payload: error
  })
);

/*************************************************************************
 * Request a taxonomy.
 * This is meant to be only used for fetching a single taxonomy
 */
/**
 * @param {string} id: id of taxonomy
 */
export const fetchTaxonomy = createAction(
  'ripcord/oms/objects/FETCH_TAXONOMY_REQUESTED',
  (id) => ({
    payload: { id }
  })
);

export const fetchTaxonomySucceeded = createAction(
  'ripcord/oms/objects/FETCH_TAXONOMY_SUCCEEDED',
  (taxonomy) => ({
    payload: taxonomy
  })
);

export const fetchTaxonomyFailed = createAction(
  'ripcord/oms/objects/FETCH_TAXONOMY_FAILED',
  (error) => ({
    payload: error
  })
);

/*************************************************************************
 * Delete a taxonomy.
 */
/**
 * @param {string} id: id of taxonomy
 */
export const deleteTaxonomy = createAction(
  'ripcord/oms/objects/DELETE_TAXONOMY_REQUESTED',
  (id) => ({
    payload: { id }
  })
);

export const deleteTaxonomySucceeded = createAction(
  'ripcord/oms/objects/DELETE_TAXONOMY_SUCCEEDED'
);

export const deleteTaxonomyFailed = createAction(
  'ripcord/oms/objects/DELETE_TAXONOMY_FAILED',
  (error) => ({
    payload: error
  })
);

/*************************************************************************
 * Save a taxonomy (create or update)
 */
export const saveTaxonomy = createAction(
  'ripcord/oms/objects/SAVE_TAXONOMY_REQUESTED',
  (values, id, taxonomyRootFolderId) => ({
    payload: { values, id, taxonomyRootFolderId }
  })
);

export const saveTaxonomySucceeded = createAction(
  'ripcord/oms/objects/SAVE_TAXONOMY_SUCCEEDED',
  (data) => ({
    payload: data
  })
);

export const saveTaxonomyFailed = createAction(
  'ripcord/oms/objects/SAVE_TAXONOMY_FAILED',
  (error) => ({
    payload: error
  })
);
