import type { ExpressionNode } from '@rsql/ast';
import type { OMSCommandResult } from './api';

import timber from './timber/macro';
import { headersToObject } from './utils/urls';

/**
 * The most general error class, all other Ripcord errors should inherit from this one, and we
 * should really only throw this when we don't have anything else more specific to throw.
 */
export class RipcordError extends Error {
  message: string;
  additionalData?: Record<string, any>;
  /**
   * @param {string} message - the error message
   * @param {Record<string, any>} additionalData - (optional) an object of additional data that will be assigned to `this`
   */
  constructor({
    message,
    additionalData
  }: {
    message: string;
    additionalData?: Record<string, any>;
  }) {
    super(message);

    Object.defineProperty(this, 'message', {
      value: message,
      writable: true
    });

    additionalData && Object.assign(this, additionalData);
  }
}

export class GenerativeWebsocketError extends RipcordError {}

/**
 * HTTP Errors class, specially made so it can be used as a response object as well
 * so we can use the data from things like validation errors.
 */
export class HTTPError extends RipcordError {
  requestUrl: string;
  status: number;
  responseHeaders: Record<string, string>;

  /**
   * Warning! This is an async constructor (kinda...)
   * So that means you need to await it (or yield in sagas) like
   * `const err = await new HTTPError(...)` or `const err = yield new HTTPError(...)`
   *
   * @param {string} requestUrl - the URL/path that the request was being made to
   * @param {Response | OMSCommandResult} response - the response object from the fetch/xhr request
   * @param {boolean} doAsyncOverride - (optional) whether to override the return value of the constructor with a Promise that resolves to `this`
   */
  constructor(
    {
      requestUrl,
      response,
      objectifiedHeadersFromBatch
    }: {
      requestUrl: string;
      response: Response | OMSCommandResult;
      objectifiedHeadersFromBatch?: Record<string, string>;
    },
    doAsyncOverride = true
  ) {
    // parse out the message and run the super constructor first
    const message =
      response instanceof Response
        ? response?.statusText
        : response.status.toString();
    super({ message });

    // assign all of the main properties as immutable/non-enumerable so they are only "visible" if directly accessed
    Object.defineProperty(this, 'requestUrl', {
      value: requestUrl,
      writable: true
    });
    Object.defineProperty(this, 'status', {
      value: response?.status,
      writable: true
    });

    if (objectifiedHeadersFromBatch) {
      Object.defineProperty(this, 'responseHeaders', {
        value: objectifiedHeadersFromBatch,
        writable: true
      });
    } else if (response instanceof Response) {
      Object.defineProperty(this, 'responseHeaders', {
        value: headersToObject(response?.headers),
        writable: true
      });
    }

    if (doAsyncOverride) {
      return returnThisPromiseOverride(this, response) as unknown as this;
    }
  }
}

export class DocOpsHTTPError extends HTTPError {}
export class GenerativeHTTPError extends HTTPError {}
export class GrpcGatewayHTTPError extends HTTPError {
  service: string;

  constructor({
    service,
    requestUrl,
    response
  }: {
    service: string;
    requestUrl: string;
    response: Response;
  }) {
    // run the super constructor first
    super({ requestUrl, response }, false);

    // assign all of the main properties as immutable/non-enumerable so they are only "visible" if directly accessed
    Object.defineProperty(this, 'service', {
      value: service,
      writable: true
    });

    return returnThisPromiseOverride(this, response) as unknown as this;
  }
}

export class CoreServiceHTTPError extends HTTPError {
  // some of these are only used for batch requests
  batchIndex?: number;
  // these below are set where the `new` is called
  errorOnSave?: boolean;
  levelIndex?: number;

  constructor({
    batchIndex,
    requestUrl,
    response
  }: {
    batchIndex?: number;
    requestUrl: string;
    response: Response | OMSCommandResult;
    objectifiedHeadersFromBatch?: Record<string, string>;
  }) {
    // run the super constructor first
    super({ requestUrl, response }, false);

    // assign all of the main properties as immutable/non-enumerable so they are only "visible" if directly accessed
    if (batchIndex != null) {
      Object.defineProperty(this, 'batchIndex', {
        value: batchIndex,
        writable: true
      });
    }

    return returnThisPromiseOverride(this, response) as unknown as this;
  }
}
export class FlowableHTTPError extends HTTPError {}

/**
 * HTTP Errors class, specially made so it can be used as a response object as well
 * so we can use the data from things like validation errors.
 */
export class XHRHTTPError extends RipcordError {
  requestUrl: string;
  status: number;
  responseHeaders: Record<string, string>;

  /**
   * @param {string} requestUrl - the URL/path that the request was being made to
   * @param {XMLHttpRequest} xhr - the xhr request object
   */
  constructor({
    requestUrl,
    xhr
  }: {
    requestUrl: string;
    xhr: XMLHttpRequest;
  }) {
    // parse out the message and run the super constructor first
    const message = xhr?.statusText;
    super({ message });

    // assign all of the main properties as immutable/non-enumerable so they are only "visible" if directly accessed
    Object.defineProperty(this, 'requestUrl', {
      value: requestUrl
    });
    Object.defineProperty(this, 'status', {
      value: xhr?.status
    });

    const headers: Record<string, string> = {};
    xhr
      ?.getAllResponseHeaders()
      ?.trim()
      ?.split(/[\r\n]+/)
      ?.forEach((line) => {
        const parts = line.split(': ');
        headers[parts.shift()!] = parts.join(': ');
      });
    Object.defineProperty(this, 'responseHeaders', {
      value: headers
    });

    try {
      Object.assign(this, JSON.parse(xhr.response));
    } catch (e) {
      timber.warn(
        'error parsing xhr response while building XHRHTTPError object: ',
        e
      );
    } finally {
      // eslint doesn't like this line because returns in finally statements are not
      // at all intuitive, but it's required here. The caveat here is that if something
      // throws in the try block, the thrown exception will be silently ignored
      // since `this` is returned in the finally block. So timber logging was addedd
      // to maintain visibility of when it happens.
      // eslint-disable-next-line no-unsafe-finally
      return this;
    }
  }
}

/**
 * A general error class for errors that occur when parsing or generating the OMS Filters AST
 */
export class RipcordASTError extends RipcordError {
  node: ExpressionNode;
  filterString?: string;

  /**
   * @param {string} message - the error message, should be in english as it's meant to be read and interpreted by developers at ripcord
   * @param {ExpressionNode} node - the node that caused the error
   * @param {string} filterString - (optional) the filter string that was being parsed or generated
   */
  constructor({
    message,
    node,
    filterString
  }: {
    message: string;
    node: ExpressionNode;
    filterString?: string;
  }) {
    super({ message, additionalData: { node, filterString } });

    Object.assign(this, { node, filterString });
  }
}

export type OMSConstraintErrorResponse = RipcordError & {
  title: string;
  status: number;
  type: string;
  violations: Array<{
    /**
     * a dot-separated path to the field that has the error, often beginning with payload
     * (either an object or an array of objects depending on what was sent in the request)
     */
    field: string;
    /**
     * This message should be translated into the user's language by the server.
     */
    message: string;
  }>;
};

async function returnThisPromiseOverride<T extends object>(
  thisObj: T,
  response: Response | OMSCommandResult
): Promise<T> {
  // Then we return an async IIFE. This return overrides the constructor's "built in"
  // return this` and hijacks it to return a promise (via making it an async IIFE).
  // Then it awaits the JSON response if it exists and assigns that to the object.

  // The end result is that the returned object contains all the enumerable properties
  // of the JSON response (if it exists), and if some error happens trying to parse it
  // it gets ignored.
  try {
    if ('json' in response) {
      // for requests with a parseable json error, we parse that here and return the parsed results as the error object
      Object.assign(thisObj, await response.json());
    } else if ('body' in response) {
      // for batch requests, the response is an OMSCommandResult, which has a body that we should assign to the error object
      Object.assign(thisObj, response.body);
    } else {
      // as a last ditch effort we assign the response to the object body and call it a day
      Object.assign(thisObj, response);
    }
  } catch (e) {
    timber.warn('error parsing json response while building error object: ', e);
  } finally {
    // eslint doesn't like this line because returns in finally statements are not
    // at all intuitive, but it's required here. The caveat here is that if something
    // throws in the try block, the thrown exception will be silently ignored
    // since `this` is returned in the finally block. So timber logging was added
    // to maintain visibility of when it happens.
    // eslint-disable-next-line no-unsafe-finally
    return thisObj;
  }
}
