import React from 'react';
import { ShimmerText } from '../../ripcord-components/loading/ShimmerPlaceholder';

export type Props = {
  className?: string;
  numChars?: number;
  fillChar?: string;
};

export default function TextLoadingShimmer({
  className,
  numChars = 6,
  fillChar
}: Props) {
  return (
    <ShimmerText
      className={className}
      numChars={numChars}
      fillChar={fillChar}
    />
  );
}
